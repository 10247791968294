import { useState } from "react";

//Below logic is for toggling the navbar when toggleNavbar is called. It is used on mobile toggling of navbar.
export default function useAnimatedNavToggler() {
  const [showNavLinks, setShowNavLinks] = useState(false);


  const toggleNavbar = () => {
    setShowNavLinks(!showNavLinks);
  };

  return {showNavLinks, toggleNavbar }
}
