import axios from 'axios';
import { getUrl, checkError } from './actionsUtil';

export const getPatients = (token, clinicId, doctorId, page, limit) => {
  return (dispatch) => {
    dispatch({ type: 'PATIENT_LOADING' });
    const baseUrl = getUrl();
    const path = 'api/patient';
    const api = axios.create({ baseURL: baseUrl });

    api.get(path, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      params: {
        clinicId: parseInt(clinicId),
        doctorId: parseInt(doctorId),
        page: parseInt(page),
        limit: parseInt(limit)
      }
    })
      .then(response => {
        dispatch({ type: 'GET_PATIENTS', response });
      })
      .catch(error => {
        var message = checkError(error);
        dispatch({ type: 'PATIENT_ERROR', message });
      });
  }
}

export const searchPatients = (token, clinicId, doctorId, page, limit , name) => {
  return (dispatch) => {
    dispatch({ type: 'PATIENT_LOADING' });
    const baseUrl = getUrl();
    const path = 'api/patient/search';
    const api = axios.create({ baseURL: baseUrl });

    api.get(path, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      params: {
        clinicId: parseInt(clinicId),
        doctorId: parseInt(doctorId),
        page: parseInt(page),
        limit: parseInt(limit),
        name: name
      }
    })
      .then(response => {
        dispatch({ type: 'GET_PATIENTS', response });
      })
      .catch(error => {
        var message = checkError(error);
        dispatch({ type: 'PATIENT_ERROR', message });
      });
  }
}

export const addPatient = (token, patient , clinicId, doctorId, page, limit) => {
  return (dispatch) => {
    dispatch({ type: 'PATIENT_LOADING' });
    const baseUrl = getUrl();
    const path = 'api/patient';
    const api = axios.create({ baseURL: baseUrl });

    api.post(path, patient, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        dispatch({ type: 'ADD_PATIENT', response });

        api.get(path, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: {
            clinicId: parseInt(clinicId),
            doctorId: parseInt(doctorId),
            page: parseInt(page),
            limit: parseInt(limit)
          }
        })
          .then(response => {
            dispatch({ type: 'GET_PATIENTS', response });
          })
          .catch(error => {
            var message = checkError(error);
            dispatch({ type: 'PATIENT_ERROR', message });
          });
      })
      .catch(error => {
        var message = checkError(error);
        dispatch({ type: 'PATIENT_ERROR', message });
      });
  }
}

export const updatePatient = (token, patientId, patient , clinicId, doctorId, page, limit) => {
  return (dispatch) => {
    dispatch({ type: 'PATIENT_LOADING' });
    const baseUrl = getUrl();
    const path = `api/patient`;
    const updatePath = `api/patient/${patientId}`;
    const api = axios.create({ baseURL: baseUrl });

    api.put(updatePath, patient, {
      headers: {
        'Authorization': `Bearer ${token}`
      } ,
      params: {
        doctorId: parseInt(doctorId),
        clinicId: parseInt(clinicId)
      }
    })
      .then(response => {
        dispatch({ type: 'UPDATE_PATIENT', response });

        api.get(path, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: {
            clinicId: parseInt(clinicId),
            doctorId: parseInt(doctorId),
            page: parseInt(page),
            limit: parseInt(limit)
          }
        })
          .then(response => {
            dispatch({ type: 'GET_PATIENTS', response });
          })
          .catch(error => {
            var message = checkError(error);
            dispatch({ type: 'PATIENT_ERROR', message });
          });
      })
      .catch(error => {
        var message = checkError(error);
        dispatch({ type: 'PATIENT_ERROR', message });
      });
  }
}

export const deletePatient = (token, patientId, clinicId, doctorId, page, limit) => {
  return (dispatch) => {
    dispatch({ type: 'PATIENT_LOADING' });
    const baseUrl = getUrl();
    const path = `api/patient`;
    const deletePath = `api/patient/${patientId}`;
    const api = axios.create({ baseURL: baseUrl });

    api.delete(deletePath, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      params: {
        doctorId: parseInt(doctorId),
        clinicId: parseInt(clinicId)
      }
    })
      .then(response => {
        dispatch({ type: 'DELETE_PATIENT', response });

        api.get(path, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: {
            clinicId: parseInt(clinicId),
            doctorId: parseInt(doctorId),
            page: parseInt(page),
            limit: parseInt(limit)
          }
        })
          .then(response => {
            dispatch({ type: 'GET_PATIENTS', response });
          })
          .catch(error => {
            var message = checkError(error);
            dispatch({ type: 'PATIENT_ERROR', message });
          });
      })
      .catch(error => {
        var message = checkError(error);
        dispatch({ type: 'PATIENT_ERROR', message });
      });
  }
}

export const attachPatient = (token, clinicId, doctorId, patientId, page, limit) => {
  return (dispatch) => {
    dispatch({ type: 'PATIENT_LOADING' });
    const baseUrl = getUrl();
    const path = `api/patient`;
    const attachPath = `api/patient/attach`;
    const api = axios.create({
      baseURL: baseUrl, headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    api.put(attachPath, null, {
      params: {
        patientId: parseInt(patientId),
        doctorId: parseInt(doctorId),
        clinicId: parseInt(clinicId)
      }
    })
      .then(response => {
        dispatch({ type: 'ATTACH_PATIENT', response });

        api.get(path, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: {
            clinicId: parseInt(clinicId),
            doctorId: parseInt(doctorId),
            page: parseInt(page),
            limit: parseInt(limit)
          }
        })
          .then(response => {
            dispatch({ type: 'GET_PATIENTS', response });
          })
          .catch(error => {
            var message = checkError(error);
            dispatch({ type: 'PATIENT_ERROR', message });
          });
      })
      .catch(error => {
        var message = checkError(error);
        dispatch({ type: 'PATIENT_ERROR', message });
      });
  }
}

export const detachPatient = (token, clinicId, doctorId, patientId ,page, limit) => {
  return (dispatch) => {
    dispatch({ type: 'PATIENT_LOADING' });
    const baseUrl = getUrl();
    const path = `api/patient`;
    const deAttachPath = `api/patient/detach`;
    const api = axios.create({
      baseURL: baseUrl, headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    api.put(deAttachPath, null, {
      params: {
        patientId: parseInt(patientId),
        doctorId: parseInt(doctorId),
        clinicId: parseInt(clinicId)
      }
    })
      .then(response => {
        dispatch({ type: 'DEATTACH_PATIENT', response });

        api.get(path, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: {
            clinicId: parseInt(clinicId),
            doctorId: parseInt(doctorId),
            page: parseInt(page),
            limit: parseInt(limit)
          }
        })
          .then(response => {
            dispatch({ type: 'GET_PATIENTS', response });
          })
          .catch(error => {
            var message = checkError(error);
            dispatch({ type: 'PATIENT_ERROR', message });
          });
      })
      .catch(error => {
        var message = checkError(error);
        dispatch({ type: 'PATIENT_ERROR', message });
      });
  }
}

export const clearError = () => {
  return (dispatch) => {
    dispatch({ type: 'PATIENT_CLEAR_ERROR' });
  }
}
