import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { signUp, clearError } from '../../store/actions/authAction';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import './auth.css';
import Image from '../../images/undraw_logic_n6th.svg';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import DnsOutlined from '@material-ui/icons/DnsOutlined';
import Grid from '@material-ui/core/Grid';
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined';
import CallOutlined from '@material-ui/icons/CallOutlined';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LinearProgress from '@material-ui/core/LinearProgress';

class SignUp extends Component {
    state = {
        Name: '',
        Email: '',
        Password: '',
        ConfirmPassword: '',
        MobileNumber: '',
        Role: 'Doctor',
        formDisabled: true,
        Loading: false,
    }
    error = '';

    setRole = (event, selected) => {
        if (selected) {
            this.setState({
                ['Role']: selected
            });
        }
    }

    updateValidation = () => {
        if (this.state.Password != "" && this.state.ConfirmPassword != ""
            && this.state.Password === this.state.ConfirmPassword
            && this.state.Email != ''
            && this.state.Name != ''
            && this.state.MobileNumber != '') {
            this.setState({
                formDisabled: false
            });
        } else {
            this.setState({
                formDisabled: true
            });
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        }, () => {
            this.updateValidation();
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            ['Loading']: true
        });
        this.props.signUp(this.state);
    }

    goToLogin = () => {
        this.props.history.push("login");
    }

    showError = (error) => {
        this.error = error;
    }

    handleClose = () => {
        this.error = '';
        this.props.clearError();
        this.setState({ ['Loading']: false });
    }

    render() {
        var { token, authError } = this.props;

        if (token) return <Redirect to='/' />

        if (authError) {
            this.showError(authError)
        }

        return (
            <div>
                <Card className='Card' >
                    <div>
                        {this.state.Loading ? <LinearProgress /> : null}
                    </div>
                    <CardContent>
                        <div className="container">
                            <form className="white" onSubmit={this.handleSubmit}>
                                <h2 className="grey-text text-darken-3">Sign Up</h2>
                                <div className="fields">
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <DnsOutlined className="black" />
                                        </Grid>
                                        <Grid item>
                                            <TextField id='Name' onChange={this.handleChange}
                                                label="Name" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <EmailOutlined className="black" />
                                        </Grid>
                                        <Grid item>
                                            <TextField id='Email' onChange={this.handleChange}
                                                label="Email" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <LockOpenOutlined className="black" />
                                        </Grid>
                                        <Grid item>
                                            <TextField id='Password' type="password"
                                                onChange={this.handleChange} label="Password" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <LockOpenOutlined className="black" />
                                        </Grid>
                                        <Grid item>
                                            <TextField id='ConfirmPassword' type="password"
                                                onChange={this.handleChange} label="Confirm Password" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <CallOutlined className="black" />
                                        </Grid>
                                        <Grid item>
                                            <TextField id='MobileNumber' onChange={this.handleChange}
                                                label="Mobile Number" />
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="toggle">
                                    <ToggleButtonGroup onChange={this.setRole} value={this.state.Role}
                                        size="small" exclusive aria-label="text alignment" >
                                        <ToggleButton value="Doctor" aria-label="left aligned">
                                            Doctor
                                             </ToggleButton>
                                        <ToggleButton value="Clinic" aria-label="centered">
                                            Clinic
                                              </ToggleButton>
                                        <ToggleButton value="Both" aria-label="centered">
                                            Both
                                              </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            </form>
                        </div>
                    </CardContent>
                    <CardActions>
                        <Button onClick={this.handleSubmit} disabled={this.state.formDisabled}
                            className="Login" variant="contained" color="secondary">
                            Sign Up
                            </Button>
                    </CardActions>
                    <div className="link">
                        <span>Already have an account? <Button className="linkButton"
                            onClick={this.goToLogin} >Login  </Button>  </span>
                    </div>
                </Card>
                <div className="image">
                    <img src={Image} alt="React Logo" />
                </div>
                <div>
                    <Snackbar className="snackbar"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={this.error != ''}
                        autoHideDuration={5000}
                        onClose={this.handleClose}
                        message={this.error}
                        action={
                            <React.Fragment>
                                <IconButton size="medium" aria-label="close" onClick={this.handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </React.Fragment>
                        }
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        authError: state.auth.authError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (data) => dispatch(signUp(data)),
        clearError: () => dispatch(clearError())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);