import axios from 'axios';
import { getUrl, checkError } from './actionsUtil';

export const getBillingss = (token, clinicId, doctorId, year,month) => {
    
    return (dispatch) => {
        dispatch({ type: 'BILLINGS_LOADING' });
        const baseUrl = getUrl();
        const path = 'api/billings';
        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        api.get(path, {
            params: {
                clinicId: parseInt(clinicId),
                doctorId: parseInt(doctorId),
                year: year,
                month: month
            }
        })
            .then(billings => {
                dispatch({ type: 'BILLINGS_RETRIVED', billings });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'BILLINGS_ERROR', message });
            });
    }
}

export const getDoctors = (token) => {
    return (dispatch) => {
        const baseUrl = getUrl();
        const path = 'api/doctor';
        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        api.get(path)
            .then(doctors => {
                dispatch({ type: 'DOCTORS_RETRIVED', doctors });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'BILLINGS_ERROR', message });
            });
    }
}
export const clearError = () => {
    return (dispatch) => {  
       dispatch({ type: 'CLEAR_BILLINGS_ERROR' });              
    }
}


