const initState = {
    services: null,
    totalServicesSum: null,
    error: null,
    isLoading: null
}

const serviceReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_SERVICES":
            return {
                ...state,
                services: action.response.data?.data?.allServices,
                totalServicesSum: action.response.data?.data?.totalSum,
                isLoading: false
            }
        case "SERVICES_ISLOADING":
            return {
                ...state, isLoading: true
            }
        case "SERVICES_ISLOADING_STOP":
            return {
                ...state, isLoading: false
            }
        default:
            return { ...state }
    }
};

export default serviceReducer;