import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import EditOutlined from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import { addDiagnose, updateDiagnose, deleteDiagnose, attachImage ,clearImage} from '../../store/actions/diagnoseAction';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    root: {
        'margin-top': '20%'
    },
    field: {
        'margin-top': '30px',
        margin: '5px',
        width: '90%'
    },
    addButton: {
        'margin-top': '60px',
        margin: '5px',
        width: '90%'
    },
    cancelButton: {
        'margin-top': '30px',
        margin: '5px',
        width: '90%'
    },
    input: {
        display: 'none',
    }
});

export default function DiagnoseMenu(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [diagnose, setDiagnose] = React.useState(props.data?.diagnose ?? "");
    const [diagnoseUpdated, setDiagnoseUpdated] = React.useState(false);
    const [date, setDate] = React.useState(props.data?.date ?? new Date());
    const [dateUpdated, setDateUpdated] = React.useState(false);

    const selectToken = state => state.auth.token;
    const tokenSelector = createSelector(
        selectToken,
        token => token
    );
    const token = useSelector(tokenSelector);

    const selectImageUrl = state => state.diagnoses.imageUrl;
    const imageUrlSelector = createSelector(
        selectImageUrl,
        imageUrl => imageUrl
    );
    const imageUrl = useSelector(imageUrlSelector);

    const selectImageStatus = state => state.diagnoses.imageIsUploading;
    const imageStatusSelector = createSelector(
        selectImageStatus,
        imageStatus => imageStatus
    );
    const imageStatus = useSelector(imageStatusSelector);

    const s_updateDiagnose = (value) => {
        setDiagnose(value);
        setDiagnoseUpdated(true);
    }
    const updateDate = (value) => {
        setDateUpdated(true);
        setDate(value);
    }

    const handleAddDiagnose = () => {
        dispatch(addDiagnose({
            token: token, patientId: props.patientId, doctorId: props.doctorId,
            clinicId: props.clinicId, body:
            {
                "diagnose": diagnose,
                "date": date,
                "imageUrl": imageUrl
            }
        }));
        setDiagnose("");
        setDate(new Date());
    };

    const handleUpdateDiagnose = () => {
        const dateToUpdate = props.data?.update == true && !dateUpdated ? props.data.date : date;
        const diagnoseToUpdate = props.data?.update == true && !diagnoseUpdated ? props.data.diagnose : diagnose;
        const imageUrlToUpdate = props.data?.update == true && imageUrl ==null ? props.data.imageUrl : imageUrl;
        dispatch(updateDiagnose({
            id: props.data.id, token: token, patientId: props.patientId,
            doctorId: props.doctorId, clinicId: props.clinicId, body:
            {
                "diagnose": diagnoseToUpdate,
                "date": dateToUpdate,
                "imageUrl": imageUrlToUpdate
            }
        }));
        props.toggleRightMenu(false);
        setDiagnose("");
        setDateUpdated(false);
        setDiagnoseUpdated(false);
        setDate(new Date());
    };

    const handleDeleteDiagnose = () => {
        dispatch(deleteDiagnose({
            id: props.data.id, token: token, patientId: props.patientId,
            doctorId: props.doctorId, clinicId: props.clinicId
        }));
        setDiagnose("");
        setDate(new Date());
        setDateUpdated(false);
        setDiagnoseUpdated(false);
        handleCancel();
    };

    const handleCancel = () => {
        props.toggleRightMenu(false);
        setDiagnose("");
        setDate(new Date());
        setDateUpdated(false);
        setDiagnoseUpdated(false);
        dispatch(clearImage());
    };

    const handleUploadImage = (e) => {
        dispatch(attachImage({
            token: token,
            file: e.target.files[0],
            doctorId: props.doctorId,
            clinicId: props.clinicId
        }))
    }

    return (
        <div className={classes.root}>
            <TextField multiline value={props.data?.update == true && !diagnoseUpdated ?
                props.data.diagnose : diagnose}
                onChange={(e) => s_updateDiagnose(e.target.value)} className={classes.field}
                id="diagnose"
                label='Diagnose'
            />
            <MuiPickersUtilsProvider required utils={DateFnsUtils}>
                <KeyboardDatePicker value={props.data?.update == true && !dateUpdated ?
                    props.data.date : date} onChange={(e) => updateDate(e)} 
                    margin="normal"
                    disableToolbar
                    className={classes.field}
                    variant="inline"
                    id="date"
                    label="Diagnose Date"
                    format="dd-MM-yyyy"
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>

            {imageStatus == true ? 
            <div>
                <CircularProgress className={classes.block} disableShrink /> 
                </div>                
                :
            <div >
                <input
                    onChange={(e) => handleUploadImage(e)}
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                />
                <label htmlFor="contained-button-file">
                    <Button className={classes.addButton} variant="contained" color="secondary" component="span">
                      {imageUrl == null ?  "Attach image" : "Image attached"}
                    </Button>
                </label>

            </div>}

            <div>
                {props.data?.add == true ? <Button variant="outlined"
                    onClick={() => { handleAddDiagnose() }} className={classes.addButton}
                    disabled={imageStatus == true}
                    color="primary" startIcon={<AddBoxOutlined></AddBoxOutlined>}
                >Add Diagnose</Button> : null}
            </div>


            <div>
                {props.data?.update == true ? <Button variant="outlined"
                    onClick={() => { handleUpdateDiagnose() }} className={classes.addButton}
                    color="primary" 
                    disabled={imageStatus == true}
                    startIcon={<EditOutlined></EditOutlined>}
                >Update Diagnose</Button> : null}
            </div>

            <div>
                {props.data?.update == true ? <Button variant="outlined"
                    onClick={() => { handleDeleteDiagnose() }} className={classes.cancelButton}
                    color="primary" startIcon={<DeleteForeverOutlined></DeleteForeverOutlined>}
                >Delete Diagnose</Button> : null}
            </div>

            <Button variant="outlined" onClick={() => { handleCancel() }} className={classes.cancelButton}
                color="primary" startIcon={<CancelOutlined></CancelOutlined>}
            >Cancel</Button>
        </div>
    );
}