import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import LayersOutlined from '@material-ui/icons/LayersOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import EditOutlined from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import { addTreatment, updateTreatment, deleteTreatment , updateTeethNumber} from '../../store/actions/treatmentAction';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import ChildCareOutlined from '@material-ui/icons/ChildCareOutlined';
import MoodOutlined from '@material-ui/icons/MoodOutlined';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Chip from '@material-ui/core/Chip';
import ToothMap from './ToothMap';
import AdultToothMap from './AdultToothMap';
const useStyles = makeStyles({
    root: {
        'margin-top': '20%'
    },
    field: {
        'margin-top': '30px',
        margin: '5px',
        width: '90%'
    },
    addButton: {
        'margin-top': '60px',
        margin: '5px',
        width: '90%'
    },
    cancelButton: {
        'margin-top': '30px',
        margin: '5px',
        width: '90%'
    }
});

export default function TreatmentMenu(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [treatment, setTreatment] = React.useState(props.data?.treatment ?? "");
    const [alignment, setAlignment] = React.useState("Adult");
    const [treatmentUpdated, setTreatmentUpdated] = React.useState(false);
    const [date, setDate] = React.useState(props.data?.date ?? new Date());
    const [dateUpdated, setDateUpdated] = React.useState(false);
    const [displayTeethPanel, setDisplayTeethPanel] = React.useState(false)
    const [selectedTeeth, setSelectedTeeth] = React.useState('');
    const [selectedTeethNumber, setSelectedTeethNumber] = React.useState('');
    const selectToken = state => state.auth.token;
    const tokenSelector = createSelector(
        selectToken,
        token => token
    );
    const token = useSelector(tokenSelector);

    const s_updateTreatment = (value) => {
        setTreatment(value);
        setTreatmentUpdated(true);
    }
    const updateDate = (value) => {
        setDateUpdated(true);
        setDate(value);
    }

    const handleAddTreatment = (event) => {
        dispatch(addTreatment({
            token: token, patientId: props.patientId, doctorId: props.doctorId,
            clinicId: props.clinicId, body:
            {
                "treatment": selectedTeeth == "" ? treatment : `${selectedTeeth} ${treatment}`,
                "date": date
            }
        }));
        setTreatment("");
        setDate(new Date());
        filterByTeeth("")
    };

    const handleUpdateTreatment = () => {
        const dateToUpdate = props.data?.update == true && !dateUpdated ? props.data.date : date;
        const treatmentToUpdate = props.data?.update == true && !treatmentUpdated ? props.data.treatment : treatment;
        dispatch(updateTreatment({
            id: props.data.id, token: token, patientId: props.patientId,
            doctorId: props.doctorId, clinicId: props.clinicId, body:
            {
                "treatment": treatmentToUpdate,
                "date": dateToUpdate
            }
        }));
        props.toggleRightMenu(false);
        setTreatment("");
        setDateUpdated(false);
        setTreatmentUpdated(false);
        setDate(new Date());
        filterByTeeth("")
    };

    const handleDeleteTreatment = () => {
        dispatch(deleteTreatment({
            id: props.data.id, token: token, patientId: props.patientId,
            doctorId: props.doctorId, clinicId: props.clinicId
        }));
        setTreatment("");
        setDate(new Date());
        setDateUpdated(false);
        setTreatmentUpdated(false);
        handleCancel();
    };

    const handleCancel = (event) => {
        props.toggleRightMenu(false);
        setTreatment();
        setDate(new Date());
        setDateUpdated(false);
        setTreatmentUpdated(false);

        filterByTeeth("")
    };
    const handleAlignment = (event, value) => {
        setAlignment(value);
    }
    const handleOpenToothMap = (isOpen) => {
        setDisplayTeethPanel(isOpen)
    };

    const filterByTeeth = (teeth) => {

        setSelectedTeeth(` ${teeth}`);
        setSelectedTeethNumber(teeth);
        dispatch(updateTeethNumber(teeth));
    };
    const handleDeleteChip = () => {
        filterByTeeth("")
    };
    const getChipLabel = () => {
        return "Selected Teeth is" + selectedTeeth
    };
    return (

        <div>
            {displayTeethPanel == true ? <div
                className='toothDrawer'>
                <ToggleButtonGroup
                    style={{ 'margin-top': 8 }}
                    color="primary"
                    aria-label="Platform"
                    size="small"
                    exclusive
                    value={alignment}
                    onChange={handleAlignment}
                >
                    <ToggleButton value="Adult">Adult  <MoodOutlined /></ToggleButton>
                    <ToggleButton value="Child">Child <ChildCareOutlined /></ToggleButton>
                </ToggleButtonGroup>

                {alignment == 'Adult' ?
                    <AdultToothMap toggleBrowseTeeth={handleOpenToothMap} selectedTooth={selectedTeethNumber}
                        filterByTeeth={filterByTeeth}></AdultToothMap> :
                    <ToothMap selectedTooth={selectedTeethNumber} toggleBrowseTeeth={handleOpenToothMap}
                        filterByTeeth={filterByTeeth}></ToothMap>}

            </div> : <div className={classes.root}>
                {selectedTeethNumber !="" ? <Chip
                    label={getChipLabel()}
                    onDelete={handleDeleteChip}
                    color="secondary"
                /> : null}
                <TextField multiline value={props.data?.update == true && !treatmentUpdated ?
                    props.data.treatment : treatment}
                    onChange={(e) => s_updateTreatment(e.target.value)} className={classes.field}
                    id="treatment"
                    label='treatment'
                />
                <MuiPickersUtilsProvider required utils={DateFnsUtils}>
                    <KeyboardDatePicker value={props.data?.update == true && !dateUpdated ?
                        props.data.date : date} onChange={(e) => updateDate(e)}
                        margin="normal"
                        className={classes.field}
                        disableToolbar
                        variant="inline"
                        id="date"
                        label="Treatment Date"
                        format="dd-MM-yyyy"
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
                <div>
                    {props.data?.add == true ? <Button variant="outlined"
                        onClick={() => { handleOpenToothMap(true) }} className={classes.addButton}
                        color="primary" startIcon={<LayersOutlined></LayersOutlined>}
                    >Browse teeth</Button> : null}
                </div>
                <div>
                    {props.data?.add == true ? <Button variant="outlined"
                        onClick={() => { handleAddTreatment() }} className={classes.addButton}
                        color="primary" startIcon={<AddBoxOutlined></AddBoxOutlined>}
                    >Add Treatment</Button> : null}
                </div>

                <div>
                    {props.data?.update == true ? <Button variant="outlined"
                        onClick={() => { handleUpdateTreatment() }} className={classes.addButton}
                        color="primary" startIcon={<EditOutlined></EditOutlined>}
                    >Update Treatment</Button> : null}
                </div>

                <div>
                    {props.data?.update == true ? <Button variant="outlined"
                        onClick={() => { handleDeleteTreatment() }} className={classes.cancelButton}
                        color="primary" startIcon={<DeleteForeverOutlined></DeleteForeverOutlined>}
                    >Delete Treatment</Button> : null}
                </div>

                <Button variant="outlined" onClick={() => { handleCancel() }} className={classes.cancelButton}
                    color="primary" startIcon={<CancelOutlined></CancelOutlined>}
                >Cancel</Button>
            </div>}
        </div>
    );
}