import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { logout } from '../../store/actions/authAction';
import { Link } from 'react-router-dom';
import logo from '../../images/logoo.png';
class Navbar extends Component {
  state = {
    showMenu: false,
    anchorEl: null
  }

  handleToggle = (e) => {
    this.setState({
      showMenu: true,
      anchorEl: e.currentTarget
    });
  };

  handleClose = (e) => {
    this.setState({
      showMenu: false,
      anchorEl: e.currentTarget
    });
  };

  handleLogout = () => {
    this.props.logout();
  }

  render() {
    return (
      <div>
        <AppBar className="appBar" position="fixed">
          <Toolbar >
            <IconButton onClick={this.props.disableToggle ? null : this.props.toggleMenu} edge="start" color="inherit" aria-label="menu">
              <MenuIcon className="white" />
            </IconButton>
            <Link to="/dashboard">
              <img className="NavIcon" src={logo} alt="Icon" />
              <Typography className="productName" variant="h6" >Exa Clinic</Typography>
            </Link>
            <div className="Menu">
              <span className="Name">{this.props.name}</span>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.handleToggle}
                color="inherit"
              ><AccountCircle className="white" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                anchorEl={this.state.anchorEl}
                open={this.state.showMenu}
                onClose={this.handleClose}
              >
                <MenuItem > <AccountCircleOutlined />
                  <Link to="/profile">  My Profile
              </Link>
                </MenuItem>
                <MenuItem onClick={this.handleLogout} > <ExitToApp /> Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    name: state.auth.name
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);