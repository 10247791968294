import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { addPatient } from '../../store/actions/patientsAction';

class AddPatient extends Component {
    state = {
        newName: '',
        newDateOfBirth: new Date('1995-03-26T21:11:54'),
        newGender: true,
        newMobileNumber: '',
        newEmail: '',
        newAddress: '',
        newDoctorId: null,
        formDisabled: true
    }

    handleChangeNewGender = (e) => {
        this.setState({
            newGender: e.target.value == "Male" ? true : false
        });
    }

    handleChangeNewBirth = (e) => {
        this.setState({
            newDateOfBirth: e
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        }, () => {
            this.updateValidation();
        });
    }

    handleChangeDoctor = (e) => {
        this.setState({
            newDoctorId: e.target.value
        }, () => {
            this.updateValidation();
        });
    }

    updateValidation = () => {
        if (this.state.newName != '') {
            this.setState({
                formDisabled: false
            });
        } else {
            this.setState({
                formDisabled: true
            });
        }
    }

    handleAddNewPatient = () => {
      
        var newPatient = {
            name: this.state.newName,
            email: this.state.newEmail,
            dateOfBirth: this.state.newDateOfBirth,
            mobileNumber: this.state.newMobileNumber,
            gender: this.state.newGender,
            address: this.state.newAddress,
            clinicId: this.props.clinicId,
            doctorId:  this.props.doctorId
        }
        this.props.addPatient(this.props.token, newPatient , this.props.clinicId, this.props.doctorId, this.props.page, this.props.limit);
        this.handleClose();
    }

    handleClose = () => {
        this.setState({
            newDateOfBirth: new Date('1995-03-26T21:11:54'),
            newGender: true,
            newName: '',
            newMobileNumber: '',
            newEmail: '',
            newAddress: '',
            newDoctorId: null
        });
        this.props.closePopUp();
    }

    render() {
        return (
            <div>
                <Dialog open={this.props.dialogOpened} onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                    <form >
                        <DialogTitle id="form-dialog-title">Add a new patient</DialogTitle>
                        <DialogContent>
                            <div className="flexDiv">
                                <div >
                                    <TextField className="block"
                                        autoFocus
                                        id="newName"
                                        required
                                        label="Name"
                                        defaultValue={this.state.newName}
                                        onChange={this.handleChange}
                                    />
                                    <TextField className="block"
                                        id="newAddress"
                                        label="Address"
                                        defaultValue={this.state.newAddress}
                                        onChange={this.handleChange}
                                    />
                                    <TextField className="block"
                                        defaultValue={this.state.newMobileNumber}
                                        onChange={this.handleChange}
                                        id="newMobileNumber"
                                        label="Mobile Number"
                                    />
                                    <TextField className="block"
                                        defaultValue={this.state.newEmail}
                                        onChange={this.handleChange}
                                        id="newEmail"
                                        label="Email"
                                        type="email"
                                    />
                                </div>
                                <div className="flexChild marginLeft">
                                    <FormControl className="block" required component="fieldset">
                                        <FormLabel component="legend">Gender</FormLabel>
                                        <RadioGroup onChange={this.handleChangeNewGender}
                                            aria-label="gender" value={this.state.newGender == true ?
                                                'Male' : 'Female'}>
                                            <FormControlLabel value="Male" control={<Radio />}
                                                label="Male" />
                                            <FormControlLabel value="Female" control={<Radio />}
                                                label="Female" />
                                        </RadioGroup>
                                    </FormControl>
                                    <MuiPickersUtilsProvider  className="block" required utils={DateFnsUtils}>
                                        <KeyboardDatePicker className="picker"
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="Date of birth"
                                            format="dd/MM/yyyy"                                          
                                            variant="inline"
                                            value={this.state.newDateOfBirth}
                                            onChange={this.handleChangeNewBirth}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>                                
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">Cancel</Button>
                            <Button disabled={this.state.formDisabled}
                                onClick={this.handleAddNewPatient} color="primary">
                                Add</Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        role: state.auth.role,
        doctors: state.doctors.doctors,      
        clinicId:state.auth.clinicId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addPatient: (token, patient, clinicId, doctorId, page, limit) => dispatch(addPatient(token, patient, clinicId, doctorId, page, limit))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPatient);