import React, {  useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteSweepOutlined from '@material-ui/icons/DeleteSweepOutlined';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { getSecretaries, addSecretary, getPermissions, clearError,updatePermissions, deleteSecretary, attchDoctor, deattachDoctor } from '../../store/actions/secretaryAction';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import AddOutlined from '@material-ui/icons/AddOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { getDoctors } from '../../store/actions/doctorAction';
import Switch from '@material-ui/core/Switch';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  table: {
    minWidth: '30vw',
  },
  container: {
    margin: '40px',
    display: 'flex',
    margin: '40px',
    'margin-left': '20%',
    'display': 'flex',
    'margin-right': '20%'

  },
  addButton: {
    color: '#000000 !important',
    'width': '100%',
    height: '40px',
  },
  detachIcon: {
    color: '#d80939fa  !important'
  },
  closeIcon: {
    color: 'white'
  },
  flex: {
    display: 'flex'
  },
  permissionsList: {
    maxHeight: '40vh',
    overflow: 'auto'
  },
  doctors: {
    margin: '20px',
    maxHeight: '40vh',
    overflow: 'auto'
  }
});

export default function Secretaries() {
  const classes = useStyles();

  const [name, setName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [confirmPassword, setConfirmPassword] = React.useState(null);
  const [mobileNumber, setMobileNumber] = React.useState(null);
  const [dialogOpened, setAddDialog] = React.useState(false);
  const [selectedSecretaryId, setSelectedSecretaryId] = React.useState(null);
  const [selectedPermissions, setSelectedPermissions] = React.useState(null);


  const selectToken = state => state.auth.token;
  const selectSecretaries = state => state.secretaries.secretaries;
  const selectError = state => state.secretaries.error;
  const selectDoctors = state => state.doctors.doctors;
  const selectPermissions = state => state.secretaries.permissions;
  const selectAttachLoading = state => state.secretaries.attachLoading;
  const selectPermissionLoading = state => state.secretaries.permissionLoading;

  const tokenSelector = createSelector(
    selectToken,
    token => token
  );

  const attachLoadingSelector = createSelector(
    selectAttachLoading,
    attachLoading  => attachLoading 
  );

  const permissionLoadingSelector = createSelector(
    selectPermissionLoading,
    permissionLoading  => permissionLoading 
  );

  const secretariesSelector = createSelector(
    selectSecretaries,
    Secretaries => Secretaries
  );

  const errorSelector = createSelector(
    selectError,
    doctors => doctors
  );

  const doctorSelector = createSelector(
    selectDoctors,
    doctors => doctors
  );

  const permissionsSelector = createSelector(
    selectPermissions,
    permissions => permissions
  );

  const token = useSelector(tokenSelector);
  const attachLoading = useSelector(attachLoadingSelector);
  const secretaries = useSelector(secretariesSelector);
  const error = useSelector(errorSelector);
  const doctors = useSelector(doctorSelector);
  const permissions = useSelector(permissionsSelector);
  const permissionsLoading = useSelector(permissionLoadingSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSecretaries(token))
    dispatch(getDoctors(token))
    dispatch(getPermissions())
  }, []);

  const isAttached = (doctorId) => {

    var existed = secretaries?.find(x => x.id == selectedSecretaryId)?.Doctors?.find(x => x.id == doctorId);
    if (existed == undefined) {
      return false;
    }
    else return true;
  };

  const isChecked = (permissionId) => {

    var existed = selectedPermissions.find(x => x == permissionId);
    if (existed == undefined) {
      return false;
    }
    else return true;
  };

  const handleAddSecretary = (name, email, password, mobileNumber) => {

    const body = {
      "name": name,
      "email": email,
      "password": password,
      "mobileNumber": mobileNumber
    };

    dispatch(addSecretary(token, body));

    handleCloseDialog();
  };

  const handleDeleteSecretary = (id) => {

    dispatch(deleteSecretary(token, id));

  };

  const handleAttachDeattach = (attach, doctorId, secretaryId) => {

    if (attach === false) {

      dispatch(attchDoctor(token, doctorId, secretaryId));
    }
    else {
      dispatch(deattachDoctor(token, doctorId, secretaryId));
    }

  };

  const handleCloseDialog = () => {

    setEmail(null);
    setName(null);
    setPassword(null);
    setConfirmPassword(null);
    setMobileNumber(null);
    setAddDialog(false);
  };

  const handleSelectSecretary = (id) => {

    setSelectedSecretaryId(id);

    const selected = secretaries?.find(x => x.id == id)?.Permissions?.map(x => x.id);

    setSelectedPermissions(selected);
  };

  const handleChangeSelection = (isChecked, id) => {
    if (isChecked === false) {    
      setSelectedPermissions(items => [...items,id]);
    }
     else {      
      setSelectedPermissions(items => items.filter((Id)=>(Id !== id)));
      }
  }

  const handleUpdatePermissions = () =>{
    
    dispatch(updatePermissions(token,selectedSecretaryId, selectedPermissions));
  }
 
  return (
    <div>
      <div className={classes.flex}>
        <div>
          <Button
            variant="outlined"
            fontSize="small"
            startIcon={<AddOutlined>send</AddOutlined>}
            className={classes.addButton}
            onClick={() => setAddDialog(true)}
          >
            Add new secretary
      </Button>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">MobileNumber</TableCell>
                  <TableCell align="right">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {secretaries?.map((row) => (
                  <TableRow selected={row.id === selectedSecretaryId} onClick={() => handleSelectSecretary(row.id)} hover role="checkbox" tabIndex={-1} key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.email}</TableCell>
                    <TableCell align="right">{row.mobileNumber}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleDeleteSecretary(row.id)} aria-label="delete" >
                        <DeleteSweepOutlined className={classes.detachIcon} fontSize="small" />
                      </IconButton >
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </div>
        <Divider className='patientDivider' orientation="vertical" flexItem />
        <div>
          {selectedSecretaryId !== null ? <div className={classes.doctors}>

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Email</TableCell>
                    <TableCell align="right">MobileNumber</TableCell>
                    <TableCell align="right">Attach/ Detach</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {doctors?.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.email}</TableCell>
                      <TableCell align="right">{row.mobileNumber}</TableCell>
                      <TableCell align="right">
                      {attachLoading == true ? <CircularProgress /> :
                        <Switch
                          checked={isAttached(row.id)}
                          onChange={() => { handleAttachDeattach(isAttached(row.id), row.id, selectedSecretaryId) }}
                          name="checkedA"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div> : null}
          <div>
            {selectedSecretaryId !== null ?
              <div><List className={classes.permissionsList}>
                {permissions?.map((value) => {
                  const action = value.name?.split('_')[1];
                  const permission = value.name?.split('_')[0];
                  return (
                    <ListItem key={value.id} dense button onClick={()=> handleChangeSelection( isChecked(value.id),value.id)}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={isChecked(value.id)}
                          tabIndex={-1}
                        />
                      </ListItemIcon>
                      <ListItemText id={value.id} primary={`${action} ${permission}`} />
                    </ListItem>
                  );
                })}
              </List>
                {permissionsLoading == true ? <CircularProgress /> :<Button fullWidth variant="outlined" color="primary" onClick={ () => {handleUpdatePermissions()}}>
                  Update Permissions
              </Button>} </div>
              : null}

          </div>
        </div>

      </div>

      <Dialog open={dialogOpened} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add a new secretary</DialogTitle>
        <DialogContent>
          <TextField
            onChange={(e) => setName(e.target.value)}
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            fullWidth
          />
          <TextField
            onChange={(e) => setEmail(e.target.value)}
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
          />
          <TextField
            onChange={(e) => setPassword(e.target.value)}
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
          />
          <TextField
            onChange={(e) => setConfirmPassword(e.target.value)}
            margin="dense"
            id="confirmPassword"
            label="Confirm Password"
            type="password"
            fullWidth
          />
          <TextField
            onChange={(e) => setMobileNumber(e.target.value)}
            margin="dense"
            id="mobileNumber"
            label="Mobile Number"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary"
            onClick={() => handleCloseDialog()}>
            Cancel
          </Button>
          <Button color="primary"
            disabled={name == null || password == null || confirmPassword == null || password !== confirmPassword}
            onClick={() => handleAddSecretary(name, email, password, mobileNumber)}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <div>
        <Snackbar className="snackbar"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={error != null}
          autoHideDuration={5000}
          onClose={() => { dispatch(clearError()) }}
          message={error}
          action={
            <React.Fragment>
              <IconButton size="medium" aria-label="close" onClick={() => { dispatch(clearError()) }} >
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </React.Fragment>
          }
        />
      </div>

    </div>

  );
}