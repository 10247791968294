import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import AddBox from '@material-ui/icons/AddBox';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getDiagnoses } from '../../store/actions/diagnoseAction';
import { createSelector } from 'reselect';
import LinearProgress from '@material-ui/core/LinearProgress';
import {  Link } from 'react-router-dom';

const columns = [
    { id: 'diagnose', label: 'Diagnose', minWidth: 100 },
    { id: 'date', label: 'Date', minWidth: 100 },
    { id: 'imageUrl', label: 'Image', minWidth: 40 },
    { id: 'loggedBy', label: 'Logged by', minWidth: 80 },
    { id: 'createdAt', label: 'Logged At', minWidth: 100 },
    { id: 'updatedBy', label: 'Updated by', minWidth: 80 },
    { id: 'updatedAt', label: 'Updated at', minWidth: 100 }
];

const useStyles = makeStyles({
    root: {
        width: '100%',
        'max-width': '62vw'
    },
    container: {
        maxHeight: 600,
    },
    scroll: {
        maxHeight: '40vh !important',
        overflow: 'auto'
    },
    addButton: {
        float: 'left',
        height: '40px',
        wedth: '40px',
        padding: '5px'
    },
    addIcon: {
        'color': 'rgb(4, 67, 117) !important',
        height: '20px'
    }
});

export default function Service(props) {
    const classes = useStyles();
    const selectToken = state => state.auth.token;
    const selectDiagnose = state => state.diagnoses.diagnoses;
    const selectDiagnosesIsLoading = state => state.diagnoses.isLoading;
    const tokenSelector = createSelector(
        selectToken,
        token => token
    );
    const diagnosesSelector = createSelector(
        selectDiagnose,
        diagnoses => diagnoses
    );
    const diagnoisesIsLoadingSelector = createSelector(
        selectDiagnosesIsLoading,
        isLoading => isLoading
    );
    const diagnoses = useSelector(diagnosesSelector, shallowEqual);
    const token = useSelector(tokenSelector);
    const isLoading = useSelector(diagnoisesIsLoadingSelector);
    const dispatch = useDispatch();

    const handleAdd = () => {
        props.toggleRightMenu(true, {
            "add": true
        })
    };

    const handleUpdate = (id, date, diagnose,imageUrl) => {
        props.toggleRightMenu(true, {
            "id": id,
            "update": true,
            "diagnose": diagnose,
            "date": new Date(date),
            "imageUrl": imageUrl
        })
    };

    useEffect(() => {
        dispatch(getDiagnoses({
            token: token, patientId: props.patientId,
            doctorId: props.doctorId, clinicId: props.clinicId
        }))

    }, [props.clinicId, props.doctorId, props.patientId, token, dispatch]);

    return (
        <div>
                {isLoading == true? <LinearProgress />:<div>
            <IconButton aria-label="add" className={classes.addButton} onClick={() => { handleAdd() }}>
                <AddBox className={classes.addIcon} />
            </IconButton>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <div className={classes.scroll}> <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {diagnoses?.map((row) => {
                                return (
                                    <TableRow className="tableRow"
                                        onClick={() => { handleUpdate(row.id, row.date, row.diagnose, row.imageUrl) }}
                                        hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns?.map((column) => {
                                            var value = row[column.id];
                                            if (column.id === 'createdAt' || column.id === 'updatedAt' || column.id === 'date') {
                                                value = value?.split('T')[0];
                                            }
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                   {column.id != 'imageUrl' || value == null ? value:<a target="_blank" href={value}>Show</a>} 
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table></div>
                </TableContainer>
            </Paper>
            </div>}
        </div>
    );
}