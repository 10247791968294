import React, { Component } from 'react'
import { connect } from 'react-redux'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { searchPatients, getPatients, clearError } from '../../store/actions/patientsAction'
import { getDoctors } from '../../store/actions/doctorAction'
import { getPayments } from '../../store/actions/paymentAction'
import { getServices } from '../../store/actions/serviceAction'
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PatientCard from './PatientCard'
import AddPatient from './AddPatient'
import EventOutlined from '@material-ui/icons/EventOutlined';
import AttachMoneyRounded from '@material-ui/icons/AttachMoneyRounded';
import LocalAtmOutlined from '@material-ui/icons/LocalAtmOutlined';
import LocalHospitalOutlined from '@material-ui/icons/LocalHospitalOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import ImageAspectRatioOutlined from '@material-ui/icons/ImageAspectRatioOutlined';
import Button from '@material-ui/core/Button';
import Payment from './Payment';
import Service from './Service';
import PAppointment from './PAppointment';
import Diagnose from './Diagnose';
import Treatment from './Treatment';
import Examination from './Examination';
import PaymentMenu from './PaymentMenu';
import ServiceMenu from './ServiceMenu';
import AppointmentMenu from './AppointmentMenu';
import DiagnoseMenu from './DiagnoseMenu';
import TreatmentMenu from './TreatmentMenu';
import ExaminationMenu from './ExaminationMenu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Route, Link, Switch } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Pagination from '@material-ui/lab/Pagination';


class Patients extends Component {
  selectedDoctorIdFromRoute = this.props.location.pathname.split('/')[2]?.toLocaleLowerCase();
  selectedPatientIdFromRoute = this.props.location.pathname.split('/')[3]?.toLocaleLowerCase();
  selectedTabFromRoute = this.props.location.pathname.split('/')[4]?.toLocaleLowerCase();
  error = '';
  serachTimer;

  state = {
    dialogOpened: false,
    filterValue: '',
    drawerStyle: 'none',
    selectedPatientId: this.selectedPatientIdFromRoute,
    doctorId: this.selectedDoctorIdFromRoute,
    selectedTab: this.selectedTabFromRoute,
    displayTeethPanel: false,
    page: 1,
    numberOfItems: 25,
    alignment: 'Adult',
    isSearch:false
  }


  componentDidMount() {
    if (this.props.role != 'Doctor') {
      this.props.getDoctors(this.props.token);
    } else {
      this.setState({ doctorId: this.props.doctorId });
      this.props.getPatients(this.props.token, this.props.clinicId, this.props.doctorId, this.state.page, this.state.numberOfItems);
    }

  }

  componentDidUpdate(prevProps) {
    if (this.props.doctors !== prevProps.doctors) {
      var selectedDoctor;
      if (this.props.role == 'Doctor') {
        selectedDoctor = this.props.doctorId;
      } else {
        selectedDoctor = this.selectedDoctorIdFromRoute ?? this.props.doctors[0].id
      }
      this.setState({ doctorId: selectedDoctor });

      this.props.getPatients(this.props.token, this.props.clinicId, selectedDoctor, this.state.page, this.state.numberOfItems);
    }

    if (this.props.patients !== prevProps.patients) {
      var routePatientId = this.props.location.pathname.split('/')[3]?.toLocaleLowerCase();
      var patient = this.props.patients?.find(patient => patient.id == routePatientId);
      this.setState({ selectedPatient: patient });

    }
  }

  selectPatient = (e) => {
    const patientId = e.currentTarget.tabIndex;

    var selectedPatient = this.props.patients.find(patient => patient.id == patientId);

    selectedPatient.dateOfBirth = selectedPatient.dateOfBirth ?
      selectedPatient.dateOfBirth.split('T')[0] : null;

    this.setState({
      id: selectedPatient.id,
      name: selectedPatient.name ? selectedPatient.name : '',
      dateOfBirth: selectedPatient.dateOfBirth ? selectedPatient.dateOfBirth.split('T')[0] : '',
      gender: selectedPatient.gender,
      mobileNumber: selectedPatient.mobileNumber ? selectedPatient.mobileNumber : '',
      email: selectedPatient.email ? selectedPatient.email : '',
      address: selectedPatient.address ? selectedPatient.address : '',
      selectedPatient: selectedPatient,
      createdAt: selectedPatient.createdAt ? selectedPatient.createdAt.split('T')[0] : '',
      updatedAt: selectedPatient.updatedAt ? selectedPatient.updatedAt.split('T')[0] : '',
      selectedPatientId: selectedPatient.id,
      editMode: false,
      doctorId: this.state.doctorId,
      filterValue: '',
      selectedTab: null,
      isSearch:false
    });

    this.props.getPayments({
      token: this.props.token,
      patientId: selectedPatient.id,
      doctorId: this.state.doctorId,
      clinicId: this.props.clinicId
    });

    this.props.getServices({
      token: this.props.token,
      patientId: selectedPatient.id,
      doctorId: this.state.doctorId,
      clinicId: this.props.clinicId
    });

  }

  updatePatientState = (patient) => {
    const selectedPatient = this.state.selectedPatient;
    selectedPatient.name = patient.name;
    selectedPatient.dateOfBirth = patient.dateOfBirth;
    selectedPatient.gender = patient.gender;
    selectedPatient.mobileNumber = patient.mobileNumber;
    selectedPatient.email = patient.email;
    selectedPatient.address = patient.address;

    this.setState({
      selectedPatient: selectedPatient
    });
  }

  handleClickOpen = () => {
    this.setState({
      dialogOpened: true,
      filterValue: ''
    });
  };

  handleClose = () => {
    this.setState({
      dialogOpened: false
    });
  };

  handleChangeTab = (tabName) => {
    this.setState({
      drawerStyle: 'none',
      selectedTab: tabName
    });
  }

  showError = (error) => {
    this.error = error;
  }

  handleCloseError = () => {
    this.error = '';
    this.props.clearError();
  }

  toggleRightMenu = (state, body) => {
    if (state === true) {
      this.setState({
        drawerStyle: 'block',
        rightMenuData: body
      });
    }
    else {
      this.setState({
        drawerStyle: 'none'
      });
    }
  }

  filterByNames = (e) => {

    var name = e.target.value;
    this.setState({
      filterValue: e.target.value,
      page: 1,
      isSearch: true
    });

    clearTimeout(this.serachTimer);

    this.serachTimer = setTimeout(() => {
      if(name== ""){
        this.props.getPatients(this.props.token, this.props.clinicId, this.state.doctorId, this.state.page, this.state.numberOfItems);
   
      }else{
      this.props.searchPatients(this.props.token, this.props.clinicId, this.state.doctorId, this.state.page, this.state.numberOfItems, name);
      }
    }, 2000);

  }


  handleChangeDoctor = (e) => {
    this.setState({
      doctorId: e.target.value
    });

    this.props.getPatients(this.props.token, this.props.clinicId, e.target.value, this.state.page, this.state.numberOfItems);

    this.props.history.push(`/patients/${e.target.value}`);

  }

  handleChangePagination = (item, page) => {
    this.setState({
      page: page
    });

    if (this.state.filterValue == '') {

      this.props.getPatients(this.props.token, this.props.clinicId, this.state.doctorId, page, this.state.numberOfItems);
    } else {
      this.props.searchPatients(this.props.token, this.props.clinicId, this.state.doctorId, this.state.page, this.state.numberOfItems, this.state.filterValue);
    }


  }
  render() {
    var drawerStyle = {
      display: this.state.drawerStyle
    }

    if (this.props.error) { this.showError(this.props.error) }
    const patientsList = this.state.filterValue != '' && this.state.filterdPatients != undefined ? this.state.filterdPatients.map((patient) =>
      <div className="patientPanel">

        <Link to={`/patients/${patient.id}/${patient.id}`}>
          <ListItem selected={patient.id == this.state.selectedPatientId
            && patient.id == this.state.doctorId} key={patient.id} id={patient.id}
            onClick={(e) => { this.selectPatient(e) }} tabIndex={patient.id} button >
            <Avatar className='avatar'>{patient.name[0]}</Avatar>
            <ListItemText className='patientName' primary={patient.name} />
          </ListItem>
        </Link>

      </div>)
      :
      this.props.patients?.map((patient) =>
        <div className="patientPanel">
          <Link to={`/patients/${this.state.doctorId}/${patient.id}`}>
            <ListItem selected={patient.id == this.state.selectedPatientId} key={patient.id} id={patient.id}
              onClick={(e) => { this.selectPatient(e) }} tabIndex={patient.id} button >
              <Avatar className='avatar'>{patient.name[0]}</Avatar>
              <ListItemText className='patientName' primary={patient.name} />
            </ListItem>
          </Link>


        </div>);

    return (
      <div className='patients'>
        <div className="patientList">
          <Button variant="outlined" onClick={this.handleClickOpen} className='addPatientButton white'
            color="primary" startIcon={<AddIcon></AddIcon>}
          >Add patient</Button>

          {this.props.role != 'Doctor' ? <FormControl fullWidth className="doctorSelector" >
            <InputLabel id="doctor-select-label">Doctor:</InputLabel>
            <Select
              labelId="doctors"
              id="doctor-select"
              autoFocus={true}
              value={parseInt(this.state.doctorId)}
              onChange={this.handleChangeDoctor}
            >

              {this.props.doctors ? this.props.doctors.map(doctor =>
                <MenuItem value={parseInt(doctor.id)}>{doctor.name}</MenuItem>

              ) : null}

            </Select>
          </FormControl> : null}

          <TextField
            id="Filter patients"
            label="Search"
            style={{ margin: 8 }}
            placeholder="Ex. Ali"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.filterByNames}
            value={this.state.filterValue}
          />
          {this.props.isLoading == true ?
            <LinearProgress /> :
            <List className="patientsListItems">
              {patientsList}

            </List>}

          <Pagination page={this.state.page} onChange={this.handleChangePagination} className="pagination" count={this.props.pageCount} color="primary" size="small" />
        </div>


        <Divider className='patientDivider' orientation="vertical" flexItem />

      {this.state.isSearch ==false ? <div className="patientInfo">
          <Route path="/patients/:doctorId/:patientId"
            render={() => <PatientCard
              clinicId={this.props.clinicId}
              page={this.state.page}
              limit={this.state.numberOfItems}
              doctorId={this.state.doctorId}
              selectedPatient={this.state.selectedPatient}
              updatePatientState={this.updatePatientState}
            ></PatientCard>}>
          </Route>

          <div className="flexDiv">
            <div className="patientOptions">
              <Route path="/patients/:doctorId/:patientId"
                render={() => <List aria-label="mailbox folders">
                  <Link to={`/patients/${this.state.doctorId}/${this.state.selectedPatientId}/treatments`}>
                    <ListItem onClick={() => { this.handleChangeTab('treatments') }}
                      selected={this.state.selectedTab === 'treatments'} key="Treatments"
                      id="Treatments" button divider>
                      <ListItemIcon> <LocalHospitalOutlined /></ListItemIcon>
                      <ListItemText primary="Treatments" />
                    </ListItem>
                  </Link>
                  
                 

                  <Link to={`/patients/${this.state.doctorId}/${this.state.selectedPatientId}/diagnosis`}>
                    <ListItem onClick={() => { this.handleChangeTab('diagnosis') }}
                      selected={this.state.selectedTab === 'diagnosis'} key="Diagnosis"
                      id="Diagnosis" button divider>
                      <ListItemIcon> <ImageAspectRatioOutlined /></ListItemIcon>
                      <ListItemText primary="Lab/Rad" />
                    </ListItem>
                  </Link>

                  

                  <Link to={`/patients/${this.state.doctorId}/${this.state.selectedPatientId}/examinations`}>
                    <ListItem onClick={() => { this.handleChangeTab('examinations') }}
                      selected={this.state.selectedTab === 'examinations'} key="Examinations"
                      id="Examinations" divider button>
                      <ListItemIcon> <ReceiptOutlinedIcon /></ListItemIcon>
                      <ListItemText primary="Prescription" />
                    </ListItem>
                  </Link>
                  <br></br>
                  <br></br>
                  <Divider></Divider>
                  <Link to={`/patients/${this.state.doctorId}/${this.state.selectedPatientId}/appointments`}>
                    <ListItem onClick={() => { this.handleChangeTab('appointments') }}
                      selected={this.state.selectedTab === 'appointments'} key="Appointments"
                      id="Appointments" button divider>
                      <ListItemIcon> <EventOutlined /></ListItemIcon>
                      <ListItemText primary="Appointments" />
                    </ListItem>
                  </Link>

                  <br></br>
                  <br></br>

                 

                  <Divider></Divider>
                  <Link to={`/patients/${this.state.doctorId}/${this.state.selectedPatientId}/payments`}>
                    <ListItem onClick={() => { this.handleChangeTab('payments') }}
                      selected={this.state.selectedTab === 'payments'} id="Payments"
                      key="Payments" button divider>
                      <ListItemIcon> <LocalAtmOutlined /></ListItemIcon>
                      <ListItemText primary="Payments" />
                    </ListItem >
                  </Link>

                  <Link to={`/patients/${this.state.doctorId}/${this.state.selectedPatientId}/services`}>
                    <ListItem onClick={() => { this.handleChangeTab('services') }}
                      selected={this.state.selectedTab === 'services'} key="Services"
                      id="Services" button divider>
                      <ListItemIcon> <AttachMoneyRounded /></ListItemIcon>
                      <ListItemText primary="Costs" />
                    </ListItem>
                  </Link>
                </List>}>
              </Route>
            </div>

            {
              this.state.selectedPatientId != null ?
                <div className="flexChild">
                  <Divider className='InsideDivider' orientation="vertical" flexItem />
                </div> : null
            }

            <div className="tableComponent">
              <Route path={`/patients/:doctorId/:patientId/payments`}
                render={() => <Payment toggleRightMenu={this.toggleRightMenu}
                  clinicId={this.props.clinicId} patientId={this.state.selectedPatientId}
                  doctorId={this.state.doctorId}></Payment>}>
              </Route>
              <Route path={`/patients/:doctorId/:patientId/services`}
                render={() => <Service toggleRightMenu={this.toggleRightMenu}
                  clinicId={this.props.clinicId} patientId={this.state.selectedPatientId}
                  doctorId={this.state.doctorId}></Service>}>
              </Route>
              <Route path={`/patients/:doctorId/:patientId/appointments`}
                render={() => <PAppointment toggleRightMenu={this.toggleRightMenu}
                  clinicId={this.props.clinicId} patientId={this.state.selectedPatientId}
                  doctorId={this.state.doctorId}></PAppointment>}>
              </Route>
              <Route path={`/patients/:doctorId/:patientId/diagnosis`}
                render={() => <Diagnose toggleRightMenu={this.toggleRightMenu}
                  clinicId={this.props.clinicId} patientId={this.state.selectedPatientId}
                  doctorId={this.state.doctorId}></Diagnose>}>
              </Route>
              <Route path={`/patients/:doctorId/:patientId/treatments`}
                render={() => <Treatment toggleRightMenu={this.toggleRightMenu}
                  clinicId={this.props.clinicId} patientId={this.state.selectedPatientId}
                  doctorId={this.state.doctorId} ></Treatment>}>
              </Route>
              <Route path={`/patients/:doctorId/:patientId/examinations`}
                render={() => <Examination toggleRightMenu={this.toggleRightMenu}
                  clinicId={this.props.clinicId} patientId={this.state.selectedPatientId}
                  doctorId={this.state.doctorId}></Examination>}>
              </Route>
            </div>
          </div>
        </div> :null}

        <AddPatient dialogOpened={this.state.dialogOpened} closePopUp={this.handleClose}
          clinicId={this.props.clinicId}
          page={this.state.page}
          limit={this.state.numberOfItems}
          doctorId={this.state.doctorId}> </AddPatient>

        <div>
          <Snackbar className="snackbar"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.error != ''}
            autoHideDuration={5000}
            onClose={this.handleCloseError}
            message={this.error}
            action={
              <React.Fragment>
                <IconButton size="medium" aria-label="close" onClick={this.handleCloseError}>
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />
        </div>

        <div style={drawerStyle}
          className='rightDrawer'>
          <Switch>
            <Route path={`/patients/:doctorId/:patientId/payments`}
              render={() => <PaymentMenu
                toggleRightMenu={this.toggleRightMenu}
                doctorId={this.state.doctorId}
                clinicId={this.props.clinicId}
                patientId={this.state.selectedPatientId}
                data={this.state.rightMenuData}></PaymentMenu>}>
            </Route>
            <Route path={`/patients/:doctorId/:patientId/services`}
              render={() => <ServiceMenu
                toggleRightMenu={this.toggleRightMenu}
                doctorId={this.state.doctorId}
                clinicId={this.props.clinicId}
                patientId={this.state.selectedPatientId}
                data={this.state.rightMenuData}></ServiceMenu>}>
            </Route>
            <Route path={`/patients/:doctorId/:patientId/appointments`}
              render={() => <AppointmentMenu
                toggleRightMenu={this.toggleRightMenu}
                doctorId={this.state.doctorId}
                clinicId={this.props.clinicId}
                patientId={this.state.selectedPatientId}
                data={this.state.rightMenuData}></AppointmentMenu>}>
            </Route>
            <Route path={`/patients/:doctorId/:patientId/diagnosis`}
              render={() => <DiagnoseMenu
                toggleRightMenu={this.toggleRightMenu}
                doctorId={this.state.doctorId}
                clinicId={this.props.clinicId}
                patientId={this.state.selectedPatientId}
                data={this.state.rightMenuData}></DiagnoseMenu>}>
            </Route>
            <Route path={`/patients/:doctorId/:patientId/treatments`}
              render={() => <TreatmentMenu
                toggleRightMenu={this.toggleRightMenu}
                doctorId={this.state.doctorId}
                clinicId={this.props.clinicId}
                patientId={this.state.selectedPatientId}
                data={this.state.rightMenuData}></TreatmentMenu>}>
            </Route>
            <Route path={`/patients/:doctorId/:patientId/examinations`}
              render={() => <ExaminationMenu
                toggleRightMenu={this.toggleRightMenu}
                doctorId={this.state.doctorId}
                clinicId={this.props.clinicId}
                patientId={this.state.selectedPatientId}
                data={this.state.rightMenuData}></ExaminationMenu>}>
            </Route>
          </Switch>
        </div>

      
      </div>
    )//End of return
  }//End of render
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    doctorId: state.auth.doctorId,
    role: state.auth.role,
    patients: state.patients.patients,
    error: state.patients.error,
    clinicId: state.auth.clinicId,
    isLoading: state.patients.isLoading,
    doctors: state.doctors.doctors,
    pageCount: state.patients.pageCount
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPatients: (token, clinicId, doctorId, limit, page) => dispatch(getPatients(token, clinicId, doctorId, limit, page)),
    searchPatients: (token, clinicId, doctorId, limit, page, name) => dispatch(searchPatients(token, clinicId, doctorId, limit, page, name)),
    clearError: () => dispatch(clearError()),
    getPayments: (info) => dispatch(getPayments(info)),
    getServices: (info) => dispatch(getServices(info)),
    getDoctors: (token) => dispatch(getDoctors(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Patients);