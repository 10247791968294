import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { getDoctors, getBillingss } from '../../store/actions/billingsAction';
import { clearAppointmentsError, getAppointmentsReposrt } from '../../store/actions/appointmentAction';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import { useLocation } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
const useStyles = makeStyles({
    flex: {
        display: 'flex'
    },
    tableBody: {
        overflow: 'auto',
        maxHeight: '80vh'
    },
    date: {
        minWidth: '12vw'
    },
    doctors: {
        marginTop: '20px !important'
    },
    progress: {
        minWidth: '50vw'
    },
    root:{
        margin:'10px',
        maxHeight:'87vh',
        overflow:'auto'
    }
});

const columns = [
    { id: 'name', label: 'Patient', minWidth: 200 },
    { id: 'date', label: 'Date', minWidth: 100 },   
    { id: 'time', label: 'Time', minWidth: 100 },
    { id: 'period', label: 'Duration', minWidth: 80 },
    { id: 'comment', label: 'Comment', minWidth: 100 },
    { id: 'loggedBy', label: 'Logged by', minWidth: 100 },
    { id: 'createdAt', label: 'Logged At', minWidth: 40 },
    { id: 'updatedBy', label: 'Updated by', minWidth: 100 }
];

export default function Appointment() {

    const location = useLocation();
    var selectedDoctorIdFromRoute = location.pathname.split('/')[2]?.toLocaleLowerCase();
    const [selectedDate, handleDateChange] = React.useState(new Date());
    const classes = useStyles();

    const selectToken = state => state.auth.token;
    const selectRole = state => state.auth.role;
    const selectClinicId = state => state.auth.clinicId;
    const selectDoctors = state => state.doctors.doctors;
    const selectAppointmetsReport = state => state.appointments.appointmentsReport;
    const selectError = state => state.appointments.error;
    const selectLoading = state => state.appointments.isLoading;

    const tokenSelector = createSelector(
        selectToken,
        token => token
    );

    const roleSelector = createSelector(
        selectRole,
        role => role
    );

    const clinicIdSelector = createSelector(
        selectClinicId,
        clinicId => clinicId
    );

    const doctorSelector = createSelector(
        selectDoctors,
        doctors => doctors
    );

    const appointmetsReportSelector = createSelector(
        selectAppointmetsReport,
        appointments => appointments
    );

    const errorSelector = createSelector(
        selectError,
        error => error
    );

    const loadingSelector = createSelector(
        selectLoading,
        loading => loading
    );

    const token = useSelector(tokenSelector);
    const role = useSelector(roleSelector);
    const clinicId = useSelector(clinicIdSelector);
    const doctors = useSelector(doctorSelector);
    const appointmentsReport = useSelector(appointmetsReportSelector);
    const error = useSelector(errorSelector);
    const loading = useSelector(loadingSelector);
    const dispatch = useDispatch();

    var currentDate = new Date();


    useEffect(() => {
        if(role != "Doctor")
        {
            dispatch(getDoctors(token));
        }
        if (selectedDoctorIdFromRoute) {
            const formattedDate = currentDate.toISOString().slice(0, 10);
            dispatch(getAppointmentsReposrt(
                {
                    token: token,
                    clinicId: clinicId,
                    doctorId: selectedDoctorIdFromRoute,
                    date: formattedDate
                }));
            handleDateChange(currentDate);
        }
    }, [selectedDoctorIdFromRoute]);

    const updateDate = (e) => {
        handleDateChange(e);
        const formattedDate = e.toISOString().slice(0, 10);

        dispatch(getAppointmentsReposrt(
            {
                token: token,
                clinicId: clinicId,
                doctorId: selectedDoctorIdFromRoute,
                date: formattedDate
            }));
    }

    return (
        <div >
            <div className={classes.flex}>
                <div className={classes.date}>
                    <MuiPickersUtilsProvider required utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            label="Select date"
                            value={selectedDate}
                            format="yyyy-MM-dd"
                            onChange={(e) => updateDate(e)}
                        />
                    </MuiPickersUtilsProvider>

                    <div className={classes.doctors} >
                        {doctors?.map((doctor) => (
                            <Link to={`/appointments/${doctor.id}`}>
                                <ListItem selected={selectedDoctorIdFromRoute == doctor.id} button key={1}>
                                    <ListItemText primary={doctor.name} />
                                </ListItem>
                            </Link>

                        ))}
                    </div>

                </div>

                {doctors != null ? <Divider className='patientDivider' orientation="vertical" flexItem />
                    : null}
                <div>

                </div>

                {loading == null || selectedDoctorIdFromRoute == null ? <Typography className={classes.root}>Please select a doctors from the doctors list! </Typography> : loading === false ?


                    <Paper className={classes.root}>

                        <TableContainer className={classes.container}>
                            <div className={classes.scroll}> <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {appointmentsReport?.map((row) => {
                                        return (
                                            <TableRow className="tableRow"
                                                hover tabIndex={-1} key={row.code}>
                                                {columns?.map((column) => {
                                                    var value = "";
                                                    if(column.id == "name")
                                                    value =  row["Patients"].name;
                                                    else if ( column.id == "time"){

                                                        // value = row["date"]?.split('T')[1].slice(0,5);
                                                        value =  new Date(row["date"]).toLocaleTimeString();
                                                    }
                                                    else
                                                     value = row[column.id];
                                                    if (column.id === 'createdAt' || column.id === 'date') {
                                                        value = value?.split('T')[0];
                                                    }
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table></div>
                        </TableContainer>
                    </Paper>

                    : <div className={classes.progress}>
                        <LinearProgress />
                    </div>}



            </div>
            <div>
                <Snackbar className="snackbar"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={error != null}
                    autoHideDuration={5000}
                    onClose={() => { dispatch(clearAppointmentsError()) }}
                    message={error}
                    action={
                        <React.Fragment>
                            <IconButton size="medium" aria-label="close" onClick={() => { dispatch(clearAppointmentsError()) }} >
                                <CloseIcon className={classes.closeIcon} />
                            </IconButton>
                        </React.Fragment>
                    }
                />
            </div>
        </div>

    );
}