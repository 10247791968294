import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { makeStyles } from '@material-ui/core/styles';
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryLink } from "../../components/misc/Links";
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/logo.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";


const useStyles = makeStyles({
  NavLink: {'color': 'rgb(36, 62, 99)', 'font-family': 'Inter, system-ui', "&:hover" :{ 'color' : 'rgb(100, 21, 255) !important' ,'border': '0 solid rgb(100, 21, 255)!important'} },
  
  NavTitle:{
    color: "rgb(247, 250, 252)", 'font-family': 'Inter, system-ui', backgroundColor: 'rgb(100, 21, 255)',
    'font-weight': 600, 'letter-spacing': '0.025em',padding:'0.75rem 2rem','font-size': '0.875rem'
  },
  SignUp:{ color:"rgb(247, 250, 252)!important", 'font-family': 'Inter, system-ui', backgroundColor: 'rgb(100, 21, 255)',
  'font-weight': 600, 'letter-spacing': '0.025em',padding:'0.75rem 2rem','font-size': '0.875rem',
    "&:hover" :{
    'background-color': 'rgb(80, 17, 204)',
    }
  }}
);

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-blue-700 will apply the bg-blue-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 
  font-semibold tracking-wide transition 
  pb-1 border-b-2 border-transparent
`;

export const redLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-gray-200 text-gray-100
  hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between `;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none  transition duration-300 
`;


export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg" }) => {

  const classes = useStyles();

  const defaultLinks = [
    <NavLinks  id="content-mobile-nav"  key={1}>
      <NavLink id="content-mobile"  className={classes.NavLink }  href="/#about">About</NavLink>
      <NavLink id="content-mobile" className={classes.NavLink } href="/#pricing">Pricing</NavLink>
      <NavLink id="content-mobile"  className={classes.NavLink } href="/#contact">Contact Us</NavLink>
      <NavLink id="content-mobile"  className={classes.NavLink }   href="/login" tw="lg:ml-12!">
        Login
      </NavLink>
      <PrimaryLink id="content-mobile"  className={classes.SignUp}
      css={roundedHeaderButton && tw`rounded-full`}href="/signup">Sign Up</PrimaryLink>
    </NavLinks>
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink className={classes.NavLink} href="/">
      <img src={logo} alt="logo" />
      Exa Clinic
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer  css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
           <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};


const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};
