import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getClinics } from '../../store/actions/doctorAction';
import { selectClinic , logout} from '../../store/actions/authAction';
import { createSelector } from 'reselect';
import Paper from '@material-ui/core/Paper';
import { Grid, Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
const useStyles = makeStyles({
    grid: {
       height:'50vh' 
     
    },
    paper: {
        margin: '10px',
        height:'200px',
        width:'150px',
        cursor:'pointer'
    }
});

export default function DoctorClinics(props) {
    const classes = useStyles();
    const selectToken = state => state.auth.token;
    const selectClinics = state => state.doctors.clinics;

    const tokenSelector = createSelector(
        selectToken,
        token => token
    );
    const clinicsSelector = createSelector(
        selectClinics,
        clinics => clinics
    );

    const clinics = useSelector(clinicsSelector);
    const token = useSelector(tokenSelector);

    const dispatch = useDispatch();
  
    useEffect(() => {
        dispatch(getClinics(token))
    }, []);

    const selectDoctor = (clinicId) => {
        dispatch(selectClinic(clinicId))
        props.history.push('/');
    };

    const logOut = () =>{
        dispatch(logout());
        props.history.push('/');
    }

    return (

        <div>
    
       <Grid className={classes.grid}
        container
        direction="row"
        justify="center"
        alignItems="center">
            {clinics?.length > 0 ? clinics?.map((clinic) =>{
                return  <Paper key={clinic.id}  onClick={() => selectDoctor(clinic.id)} className={classes.paper}>
                    <Typography variant="h4">
                    {clinic.name}
                    </Typography >
                    <Typography variant="h6">
                    {clinic.email}
                    </Typography>    
                    <Typography variant="h6">
                    {clinic.mobileNumber}
                    </Typography>           
                    
                    </Paper>
            } ) : 
            <div> You don't have any clinics!</div>
         }
        
           </Grid>
     
        
           <Button variant="outlined" onClick={ () => logOut()}>Log out</Button>
        
        </div>
    );
}