const initState = {
    billings: null,
    paymentsSum: null,
    servicesSum: null,
    error: null,
    loading: null
}

const billingsReducer = (state = initState, action) => {

    switch (action.type) {
        case "BILLINGS_RETRIVED":
            return {
                ...state, billings: action.billings.data.data.dates,
                paymentsSum: action.billings.data.data.paymentsSum,
                servicesSum: action.billings.data.data.servicesSum,
                loading: false
            }
        case "BILLINGS_ERROR":
            return {
                ...state,
                error: action.message.message,
                loading: false
            }
        case "BILLINGS_LOADING":
            return { ...state, loading: true }
        case "CLEAR_BILLINGS_ERROR":
            return { ...state, error: null }
        default:
            return state
    }
};

export default billingsReducer;