import axios from 'axios';
import { getUrl, checkError } from './actionsUtil';

export const getPayments = (info) => {
  return (dispatch) => {
    dispatch({ type: 'PAYMENTS_ISLOADING' });
    const baseUrl = getUrl();
    const path = 'api/payment';

    const api = axios.create({
      baseURL: baseUrl, headers: {
        'Authorization': `Bearer ${info.token}`
      }
    });

    api.get(path, {
      params: {
        patientId: parseInt(info.patientId),
        doctorId: parseInt(info.doctorId),
        clinicId: parseInt(info.clinicId)
      }
    })
      .then(response => {
        dispatch({ type: 'GET_PAYMENTS', response });
      })
      .catch(error => {
        var message = checkError(error);
        dispatch({ type: 'PATIENT_ERROR', message });
        dispatch({ type: 'PAYMENTS_ISLOADING_STOP' });

      });
  }
}

export const addPayment = (info) => {
  return (dispatch) => {
    dispatch({ type: 'PAYMENTS_ISLOADING' });
    const baseUrl = getUrl();
    const path = 'api/payment';

    const api = axios.create({
      baseURL: baseUrl, headers: {
        'Authorization': `Bearer ${info.token}`
      }
    });

    const userTimezoneOffset = info.body.date.getTimezoneOffset() * 60000;
    const dateWithoutTimeZone = new Date(info.body.date.getTime() - userTimezoneOffset);

    var payment = {
      amount: info.body.amount,
      date: dateWithoutTimeZone,
      clinicId: info.clinicId,
      doctorId: info.doctorId,
      patientId: info.patientId,
    };

    api.post(path, payment)
      .then(response => {
        dispatch({ type: 'ADD_PAYMENT', response });

        api.get(path, {
          params: {
            patientId: parseInt(info.patientId),
            doctorId: parseInt(info.doctorId),
            clinicId: parseInt(info.clinicId)
          }
        })
          .then(response => {
            dispatch({ type: 'GET_PAYMENTS', response });
          }).catch(error => {
            var message = checkError(error);
            dispatch({ type: 'PATIENT_ERROR', message });
            dispatch({ type: 'PAYMENTS_ISLOADING_STOP' });
          });
      })
      .catch(error => {
        var message = checkError(error);
        dispatch({ type: 'PATIENT_ERROR', message });
        dispatch({ type: 'PAYMENTS_ISLOADING_STOP' });
      });
  }
}

export const updatePayment = (info) => {
  return (dispatch) => {
    dispatch({ type: 'PAYMENTS_ISLOADING' });
    const baseUrl = getUrl();
    const path = 'api/payment';
    const updatePath = `api/payment/${info.id}`;

    const api = axios.create({
      baseURL: baseUrl, headers: {
        'Authorization': `Bearer ${info.token}`
      }
    });

    const userTimezoneOffset = info.body.date.getTimezoneOffset() * 60000;
    const dateWithoutTimeZone = new Date(info.body.date.getTime() - userTimezoneOffset);

    var payment = {
      amount: info.body.amount,
      date: dateWithoutTimeZone,
      clinicId: info.clinicId,
      doctorId: info.doctorId,
      patientId: info.patientId,
    };

    api.put(updatePath, payment)
      .then(response => {
        dispatch({ type: 'UPDATE_PAYMENT', response });

        api.get(path, {
          params: {
            patientId: parseInt(info.patientId),
            doctorId: parseInt(info.doctorId),
            clinicId: parseInt(info.clinicId)
          }
        })
          .then(response => {
            dispatch({ type: 'GET_PAYMENTS', response });
          }).catch(error => {
            var message = checkError(error);
            dispatch({ type: 'PATIENT_ERROR', message });
            dispatch({ type: 'PAYMENTS_ISLOADING_STOP' });
          });
      })
      .catch(error => {
        var message = checkError(error);
        dispatch({ type: 'PATIENT_ERROR', message });
        dispatch({ type: 'PAYMENTS_ISLOADING_STOP' });
      });
  }
}

export const deletePayment = (info) => {
  return (dispatch) => {
    dispatch({ type: 'PAYMENTS_ISLOADING' });
    const baseUrl = getUrl();
    const path = 'api/payment';
    const deletePath = `api/payment/${info.id}`;

    const api = axios.create({
      baseURL: baseUrl, headers: {
        'Authorization': `Bearer ${info.token}`
      }
    });

    api.delete(deletePath, {
      params: {
        patientId: parseInt(info.patientId),
        doctorId: parseInt(info.doctorId),
        clinicId: parseInt(info.clinicId)
      }
    })
      .then(response => {
        dispatch({ type: 'DELETE_PAYMENT', response });

        api.get(path, {
          params: {
            patientId: parseInt(info.patientId),
            doctorId: parseInt(info.doctorId),
            clinicId: parseInt(info.clinicId)
          }
        })
          .then(response => {
            dispatch({ type: 'GET_PAYMENTS', response });
          }).catch(error => {
            var message = checkError(error);
            dispatch({ type: 'PATIENT_ERROR', message });
            dispatch({ type: 'PAYMENTS_ISLOADING_STOP' });
          });
      })
      .catch(error => {
        var message = checkError(error);
        dispatch({ type: 'PATIENT_ERROR', message });
        dispatch({ type: 'PAYMENTS_ISLOADING_STOP' });
      });
  }
}