import React, { Component } from 'react'
import { connect } from 'react-redux'
import NavBar from '../layout/Navbar'
import './dashboard.css';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PermContactCalendarOutlined from '@material-ui/icons/PermContactCalendarOutlined';
import PeopleOutlined from '@material-ui/icons/PeopleOutlined';
import AssignmentIndOutlined from '@material-ui/icons/AssignmentIndOutlined';
import AccountBalanceOutlined from '@material-ui/icons/AccountBalanceOutlined';
import EventNoteOutlined from '@material-ui/icons/EventNoteOutlined';
import DashboardOutlined from '@material-ui/icons/DashboardOutlined';
import Appointment from './Appointment';
import Patients from './Patients';
import Secretaries from './Secretaries';
import Billings from './Billings';
import Doctors from './Doctors';
import Home from './Home';
import List from '@material-ui/core/List';
import { Route, Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import MainPage from '../Main/MainPage';

class Dashboard extends Component {

  state = {
    isMinimized: true,
    menuOpen: true,
    width: '60px',
    marginLeft: '75px'
  }

  toggleMenu = () => {
    let newStatus = !this.state.isMinimized;
    let width = !this.state.isMinimized ? '60px' : '200px'
    let marginLeft = !this.state.isMinimized ? '80px' : '220px'
    this.setState({
      isMinimized: newStatus,
      width: width,
      marginLeft: marginLeft
    });
  }
  toggleSidebar = (event) => {

    this.setState({ menuOpen: !this.state.menuOpen });
  }

  render() {
    var { token } = this.props;
    var doctorId = this.props.doctorId == undefined ? "" : `/${this.props.doctorId}`
    if (!token) {
      return <MainPage/>
    }
    if (this.props.history.location.pathname == '/')
      this.props.history.push('dashboard')

    if (this.props.clinicId === undefined) {
      return <Redirect to='/SelectClinic' />
    }

    const drawerStyle = {
      width: this.state.width,
      transition: 'width 0.4s'
    }

    const contentStyle = {
      'margin-left': this.state.marginLeft,
      transition: 'all 0.3s ease-in-out'
    }

    const selectedTab = this.props.location.pathname.split('/')[1].toLocaleLowerCase();

    return (

      <div >

        <NavBar toggleMenu={this.toggleMenu} />
        <Drawer variant="permanent">
          <div style={drawerStyle} className="links">
            <List   >


              <Link to="/dashboard">
                <ListItem selected={selectedTab === 'dashboard'} className="listItem" key={1} button >
                  <ListItemIcon> <DashboardOutlined /></ListItemIcon>
                  {this.state.isMinimized ? null : <ListItemText primary="Dashboard" />}
                </ListItem>
              </Link>


              <Link to="/patients">
                <ListItem selected={selectedTab === 'patients'} className="listItem" key={2} button >
                  <ListItemIcon> <PeopleOutlined /></ListItemIcon>
                  {this.state.isMinimized ? null : <ListItemText primary="Patients" />}
                </ListItem>
              </Link>

              <Link to={`/appointments${doctorId}`}>
                <ListItem selected={selectedTab === 'appointments'} className="listItem" key={3} button >
                  <ListItemIcon> <EventNoteOutlined /></ListItemIcon>
                  {this.state.isMinimized ? null : <ListItemText primary="Appointments" />}
                </ListItem >
              </Link>

              <Link to={`/billings${doctorId}`}>
                <ListItem selected={selectedTab === 'billings'} className="listItem" key={4} button >
                  <ListItemIcon> <AccountBalanceOutlined /></ListItemIcon>
                  {this.state.isMinimized ? null : <ListItemText primary="Billings" />}
                </ListItem>
              </Link>

              {this.props.role == 'Clinic' ? <Link to={`/doctors`}>
                <ListItem selected={selectedTab === 'doctors'} className="listItem" key={5} button >
                  <ListItemIcon> <AssignmentIndOutlined /></ListItemIcon>
                  {this.state.isMinimized ? null : <ListItemText primary="Doctors" />}
                </ListItem>
              </Link> : null}

              {this.props.role == 'Clinic' ? <Link to={`/secretaries`}>
                <ListItem selected={selectedTab === 'secretaries'} className="listItem" key={6} button >
                  <ListItemIcon> <PermContactCalendarOutlined /></ListItemIcon>
                  {this.state.isMinimized ? null : <ListItemText primary="Secretaries" />}
                </ListItem>
              </Link> : null}

            </List>
          </div>
        </Drawer>

        <div style={contentStyle} className="pageContent">
          <Route path='/Dashboard' component={Home} />
          <Route path='/Patients' component={Patients} />
          <Route path='/Appointments' component={Appointment} />
          <Route path='/Secretaries' component={Secretaries} />
          <Route path='/Doctors' component={Doctors} />
          <Route path='/Billings' component={Billings} />
        </div>



      </div>
    )
  }
}


const mapStateToProps = (state) => {

  return {
    token: state.auth.token,
    role: state.auth.role,
    clinicId: state.auth.clinicId,
    doctorId: state.auth.doctorId
  }
}

const mapDispatchToProps = (dispatch) => {

  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)