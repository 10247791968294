import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import EditOutlined from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import { addService, updateService, deleteService } from '../../store/actions/serviceAction';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

const useStyles = makeStyles({
    root: {
        'margin-top': '20%'
    },
    field: {
        'margin-top': '30px',
        margin: '5px',
        width: '90%'
    },
    addButton: {
        'margin-top': '60px',
        margin: '5px',
        width: '90%'
    },
    cancelButton: {
        'margin-top': '30px',
        margin: '5px',
        width: '90%'
    }
});

export default function ServiceMenu(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [amount, setAmount] = React.useState(props.data?.amount ?? 0);
    const [amountUpdated, setAmountUpdated] = React.useState(false);
    const [date, setDate] = React.useState(props.data?.date ?? new Date());
    const [dateUpdated, setDateUpdated] = React.useState(false);
    const selectToken = state => state.auth.token;
    const tokenSelector = createSelector(
        selectToken,
        token => token
    );
    const token = useSelector(tokenSelector);

    const updateAmount = (value) => {
        setAmount(value);
        setAmountUpdated(true);
    }
    const updateDate = (value) => {
        setDateUpdated(true);
        setDate(value);
    }

    const handleAddService = () => {
        dispatch(addService({
            token: token, patientId: props.patientId, doctorId: props.doctorId,
            clinicId: props.clinicId, body:
            {
                "amount": amount,
                "date": date
            }
        }));
        setAmount(0);
        setDate(new Date());
    };

    const handleUpdateService = () => {
        const dateToUpdate = props.data?.update == true && !dateUpdated  ? props.data.date:  date;
        const amountToUpdate = props.data?.update == true && !amountUpdated? props.data.amount : amount;
        dispatch(updateService({
            id: props.data.id, token: token, patientId: props.patientId,
            doctorId: props.doctorId, clinicId: props.clinicId, body:
            {
                "amount": amountToUpdate,
                "date": dateToUpdate
            }
        }));
        props.toggleRightMenu(false);
        setAmount(0);
        setDateUpdated(false);
        setAmountUpdated(false);
        setDate(new Date());
    };

    const handleDeleteService = () => {
        dispatch(deleteService({
            id: props.data.id, token: token, patientId: props.patientId,
            doctorId: props.doctorId, clinicId: props.clinicId
        }));
        setAmount(0);
        setDate(new Date());
        setDateUpdated(false);
        setAmountUpdated(false);
        handleCancel();
    };

    const handleCancel = (event) => {
        props.toggleRightMenu(false);
        setAmount(0);
        setDate(new Date());
        setDateUpdated(false);
        setAmountUpdated(false);
    };

    return (
        <div className={classes.root}>
            <TextField value={props.data?.update == true && !amountUpdated ?
                props.data.amount : amount}
                onChange={(e) => updateAmount(e.target.value)} 
                className={classes.field}
                id="Amount"
                fullWidth
                label='Amount'
            />
            <MuiPickersUtilsProvider required utils={DateFnsUtils}>
                <KeyboardDatePicker value={props.data?.update == true && !dateUpdated ?
                    props.data.date : date} onChange={(e) => updateDate(e)}
                    margin="normal"
                    disableToolbar
                    className={classes.field}
                    variant="inline"                    
                    id="date"
                    label="Service Date"
                    format="dd-MM-yyyy"
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
            <div>
                {props.data?.add == true ? <Button variant="outlined"
                    onClick={() => { handleAddService() }} className={classes.addButton}
                    color="primary" startIcon={<AddBoxOutlined></AddBoxOutlined>}
                >Add Service</Button> : null}
            </div>

            <div>
                {props.data?.update == true ? <Button variant="outlined"
                    onClick={() => { handleUpdateService() }} className={classes.addButton}
                    color="primary" startIcon={<EditOutlined></EditOutlined>}
                >Update Service</Button> : null}
            </div>

            <div>
                {props.data?.update == true ? <Button variant="outlined"
                    onClick={() => { handleDeleteService() }} className={classes.cancelButton}
                    color="primary" startIcon={<DeleteForeverOutlined></DeleteForeverOutlined>}
                >Delete Service</Button> : null}
            </div>

            <Button variant="outlined" onClick={() => { handleCancel() }} className={classes.cancelButton}
                color="primary" startIcon={<CancelOutlined></CancelOutlined>}
            >Cancel</Button>
        </div>
    );
}