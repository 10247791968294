const initState = {
  payments: null,
  totalPaymentSum: null,
  error: null,
  isLoading: null
}

const paymentReducer = (state = initState, action) => {
  switch (action.type) {
    case 'GET_PAYMENTS':
      return {
        ...state,
        payments: action.response.data?.data?.allPayments,
        totalPaymentSum: action.response.data?.data?.totalSum,
        isLoading: false
      }
    case "PAYMENTS_ISLOADING":
      return {
        ...state, isLoading: true
      }
    case "PAYMENTS_ISLOADING_STOP":
      return {
        ...state, isLoading: false
      }
    default:
      return state
  }
};

export default paymentReducer;