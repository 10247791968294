import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import EditOutlined from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import { addExamination, updateExamination, deleteExamination } from '../../store/actions/examinationAction';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

const useStyles = makeStyles({
    root: {
        'margin-top': '20%'
    },
    field: {
        'margin-top': '30px',
        margin: '5px',
        width: '90%'
    },
    addButton: {
        'margin-top': '60px',
        margin: '5px',
        width: '90%'
    },
    cancelButton: {
        'margin-top': '30px',
        margin: '5px',
        width: '90%'
    }
});

export default function ServiceMenu(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [examination, setExamination] = React.useState(props.data?.examination ?? "");
    const [examinationUpdated, setExaminationUpdated] = React.useState(false);
    const [date, setDate] = React.useState(props.data?.date ?? new Date());
    const [dateUpdated, setDateUpdated] = React.useState(false);
    const selectToken = state => state.auth.token;
    const tokenSelector = createSelector(
        selectToken,
        token => token
    );
    const token = useSelector(tokenSelector);

    const s_updateExamination = (value) => {
        setExamination(value);
        setExaminationUpdated(true);
    }
    const updateDate = (value) => {
        setDateUpdated(true);
        setDate(value);
    }

    const handleaddExamination = () => {
        dispatch(addExamination({
            token: token, patientId: props.patientId, doctorId: props.doctorId,
            clinicId: props.clinicId, body:
            {
                "examination": examination,
                "date": date
            }
        }));
        setExamination("");
        setDate(new Date());
    };

    const handles_updateExamination = () => {
        const dateToUpdate = props.data?.update == true && !dateUpdated  ? props.data.date:  date;
        const examinationToUpdate = props.data?.update == true && !examinationUpdated? props.data.examination : examination;
        dispatch(updateExamination({
            id: props.data.id, token: token, patientId: props.patientId,
            doctorId: props.doctorId, clinicId: props.clinicId, body:
            {
                "examination": examinationToUpdate,
                "date": dateToUpdate
            }
        }));
        props.toggleRightMenu(false);
        setExamination("");
        setDateUpdated(false);
        setExaminationUpdated(false);
        setDate(new Date());
    };

    const handledeleteExamination = () => {
        dispatch(deleteExamination({
            id: props.data.id, token: token, patientId: props.patientId,
            doctorId: props.doctorId, clinicId: props.clinicId
        }));
        setExamination("");
        setDate(new Date());
        setDateUpdated(false);
        setExaminationUpdated(false);
        handleCancel();
    };

    const handleCancel = () => {
        props.toggleRightMenu(false);
        setExamination("");
        setDate(new Date());
        setDateUpdated(false);
        setExaminationUpdated(false);
    };

    return (
        <div className={classes.root}>
            <TextField multiline value={props.data?.update == true && !examinationUpdated ?
                props.data.examination : examination}
                onChange={(e) => s_updateExamination(e.target.value)} className={classes.field}
                id="examination"
                label='Prescription'
            />
            <MuiPickersUtilsProvider required utils={DateFnsUtils}>
                <KeyboardDatePicker value={props.data?.update == true && !dateUpdated ?
                    props.data.date : date} onChange={(e) => updateDate(e)} 
                    margin="normal"
                    disableToolbar
                    className={classes.field} 
                    variant="inline"
                    id="date"
                    label="Service Date"
                    format="dd-MM-yyyy"
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
            <div>
                {props.data?.add == true ? <Button variant="outlined"
                    onClick={() => { handleaddExamination() }} className={classes.addButton}
                    color="primary" startIcon={<AddBoxOutlined></AddBoxOutlined>}
                >Add Prescription</Button> : null}
            </div>

            <div>
                {props.data?.update == true ? <Button variant="outlined"
                    onClick={() => { handles_updateExamination() }} className={classes.addButton}
                    color="primary" startIcon={<EditOutlined></EditOutlined>}
                >Update Prescription</Button> : null}
            </div>

            <div>
                {props.data?.update == true ? <Button variant="outlined"
                    onClick={() => { handledeleteExamination() }} className={classes.cancelButton}
                    color="primary" startIcon={<DeleteForeverOutlined></DeleteForeverOutlined>}
                >Delete Prescription</Button> : null}
            </div>

            <Button variant="outlined" onClick={() => { handleCancel() }} className={classes.cancelButton}
                color="primary" startIcon={<CancelOutlined></CancelOutlined>}
            >Cancel</Button>
        </div>
    );
}