import axios from 'axios';
import { getUrl, checkError } from './actionsUtil';

export const getSecretaries = (token) => {
    return (dispatch) => {
        const baseUrl = getUrl();
        const path = 'api/secretary';
        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        api.get(path)
            .then(secretaries => {
                dispatch({ type: 'SECRETARIES_RETRIVED', secretaries });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'SECRETARY_ERROR', message });
            });
    }
}

export const getPermissions = () => {
    return (dispatch) => {
        const baseUrl = getUrl();
        const path = 'api/permissions';
        const api = axios.create({
            baseURL: baseUrl});

        api.get(path)
            .then(permissions => {
                dispatch({ type: 'PERMISSIONS_RETRIVED', permissions });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'SECRETARY_ERROR', message });
            });
    }
}

export const updatePermissions = (token, secretaryId, permissions) => {
  
    return (dispatch) => {
        dispatch({ type: 'SECRETARY_LOADING_PERMISSION' });
        const baseUrl = getUrl();
        const path = 'api/secretary/permissions';
        const getPath = 'api/secretary';
        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${token}`
            }
        });
      
        api.put(path, {permissions: permissions}, {
            params: {
              secretaryId: parseInt(secretaryId)
            }
          })
            .then(response => {
                dispatch({ type: 'SECRETARY_PERMISSION_RETRIVED' });
                api.get(getPath)
                    .then(secretaries => {
                        dispatch({ type: 'SECRETARIES_RETRIVED', secretaries });
                    })
                    .catch(error => {
                        var message = checkError(error);
                        dispatch({ type: 'SECRETARY_ERROR', message });
                    });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'SECRETARY_ERROR', message });
            });
}
}

export const addSecretary = (token, body) => {

    return (dispatch) => {
        const baseUrl = getUrl();
        const path = 'api/secretary';
        const getPath = 'api/secretary';
        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        api.post(path, body)
            .then(response => {
                dispatch({ type: 'SECRETARY_ADDED', response });

                api.get(getPath)
                    .then(secretaries => {
                        dispatch({ type: 'SECRETARIES_RETRIVED', secretaries });
                    })
                    .catch(error => {
                        var message = checkError(error);
                        dispatch({ type: 'SECRETARY_ERROR', message });
                    });

            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'SECRETARY_ERROR', message });
            });
    }
}

export const attchDoctor = (token, doctorId, secretaryId) => {

    return (dispatch) => {
        dispatch({ type: 'SECRETARY_LOADING_ATTACH'});
        const baseUrl = getUrl();
        const path = 'api/secretary/doctor/attach';
        const getPath = 'api/secretary';
        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${token}`
            }
        });
      
        api.put(path, null, {
            params: {
              secretaryId: parseInt(secretaryId),
              doctorId: parseInt(doctorId)
            }
          
          })
            .then(secretary => {
                dispatch({ type: 'SECRETARIES_ATTACHED' });
                api.get(getPath)
                    .then(secretaries => {
                        dispatch({ type: 'SECRETARIES_RETRIVED', secretaries });
                    })
                    .catch(error => {
                        var message = checkError(error);
                        dispatch({ type: 'SECRETARY_ERROR', message });
                    });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'SECRETARY_ERROR', message });
            });
    }
}

export const deattachDoctor = (token, doctorId, secretaryId) => {

    return (dispatch) => {
        dispatch({ type: 'SECRETARY_LOADING_ATTACH'});
        const baseUrl = getUrl();
        const path = 'api/secretary/doctor/detach';
        const getPath = 'api/secretary';
        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${token}`
            }
        });
      
        api.put(path, null, {
            params: {
              secretaryId: parseInt(secretaryId),
              doctorId: parseInt(doctorId)
            }
          })
            .then(secretary => {
                dispatch({ type: 'SECRETARIES_DEATTACHED' });
                api.get(getPath)
                    .then(secretaries => {
                        dispatch({ type: 'SECRETARIES_RETRIVED', secretaries });
                    })
                    .catch(error => {
                        var message = checkError(error);
                        dispatch({ type: 'SECRETARY_ERROR', message });
                    });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'SECRETARY_ERROR', message });
            });
    }
}

export const deleteSecretary = (token, id) => {
    return (dispatch) => {
        const baseUrl = getUrl();
        const path = 'api/secretary';
        const deletePath = `api/secretary/${id}`;

        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        api.delete(deletePath)
            .then(response => {
                dispatch({ type: 'SECRETARY_DELETED', response });

                api.get(path)
                    .then(secretaries => {
                        dispatch({ type: 'SECRETARIES_RETRIVED', secretaries });
                    }).catch(error => {
                        var message = checkError(error);
                        dispatch({ type: 'SECRETARY_ERROR', message });
                    });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'SECRETARY_ERROR', message });
            });
    }
}
export const clearError = () => {
    return (dispatch) => {
        dispatch({ type: 'CLEAR_SECRETARY_ERROR' });
    }
}


