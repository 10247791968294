const initState = {
    appointments: null,
    error: null,
    isLoading: null,
    appointmentsReport: null
}

const appointmentReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_APPOINTMENTS":
            return {
                ...state, appointments: action.response.data.data,
                isLoading: false
            }
        case "GET_APPOINTMENTS_REPORT":
            return {
                ...state, appointmentsReport: action.response.data.data,
                isLoading: false
            }
        case "APPOINTMENTS_ISLOADING":
            return {
                ...state, isLoading: true
            }
        case "APPOINTMENTS_ERROR":
            return {
                ...state,
                error: action.message.message,
                isLoading: false
            }
        case "APPOINTMENTS_ISLOADING_STOP":
            return {
                ...state, isLoading: false
            }
        case 'APPOINTMENTS_CLEAR_ERROR':
            return {
                ...state,
                error: null
            }
        default:
            return { ...state }
    }
};

export default appointmentReducer;