import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import AddBox from '@material-ui/icons/AddBox';
import { useSelector, useDispatch } from 'react-redux';
import { getPayments } from '../../store/actions/paymentAction';
import { createSelector } from 'reselect';
import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';

const columns = [
  { id: 'amount', label: 'Amount', minWidth: 70 },
  { id: 'date', label: 'Date', minWidth: 100 },
  { id: 'loggedBy', label: 'Logged by', minWidth: 100 },
  { id: 'createdAt', label: 'Logged At', minWidth: 100 },
  { id: 'updatedBy', label: 'Updated by', minWidth: 100 },
  { id: 'updatedAt', label: 'Updated at', minWidth: 100 }
];

const useStyles = makeStyles({
  root: {
    width: '100%',
    'max-width': '62vw'
  },
  container: {
    maxHeight: 600,
  },
  scroll: {
    maxHeight: '40vh !important',
    overflow: 'auto'
  },
  addButton: {
    float: 'left',
    height: '40px',
    wedth: '40px',
    padding: '5px'
  },
  addIcon: {
    'color': 'rgb(4, 67, 117) !important',
    height: '20px'
  },
  chip:{
    'background-color': '#97adb5',
    'float': 'right',
    margin: '8px'
  }
});

export default function Payment(props) {
  const classes = useStyles();
  const selectToken = state => state.auth.token;
  const selectPayments = state => state.payments.payments;
  const selectTotalSum = state => state.payments.totalPaymentSum;
  const selectPaymentsIsLoading = state => state.payments.isLoading;

  const tokenSelector = createSelector(
    selectToken,
    token => token
  );

  const paymentsSelector = createSelector(
    selectPayments,
    payments => payments
  );

  const paymentsTotalSum = createSelector(
    selectTotalSum,
    totalPaymentSum => totalPaymentSum
  );
  const paymentsIsLoadingSelector = createSelector(
    selectPaymentsIsLoading,
    isLoading => isLoading
);
  const payments = useSelector(paymentsSelector);
  const totalSum = useSelector(paymentsTotalSum);
  const token = useSelector(tokenSelector);
  const isLoading = useSelector(paymentsIsLoadingSelector);
  const dispatch = useDispatch();

  const handleAdd = (event) => {
    props.toggleRightMenu(true, {
      "add": true
    })
  };

  const handleUpdate = (id, date, amount) => {
    props.toggleRightMenu(true, {
      "id": id,
      "update": true,
      "amount": amount,
      "date": new Date(date)
    })
  };

  useEffect(() => {
    dispatch(getPayments({
      token: token, patientId: props.patientId,
      doctorId: props.doctorId, clinicId: props.clinicId
    }))
  }, [props.clinicId, props.doctorId, props.patientId, token, dispatch]);

  return (
    <div >
          {isLoading == true? <LinearProgress />:<div>
      <IconButton aria-label="add" className={classes.addButton} onClick={() => { handleAdd() }}>
        <AddBox className={classes.addIcon} />
      </IconButton>
    
      <Paper className={classes.root}>
      {totalSum ? <Chip className={classes.chip}
        label= { "Total = " + totalSum}   
      /> : null}
        <TableContainer className={classes.container}>
      
          <div className={classes.scroll}> <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {payments?.map((row) => {
                return (
                  <TableRow onClick={() => { handleUpdate(row.id, row.date, row.amount) }}
                    hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns?.map((column) => {
                      var value = row[column.id];
                      if (column.id === 'createdAt' || column.id === 'updatedAt' || column.id === 'date') {
                        value = value?.split('T')[0];
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table></div>
        </TableContainer>
      </Paper>
      </div>}
    </div>
  );
}