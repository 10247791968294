import axios from 'axios';
import { getUrl, checkError } from './actionsUtil';

export const getTreatments = (info) => {
    return (dispatch) => {
        const teethNumber = "";
        dispatch({ type: 'TREATMENT_TEETH_ID', teethNumber });
        dispatch({ type: 'TREATMENT_ISLOADING' });
        const baseUrl = getUrl();
        const path = 'api/treatment';
        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${info.token}`
            },
        });

        api.get(path, {
            params: {
                patientId: parseInt(info.patientId),
                doctorId: parseInt(info.doctorId),
                clinicId: parseInt(info.clinicId)
            }
        })
            .then(response => {
                dispatch({ type: 'GET_TREATMENTS', response });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'PATIENT_ERROR', message });
                dispatch({ type: 'TREATMENT_ISLOADING_STOP' });
            });
    }
}

export const addTreatment = (info) => {
    return (dispatch) => {
        dispatch({ type: 'TREATMENT_ISLOADING' });
        const baseUrl = getUrl();
        const path = 'api/treatment';

        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${info.token}`
            }
        });

        const userTimezoneOffset = info.body.date.getTimezoneOffset() * 60000;
        const dateWithoutTimeZone = new Date(info.body.date.getTime() - userTimezoneOffset);

        var treatment = {
            treatment: info.body.treatment,
            date: dateWithoutTimeZone,
            clinicId: info.clinicId,
            doctorId: info.doctorId,
            patientId: info.patientId,
        };

        api.post(path, treatment)
            .then(response => {
                dispatch({ type: 'ADD_TREATMENT', response });

                api.get(path, {
                    params: {
                        patientId: parseInt(info.patientId),
                        doctorId: parseInt(info.doctorId),
                        clinicId: parseInt(info.clinicId)
                    }
                })
                    .then(response => {
                        dispatch({ type: 'GET_TREATMENTS', response });
                    }).catch(error => {
                        var message = checkError(error);
                        dispatch({ type: 'PATIENT_ERROR', message });
                        dispatch({ type: 'TREATMENT_ISLOADING_STOP' });
                    });
            }).catch(error => {
                var message = checkError(error);
                dispatch({ type: 'PATIENT_ERROR', message });
                dispatch({ type: 'TREATMENT_ISLOADING_STOP' });
            });
    }
}

export const updateTreatment = (info) => {
    return (dispatch) => {
        const teethNumber = "";
        dispatch({ type: 'TREATMENT_TEETH_ID', teethNumber });
        dispatch({ type: 'TREATMENT_ISLOADING' });
        const baseUrl = getUrl();
        const path = 'api/treatment';
        const updatePath = `api/treatment/${info.id}`;

        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${info.token}`
            }
        });

        const userTimezoneOffset = info.body.date.getTimezoneOffset() * 60000;
        const dateWithoutTimeZone = new Date(info.body.date.getTime() - userTimezoneOffset);

        var treatment = {
            treatment: info.body.treatment,
            date: dateWithoutTimeZone,
            clinicId: info.clinicId,
            doctorId: info.doctorId,
            patientId: info.patientId,
        };

        api.put(updatePath, treatment)
            .then(response => {
                dispatch({ type: 'UPDATE_TREATMENT', response });

                api.get(path, {
                    params: {
                        patientId: parseInt(info.patientId),
                        doctorId: parseInt(info.doctorId),
                        clinicId: parseInt(info.clinicId)
                    }
                })
                    .then(response => {
                        dispatch({ type: 'GET_TREATMENTS', response });
                    }).catch(error => {
                        var message = checkError(error);
                        dispatch({ type: 'PATIENT_ERROR', message });
                        dispatch({ type: 'TREATMENT_ISLOADING_STOP' });
                    });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'PATIENT_ERROR', message });
                dispatch({ type: 'TREATMENT_ISLOADING_STOP' });
            });
    }
}

export const deleteTreatment = (info) => {
    return (dispatch) => {
        const teethNumber = "";
        dispatch({ type: 'TREATMENT_TEETH_ID', teethNumber });
        dispatch({ type: 'TREATMENT_ISLOADING' });
        const baseUrl = getUrl();
        const path = 'api/treatment';
        const deletePath = `api/treatment/${info.id}`;

        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${info.token}`
            }
        });

        api.delete(deletePath, {
            params: {
                patientId: parseInt(info.patientId),
                doctorId: parseInt(info.doctorId),
                clinicId: parseInt(info.clinicId)
            }
        })
            .then(response => {
                dispatch({ type: 'DELETE_TREATMENT', response });

                api.get(path, {
                    params: {
                        patientId: parseInt(info.patientId),
                        doctorId: parseInt(info.doctorId),
                        clinicId: parseInt(info.clinicId)
                    }
                })
                    .then(response => {
                        dispatch({ type: 'GET_TREATMENTS', response });
                    }).catch(error => {
                        var message = checkError(error);
                        dispatch({ type: 'PATIENT_ERROR', message });
                        dispatch({ type: 'TREATMENT_ISLOADING_STOP' });
                    });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'PATIENT_ERROR', message });
                dispatch({ type: 'TREATMENT_ISLOADING_STOP' });
            });
    }
}

export const updateTeethNumber = (teethNumber) => {
    return (dispatch) => {
        dispatch({ type: 'TREATMENT_TEETH_ID', teethNumber });
    }      
}