import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteSweepOutlined from '@material-ui/icons/DeleteSweepOutlined';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { getDoctors ,attachDoctor,detachDoctor, clearError} from '../../store/actions/doctorAction';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import AddOutlined from '@material-ui/icons/AddOutlined';

const useStyles = makeStyles({
  table: {
    minWidth: '90vw',
  },
  container:{
    margin: '40px',
    display: 'flex',
    margin: '40px',
   'margin-left': '20%',
    'display': 'flex',
    'margin-right': '20%'

  },
  addButton: {
    color:'#000000 !important',
    'min-width': '180px',
    'margin-left': '30px',
    height: '40px',
    'margin-top': '19px'
  },
  detachIcon:{
    color: '#d80939fa  !important'
  },
  closeIcon:{
    color: 'white'
  }
});

export default function Doctors() {
  const classes = useStyles();

  const [email, setEmail] = React.useState(null);

  const selectToken = state => state.auth.token;
  const selectDoctors = state => state.doctors.doctors;
  const selectError = state => state.doctors.error;
  
  const tokenSelector = createSelector(
    selectToken,
    token => token
  );

  const doctorSelector = createSelector(
    selectDoctors,
    doctors => doctors
  );

  const errorSelector = createSelector(
    selectError,
    doctors => doctors
  );

  const token = useSelector(tokenSelector);
  const doctors = useSelector(doctorSelector);
  const error = useSelector(errorSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDoctors(token))
  }, []);

  const handleAttachDoctor = () => {
    dispatch(attachDoctor(token, email));
    setEmail(null);
  };

  const handleDetachDoctor = (doctorEmail) => {
    dispatch(detachDoctor(token, doctorEmail));
  };

  return (
      <div>
          <div className={classes.container}>
          <TextField
    id="Add new doctor"
    label="Doctor Email"
    style={{ margin: 8 }}
    placeholder="Ex. yazed@gmail.com"
    fullWidth
    margin="normal"
    InputLabelProps={{
      shrink: true,
    }}
    value={email}
    onChange={(e) => setEmail(e.target.value)}
   
  />

        <Button onClick={() =>{handleAttachDoctor()}}
        variant="outlined"
        fontSize="small"
        disabled={email === null || email === ""}
        startIcon={<AddOutlined>send</AddOutlined>}
       className={classes.addButton}
      >
        Attach doctor
      </Button>
          </div> 
  
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">MobileNumber</TableCell>
            <TableCell  align="right">Detach</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {doctors?.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.email}</TableCell>
              <TableCell align="right">{row.mobileNumber}</TableCell>
              <TableCell align="right">  
              <IconButton aria-label="delete" onClick={()=>handleDetachDoctor(row.email)}>
          <DeleteSweepOutlined  className={classes.detachIcon}  fontSize="small" />
        </IconButton >
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
 
    <div>
          <Snackbar className="snackbar"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
             open={error != null}
            autoHideDuration={5000}
             onClose={() => {dispatch(clearError())}}
             message={error}
            action={
              <React.Fragment>
                <IconButton  size="medium" aria-label="close" onClick={() => {dispatch(clearError())}} >
                  <CloseIcon  className={classes.closeIcon} />
                </IconButton>
              </React.Fragment>
            }
          />
        </div>

      </div>
  
  );
}