import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line

import { makeStyles } from '@material-ui/core/styles';
import { SectionHeading, Subheading as SubheadingBase } from "../../components/misc/Headings.js";
import { SectionDescription } from "../../components/misc/Typography.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import QualityIconImage from "../../images/quality.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";


const useStyles = makeStyles({
  Icon: {'border': '1px solid #e2e8f0 !important','color': 'rgb(124, 139, 161)!important', 'font-family': 'Inter, system-ui','display': 'flex'},
  featureTitle:{ 'font-family': 'Inter, system-ui!important',
  'color': 'rgb(36, 62, 99)'},
  featureDescription:{ 'font-family': 'Inter, system-ui!important',
 'color': 'rgb(124, 139, 161)'}
}
);


const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Amazing Features", subheading = "Features", description = "Specialized Software Development Company." }) => {
 

  const classes = useStyles();
  const defaultCards = [
    {
      imageSrc: SupportIconImage,
      title: "CONTACT INFO",
      description: " +972 592 120 606         info@inabtech.com"
    },
    { imageSrc: ShieldIconImage, title: "OUR STUDIO" ,description: " Sa'd Ibn Abi Waqqas St, Ramallah, Palestine." },
      ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading >{subheading}</Subheading>}
        <Heading className="Title">{heading}</Heading>
        {description && <Description className="Description">{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span  className="imageContainer" className={`imageContainer ${classes.Icon}`}>
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className={`title ${classes.featureTitle}`}>{card.title || "Fully Secure"}</span>
                <p className={`description ${classes.featureDescription}`}>
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
