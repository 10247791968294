const initState = {
    secretaries: null,
    doctors: null,
    permissions: null,
    error: null,
    attachLoading: null,
    permissionLoading: null
}

const secretaryReducer = (state = initState, action) => {
    switch (action.type) {
        case "SECRETARIES_RETRIVED":
            return { ...state, secretaries: action.secretaries.data.data, }
        case "SECRETARIES_DEATTACHED":
            return { ...state, attachLoading: false }
        case "SECRETARIES_ATTACHED":
            return { ...state, attachLoading: false }
        case "SECRETARY_LOADING_ATTACH":
            return {
                ...state,
                attachLoading: true
            }
        case "SECRETARY_LOADING_PERMISSION":
            return {
                ...state,
                permissionLoading: true
            }
        case "SECRETARY_PERMISSION_RETRIVED":
            return { ...state, permissionLoading: false }
        case "PERMISSIONS_RETRIVED":
            return { ...state, permissions: action.permissions.data.data }
        case "SECRETARY_ERROR":
            return { ...state, error: action.message.message }
        case "CLEAR_SECRETARY_ERROR":
            return { ...state, error: null }
        default:
            return state
    }
};

export default secretaryReducer;