import { combineReducers } from 'redux';
import authReducer from './authReducer';
import patientsReducer from './patientsReducer';
import paymentReducer from './paymentReducer';
import serviceReducer from './serviceReducer';
import appointmentReducer from './appointmentReducer';
import diagnoseReducer from './diagnoseReducer';
import treatmentReducer from './treatmentReducer';
import examinationReducer from './examinationReducer';
import doctorReducer from './doctorReducer';
import secretaryReducer from './secretaryReducer';
import billingsReducer from './billingsReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  patients: patientsReducer,
  payments: paymentReducer,
  services: serviceReducer,
  appointments: appointmentReducer,
  diagnoses: diagnoseReducer,
  treatments: treatmentReducer,
  examinations: examinationReducer,
  doctors: doctorReducer,
  secretaries: secretaryReducer,
  billings:billingsReducer
});

export default rootReducer;