import React from 'react';
import './App.css';
import SignUp from './pages/auth/SignUp';
import SignIn from './pages/auth/SignIn';
import Dashboard from './pages/dashboard/Dashboard';
import Profile from './pages/profile/Profile';
import DoctorClinics from './pages/dashboard/DoctorClinics';
import { Switch, Route } from 'react-router-dom';
import "normalize.css";
import 'typeface-roboto';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#4788a6',
      main: '#075b77',
      dark: '#00324b'
    },
    secondary: {
   light: '#ffea4d',
      main: '#f7b800',
      dark: '#bf8900'
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={outerTheme}>
    <div className="App">
      <Switch>
        <Route exact path='/signup' component={SignUp} />
        <Route exact path='/login' component={SignIn} />
        <Route exact path='/SelectClinic' component={DoctorClinics} />
        <Route exact path='/Profile' component={Profile} />
        <Route path='/' component={Dashboard} />
    
      </Switch>
    </div>
    </ThemeProvider>
  )
}

export default App;
