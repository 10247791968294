const initState = {
    diagnoses: null,
    error: null,
    isLoading: null,
    imageIsUploading: null,
    imageUrl: null
}

const diagnoseReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_DIAGNOSES":
            return {
                ...state, diagnoses: action.response.data.data,
                isLoading: false
            }
        case "ADD_DIAGNOSE":
            return {
                ...state,
                isLoading: false,
                imageUrl: null
            }
        case "IMAGE_UPLOADED":
            return {
                ...state,
                imageUrl: action.response.data.data,
                imageIsUploading: false
            }
        case "DIAGNOSES_ISLOADING":
            return {
                ...state, isLoading: true
            }
        case "DIAGNOSES_IMAGE_UPLOADING":
            return {
                ...state, imageIsUploading: true
            }
        case "DIAGNOSES_ISLOADING_STOP":
            return {
                ...state, isLoading: false
            }
        case "DIAGNOSES_IMAGE_UPLOADED":
            return {
                ...state, imageIsUploading: false
            }
        case "DIAGNOSES_IMAGE_CLEAR":
            return {
                ...state,
                 imageUrl: null,
                  imageIsUploading: false
            }
        default:
            return { ...state }
    }
};

export default diagnoseReducer;