const initState = {
  patients: null,
  pageCount: null,
  error: null,
  payments: null,
  isLoading: null
}

const patientsReducer = (state = initState, action) => {
  switch (action.type) {
    case 'GET_PATIENTS':
      return {
        ...state,
        patients: action.response.data.data.patients,
        pageCount: action.response.data.data.pages,
        isLoading: false
      }
    case 'ADD_PATIENT':
      return {
        ...state
      }
    case 'UPDATE_PATIENT':
      return {
        ...state
      }
    case 'DELETE_PATIENT':
      return {
        ...state
      }
    case 'ATTACH_PATIENT':
      return {
        ...state
      }
    case 'DEATTACH_PATIENT':
      return {
        ...state
      }
    case 'PATIENT_ERROR':
      return {
        ...state,
        error: action.message.message,
        isLoading: false
      }
    case 'PATIENT_LOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'PATIENT_CLEAR_ERROR':
      return {
        ...state,
        error: null
      }
    default:
      return state
  }
};

export default patientsReducer;