import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import Hero from "../../components/hero/TwoColumnWithInput.js";
import Features from "../../components/features/ThreeColWithSideImage.js";
import Features2 from "../../components/features/ThreeCol.js";
import MainFeature from "../../components/features/TwoColWithButton.js";
import MainFeature2 from "../../components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "../../components/features/TwoColWithSteps.js";
import Pricing from "../../components/pricing/ThreePlans";
import Testimonial from "../../components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "../../components/faqs/SingleCol.js";
import GetStarted from "../../components/cta/GetStarted";
import Footer from "../../components/footers/FiveColumnWithBackground";
import heroScreenshotImageSrc from "../../images/dashboard.png";
import macHeroScreenshotImageSrc from "../../images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "../../images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";



export default function MainPage ()  {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-gray-500`;
  const HighlightedText = tw.span`text-gray-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading className="Heading">Features</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText className="StyledTitle">Service.</HighlightedText>
          </>
        }
      />
      <MainFeature
        subheading={<Subheading id="about"  className="Heading">Quality Work</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <FeatureWithSteps
        subheading={<Subheading className="Heading">STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText className="StyledTitle">Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 -translate-x-1/2`}
      />
      <MainFeature2
        subheading={<Subheading className="Heading">VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our <HighlightedText className="StyledTitle">Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description: "We assure you that our templates are designed and created by professional designers.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      />

      <Pricing 
        subheading={<Subheading id="pricing" className="Heading">Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText className="StyledTitle">Plans.</HighlightedText>
          </>
        }
        plans={[
            {
              name: "Free Trial",
              price: "Free",
              duration: "1 Week",
              features: ["Unlimited stored data", "Unlimited transactions", "Unlimitied uploaded Images"],
            },
          {
            name: "Monthly",
            price: "$29.99",
            duration: "1 Month",
            features: ["Unlimited stored data", "Unlimited transactions", "Unlimitied uploaded Images"],
          
          },
          {
            name: "Yearly",
            price: "$199.99",
            duration: "1 Year",
            features: ["plus 50$ per additional user", "Unlimited stored data", "Unlimited transactions", "Unlimitied uploaded Images"],
            featured: true
          }
        ]}
      />
      {/* <Testimonial
        subheading={<Subheading className="Heading">Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText className="StyledTitle">Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc."
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC"
          }
        ]}
      /> */}
      <FAQ
        subheading={<Subheading  className="Heading">FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText className="StyledTitle">Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "Can I add more than doctor for my clinic?",
            answer:
              "Yes, You can add as much doctor as you need, and each doctor has his specific patients, data and calculations."
          },
          {
            question: "Can I add secretaries for my clinic ?            ",
            answer:
              "Yes, you can add as much as you need, each secretary can have it's own roles and doctors, and you can specify their rules when you want."
          },
          {
            question: "What kind of payment methods do you accept ?",
            answer:
              "Currently, We support Palpay method from the bank account, also you can use cash payment."
          },
          {
            question: "Can my patients check their data?",
            answer:
            "Yes, We have a mobile application for your patients, they can check their data by their mobile phone number that you added for them."
            }        
        ]}
      />
         <Features2 
        subheading={<Subheading id="contact" className="Heading">Contact us</Subheading>}
        heading={
          <>
            Inab  <HighlightedText className="StyledTitle">Technologies.</HighlightedText>
          </>
        }
      />
    
      < Footer  />
    </AnimationRevealPage>
  );
}
