import axios from 'axios';
import { getUrl, checkError } from './actionsUtil';

export const getDoctors = (token) => {
    return (dispatch) => {
        const baseUrl = getUrl();
        const path = 'api/doctor';
        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        api.get(path)
            .then(doctors => {
                dispatch({ type: 'DOCTORS_RETRIVED', doctors });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'DOCTOR_ERROR', message });
            });
    }
}

export const getClinics = (token) => {
    return (dispatch) => {
        const baseUrl = getUrl();
        const path = 'api/doctor/clinics';
        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        api.get(path)
            .then(doctors => {
                dispatch({ type: 'CLINICS_RETRIVED', doctors });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'DOCTOR_ERROR', message });
            });
    }
}

export const attachDoctor = (token, email) => {
    return (dispatch) => {
        const baseUrl = getUrl();
        const path = 'api/clinic/attach';
        const getPath = 'api/doctor';
        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        api.put(path, {email})
            .then(response => {
                dispatch({ type: 'DOCTOR_ATTACHED', response });

                api.get(getPath)
                .then(doctors => {
                    dispatch({ type: 'DOCTORS_RETRIVED', doctors });
                })
                .catch(error => {
                    var message = checkError(error);
                    dispatch({ type: 'DOCTOR_ERROR', message });
                });

            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'DOCTOR_ERROR', message });
            });
    }
}

export const detachDoctor = (token, email) => {
    return (dispatch) => {
        const baseUrl = getUrl();
        const path = 'api/clinic/detach';
        const getPath = 'api/doctor';
        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        api.put(path, {email})
            .then(response => {
                dispatch({ type: 'DOCTOR_DETACHED', response });

                api.get(getPath)
                .then(doctors => {
                    dispatch({ type: 'DOCTORS_RETRIVED', doctors });
                })
                .catch(error => {
                    var message = checkError(error);
                    dispatch({ type: 'DOCTOR_ERROR', message });
                });

            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'DOCTOR_ERROR', message });
            });
    }
}

export const clearError = () => {
    return (dispatch) => {  
       dispatch({ type: 'CLEAR_DOCTOR_ERROR' });              
    }
}


