const initState = {
    examinations: null,
    error: null,
    isLoading: null
}

const examinationReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_EXAMINATIONS":
            return {
                ...state, examinations: action.response.data.data,
                isLoading: false
            }
        case "EXAMINATIONS_ISLOADING":
            return {
                ...state, isLoading: true
            }
        case "EXAMINATIONS_ISLOADING_STOP":
            return {
                ...state, isLoading: false
            }
        default:
            return { ...state }
    }
};

export default examinationReducer;