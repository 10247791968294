import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "../../components/misc/Headings.js";
import TeamIllustrationSrc from "../../images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "../../images/dot-pattern.svg";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  image:{'max-width': '100%!important', 'font-family': 'Inter, system-ui!important'},
  icon:{'width': '5rem!important',
   'height': '5rem!important',
   '--transform-translate-x': '-50%!important',
    'left': '50%!important',
    'position': 'absolute!important',
    'right': '0px!important',
    'bottom': '0px!important',
    'fill': 'currentcolor!important',
    'color': 'rgb(100, 21, 255)!important',
    'z-index': '-10!important', 
    'opacity': '0.15!important'},
    step:{
      'display': 'inline',   
      'font-family': 'Inter, system-ui',
      'min-height': '100vh',
      'color': 'rgb(36, 62, 99)',
      'overflow': 'hidden'},
      setpDesc:{
        'margin-top': '0.75rem',
        'max-width': '20rem',
        'line-height': '2',
        'font-size': '0.875rem',
        'color': 'rgb(113, 128, 150)',
        'font-family': 'Inter, system-ui',
        'font-weight': '500'
      },
      link:{'font-family': 'Inter, system-ui',
      'color': 'rgb(100, 21, 255)!important',
      'font-size': '0.875rem'
    },
      padding:{'padding':'0'}
 }
);

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);

const DecoratorBlob = styled(SvgDotPattern)(() => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-gray-500 `
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Steps = tw.ul`mt-12`;
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`;
const StepNumber = tw.div`font-semibold text-4xl leading-none text-gray-400`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`;
const StepHeading = tw.h6`leading-none text-xl font-semibold`;
const StepDescription = tw.p`mt-3 max-w-xs leading-loose text-sm text-gray-600 font-medium`;

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Designed & Developed by <span tw="text-gray-500">Professionals.</span>
    </>
  ),
  imageSrc = TeamIllustrationSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageDecoratorBlob = false,
  textOnLeft = true,
  steps = null,
  decoratorBlobCss = null,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const defaultSteps = [
    {
      heading: "Register",
      description: "Create an account by fill your information."
    },
    {
      heading: "Start",
      description: "You can start using the application directly."
    }
  ];

  if (!steps) steps = defaultSteps;

  const classes = useStyles();

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image className={classes.image} src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
          {imageDecoratorBlob && <DecoratorBlob  className={classes.icon} css={decoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent >
            <Subheading>{subheading}</Subheading>
            <Heading  className="Title">{heading}</Heading>
            <Steps className={classes.padding}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepNumber>{(index+1).toString().padStart(2,'0')}</StepNumber>
              
                  <StepText>
                    <StepHeading className={classes.step}>{step.heading}</StepHeading>
                    <StepDescription className={classes.setpDesc}>{step.description}</StepDescription>
                    {index ==0 ?  <a className={classes.link} href="/signup">Click here to register</a> : null}    
                   
                  </StepText>
                </Step>
              ))}
            </Steps>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
