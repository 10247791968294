import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createSelector } from 'reselect';
import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import Chip from '@material-ui/core/Chip';
import { useSelector } from 'react-redux';
import MainPage from '../Main/MainPage';
import NavBar from '../layout/Navbar'

const useStyles = makeStyles({
    root: {
        margin: '20px'
    },
    heading: {
        'margin-top': '70px',
        textAlign: 'left',
        marginLeft: '10px'
    },
    headingPassword: {
        'margin-top': '20px',
        textAlign: 'left',
        marginLeft: '10px'
    },
    chip: {
        marginLeft: '30px',        
        marginBottom: '16px',
        textAlign: 'left',
    },
    title: {
        fontSize: 14,
    },
    chipContent: {      
        minWidth: '120px',
        marginLeft: '10px',
        cursor: 'default'
    },
    textField: {
        textAlign: 'left',
        marginLeft: '30px',
        display: 'block !important'
    }
});


export default function Profile() {
    const classes = useStyles();
    
  const selectToken = state => state.auth.token;
    const tokenSelector = createSelector(
        selectToken,
        token => token
      );
    const token = useSelector(tokenSelector);


    const selectName = state => state.auth.name;  

  const nameSelector = createSelector(
    selectName,
    name => name
  );

  const name = useSelector(nameSelector);


  const selectemail = state => state.auth.email;  

  const emailSelector = createSelector(
    selectemail,
    email => email
  );

  const email = useSelector(emailSelector);


  const selectmobileNumber = state => state.auth.mobileNumber;  

  const mobileNumberSelector = createSelector(
    selectmobileNumber,
    mobileNumber => mobileNumber
  );

  const mobileNumber = useSelector(mobileNumberSelector);

    if (!token) {
      return <MainPage/>
    }
    return (

        <div>

            <NavBar disableToggle={true} />
            <div>
                <Typography className={classes.heading} variant="h6" >Update Information</Typography>
                <Card className={classes.root}>
                    <CardContent>
                    <div className={classes.chip}>
                            
                            Role: 
                            <Chip className={classes.chipContent}
            label="Clinic"
            clickable
            color="secondary"
          />
                            </div>
                        <div className={classes.textField}>
                            <TextField id="standard-basic" label="User Name" defaultValue={name}/>
                        </div>
                        <div className={classes.textField}>
                            <TextField id="standard-basic" label="Email address" defaultValue={email} />
                        </div>
                        <div className={classes.textField}>
                            <TextField id="standard-basic" label="Mobile Number" defaultValue={mobileNumber } />
                        </div>
                       

                    </CardContent>
                    <CardActions>
                        <Button size="small">Update Information</Button>
                    </CardActions>
                </Card>
                <Divider />
                <Typography className={classes.headingPassword} variant="h6" >Update Password</Typography>

                <Card className={classes.root}>
                    <CardContent>
                        <div className={classes.textField}>
                            <TextField
                                id="standard-password-input"
                                label="current password"
                                type="password"
                                autoComplete="current-password"
                            />
                        </div>
                        <div className={classes.textField}>
                            <TextField
                                id="standard-password-input"
                                label="new password"
                                type="password"
                                autoComplete="current-password"
                            />
                        </div>
                        <div className={classes.textField}>
                            <TextField
                                id="standard-password-input"
                                label="confirm password "
                                type="password"
                                autoComplete="current-password"
                            />
                        </div>


                    </CardContent>
                    <CardActions>
                        <Button size="small">Update Password</Button>
                    </CardActions>
                </Card>

            </div>

        </div>

    );
}