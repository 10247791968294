const initState = {
    treatments: null,
    error: null,
    isLoading: null,
    teethNumber: ''
}

const treatmentReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_TREATMENTS":
            return {
                ...state, treatments: action.response.data.data,
                isLoading: false
            }
        case "TREATMENT_ISLOADING":
            return {
                ...state, isLoading: true
            }

        case "TREATMENT_ISLOADING_STOP":
            return {
                ...state, isLoading: false
            }
            case "TREATMENT_TEETH_ID":
                return {
                    ...state, teethNumber: action.teethNumber, isLoading: false
                }
        default:
            return { ...state }
    }
};

export default treatmentReducer;