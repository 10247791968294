let user = JSON.parse(localStorage.getItem('user'));

const initState = user ? user : {};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'AUTH_SUCCESS':
      return {
        ...state,
        token: action.response.data.Token,
        role: action.response.data.Role,
        name: action.response.data.Name,
        clinicId: action.response.data.ClinicId,
        doctorId: action.response.data.DoctorId,
        email: action.response.data.Email,
        mobileNumber: action.response.data.MobileNumber,
        authError: null
      }
    case 'AUTH_ERROR':
      return {
        ...state,
        token: null,
        role: null,
        authError: action.message.message
      }
    case 'AUTH_CLEAR_ERROR':
      return {
        ...state,
        authError: null
      }
      case 'AUTH_LOGOUT':
        return {}
        case 'AUTH_CLINIC_SELECTED':
          return {...state, clinicId: action.id}
      default:
        return state
    }
    
};

export default authReducer;