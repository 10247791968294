import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import EditOutlined from '@material-ui/icons/EditOutlined';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import SaveIcon from '@material-ui/icons/Save';
import PlaylistAddOutlined from '@material-ui/icons/PlaylistAddOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { deletePatient, updatePatient, detachPatient } from '../../store/actions/patientsAction';
import AttachPatient from './AttachPatient';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class PatientCard extends Component {
    state = {
        editMode: false,
        attachDialogOpened: false,
        confirmDeletePatientDialogOpened: false
    }

    enableEditMode = () => {
        var isActive = this.state.editMode;
        this.setState({
            editMode: !isActive
        });
        if (!isActive) {
            this.fillUpdatedValues();
        }
    }

    handleUpdateChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleAttachClose = () => {
        this.setState({
            attachDialogOpened: false,
            attachDoctorId: null
        })
    };

    handleClickAttach = () => {
        this.setState({
            attachDialogOpened: true
        });
    };

    handleUpdateChangeGender = (e) => {
        var gender = e.target.value == 'male' ? true : false;
        this.setState({
            updatedGender: gender
        });
    }

    handleDeletePatient = () => {
        this.props.deletePatient(this.props.token, this.props.selectedPatient.id,
            this.props.clinicId, this.props.doctorId , this.props.page, this.props.limit );
        this.setState({
            editMode: false,
            confirmDeletePatientDialogOpened: false
        });
    }

    handleDetachPatient = () => {
        this.props.detachPatient(this.props.token, this.props.clinicId, this.props.doctorId,
            this.props.selectedPatient.id, this.props.page, this.props.limit)
    }

    handleUpdateChangeBirth = (e) => {
        this.setState({
            updatedDateOfBirth: e
        });
    }

    fillUpdatedValues = () => {
        this.setState({
            updatedName: this.props.selectedPatient?.name,
            updatedDateOfBirth: this.props.selectedPatient?.dateOfBirth,
            updatedGender: this.props.selectedPatient?.gender,
            updatedMobileNumber: this.props.selectedPatient?.mobileNumber,
            updatedEmail: this.props.selectedPatient?.email,
            updatedAddress: this.props.selectedPatient?.address,
        })
    }

    handleUpdatePatient = () => {
        var patientToUpdate = {
            name: this.state.updatedName,
            dateOfBirth: this.state.updatedDateOfBirth,
            gender: this.state.updatedGender,
            mobileNumber: this.state.updatedMobileNumber,
            email: this.state.updatedEmail,
            address: this.state.updatedAddress,
            doctorId: this.props.doctorId
        }
        this.props.updatePatient(this.props.token, this.props.selectedPatient.id, patientToUpdate,
            this.props.clinicId, this.props.doctorId , this.props.page, this.props.limit );
        this.props.updatePatientState(patientToUpdate);
        this.setState({
            editMode: false
        });
    }

    handleDeleteDialogState = (state) => {
        this.setState({
            confirmDeletePatientDialogOpened: state
        })
    }
    render() {
        const mystyle = {
            'border': '2px solid #295c74'
        };

        const costsSum = this.props.totalServices != undefined && this.props.totalServices != null? Number(this.props.totalServices) : 0 ;

        const paymentsSum = this.props.totalPayments != undefined && this.props.totalPayments != null? Number(this.props.totalPayments) : 0 ;

        const total =   paymentsSum - costsSum;

        return (
            <div>
                <Card style={this.state.editMode ? mystyle : null} variant="outlined">
                    <CardContent className="Card-parent">
                        <div className="cardContent">
                            <div className="cardContent-input">
                                <TextField className="Field"
                                    id="updatedName"
                                    label='Name'
                                    focused
                                    onChange={this.handleUpdateChange}
                                    inputProps={{ readOnly: !this.state.editMode }}
                                    value={this.state.editMode ? null : this.props.selectedPatient?.name}
                                />
                                <TextField className="Field"
                                    id="updatedMobileNumber"
                                    label="Mobile Number"
                                    focused
                                    onChange={this.handleUpdateChange}
                                    inputProps={{ readOnly: !this.state.editMode }}
                                    value={this.state.editMode ? null : this.props.selectedPatient?.mobileNumber}
                                />
                                <TextField className="Field"
                                    id="updatedAddress"
                                    label="Address"
                                    onChange={this.handleUpdateChange}
                                    focused
                                    inputProps={{ readOnly: !this.state.editMode }}
                                    value={this.state.editMode ? null : this.props.selectedPatient?.address}
                                />
                                <TextField className="Field"
                                    id="updatedEmail"
                                    label="Email"
                                    onChange={this.handleUpdateChange}
                                    focused
                                    inputProps={{ readOnly: !this.state.editMode }}
                                    value={this.state.editMode ? null : this.props.selectedPatient?.email}
                                />

                             
                            </div>
                            <div >
                                <MuiPickersUtilsProvider required utils={DateFnsUtils}>
                                    <KeyboardDatePicker className="picker"
                                        margin="normal"
                                        variant="inline"
                                        id="dateOfBirth"
                                        label="Date of birth"
                                        format="dd/MM/yyyy"
                                        readOnly={!this.state.editMode}
                                        inputProps={{ readOnly: !this.state.editMode }}
                                        value={this.state.editMode ? this.state.updatedDateOfBirth : this.props.selectedPatient?.dateOfBirth}
                                        onChange={this.handleUpdateChangeBirth}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                <FormControl className="genderGroup" component="fieldset">
                                    <FormLabel component="legend">Gender</FormLabel>
                                    <RadioGroup aria-label="gender" value={this.state.editMode ?
                                        (this.state.updatedGender == true ? 'male' : 'female')
                                        : (this.props.selectedPatient?.gender == true ? 'male' : 'female')} onChange={this.handleUpdateChangeGender} name="gender" >
                                        {this.props.selectedPatient?.gender == true || this.state.editMode ? <FormControlLabel value="male" control={<Radio />} label="Male" /> : null}
                                        {this.props.selectedPatient?.gender == false || this.state.editMode ? <FormControlLabel value="female" control={<Radio />} label="Female" /> : null}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div>
                            <span className="TimeStamp">Created at:
                            {this.props.selectedPatient?.createdAt.split('T')[0]}</span>
                            <span className="TimeStamp">|</span>
                            <span className="TimeStamp">Updated at:
                            {this.props.selectedPatient?.updatedAt.split('T')[0]}</span>
                           <Chip  className="chip" label={"Total balance = " +total} />
                            <Button onClick={this.enableEditMode} startIcon={<EditOutlined />}
                                className="EditCard" size="small">{this.state.editMode ? 'Cancel edit'
                                    : 'Edit'}</Button>
                            {this.state.editMode ? <Button disabled={this.state.updatedName == ''}
                                onClick={this.handleUpdatePatient} startIcon={<SaveIcon />}
                                className="EditCard" size="small">Save</Button> : null}
                            {this.state.editMode ? <Button onClick={() => { this.handleDeleteDialogState(true) }}
                                startIcon={<DeleteIcon />} className="EditCard" size="small">
                                Delete patient</Button> : null}
                            {!this.state.editMode && this.props.role != 'Doctor' ? <Button onClick={this.handleClickAttach}
                                startIcon={<PlaylistAddOutlined />} className="EditCard" size="small">
                                Attach To Doctor</Button> : null}
                            {this.state.editMode && this.props.role != 'Doctor' ? <Button onClick={this.handleDetachPatient}
                                startIcon={<PlaylistAddOutlined />} className="EditCard" size="small">
                                Detach from Doctor</Button> : null}
                        </div>
                    </CardContent>
                </Card>
                <AttachPatient doctorId={this.props.doctorId}
                    patientId={this.props.selectedPatient?.id}
                    handleAttachClose={this.handleAttachClose}
                    attachDialogOpened={this.state.attachDialogOpened}
                    page={this.props.page}
                    limit={this.props.limit}
                ></AttachPatient>
                <Dialog
                    open={this.state.confirmDeletePatientDialogOpened === true}
                    // onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Do you want to delete the patient?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This will delete the patient and all it's related data like appointments, payments, services, examanations, diagnoises and treatemts.
          </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={() => { this.handleDeleteDialogState(false) }}>
                            Cancel
          </Button>
                        <Button color="primary" autoFocus onClick={() => { this.handleDeletePatient() }}>
                            Delete anyway
          </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        role: state.auth.role,
        patients: state.patients.patients,
        clinicId: state.auth.clinicId,
        totalPayments: state.payments.totalPaymentSum,
        totalServices: state.services.totalServicesSum
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updatePatient: (token, patientId, patient, clinicId, doctorId, page, limit) => dispatch(updatePatient(token, patientId, patient, clinicId, doctorId, page, limit)),
        detachPatient: (token, clinicId, doctorId, patientId, page, limit) => dispatch(detachPatient(token, clinicId, doctorId, patientId, page, limit)),
        deletePatient: (token, patientId, clinicId, doctorId, page, limit) => dispatch(deletePatient(token, patientId, clinicId, doctorId, page, limit))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientCard);