import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createSelector } from 'reselect';
import { Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { getDoctors, getBillingss, clearError } from '../../store/actions/billingsAction';
import Divider from '@material-ui/core/Divider';
import {  Link } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import { useLocation } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
const useStyles = makeStyles({
  flex: {
    display: 'flex'
  },
  tableBody:{
    overflow:'auto',
    maxHeight:'80vh'
  },
  date:{  
    minWidth:'12vw'
  },
  doctors:{
    marginTop:'20px !important'
  },
  progress:{
    minWidth:'50vw'
  },
  root:{
      margin:'10px',
      maxHeight:'87vh',
      overflow:'auto'
  }
});

export default function Billings() {

   const location = useLocation();
   var selectedDoctorIdFromRoute = location.pathname.split('/')[2]?.toLocaleLowerCase();
  const [selectedDate, handleDateChange] = React.useState(new Date());
  const classes = useStyles();


  const selectToken = state => state.auth.token;
  const selectRole = state => state.auth.role;
  const selectClinicId = state => state.auth.clinicId;
  const selectDoctors = state => state.doctors.doctors;
  const selectBillings = state => state.billings.billings;
  const selectPaymentsSum = state => state.billings.paymentsSum;
  const selectServicesSum = state => state.billings.servicesSum;
  const selectError = state => state.billings.error;
  const selectLoading = state => state.billings.loading;

  const tokenSelector = createSelector(
    selectToken,
    token => token
  );

  const roleSelector = createSelector(
    selectRole,
    role => role
);

  const clinicIdSelector = createSelector(
    selectClinicId,
    clinicId => clinicId
  );


  const doctorSelector = createSelector(
    selectDoctors,
    doctors => doctors
  );

  const billingsSelector = createSelector(
    selectBillings,
    doctors => doctors
  );

  const paymentsSumSelector = createSelector(
    selectPaymentsSum,
    paymentsSum => paymentsSum
  );

  const servicesSumSelector = createSelector(
    selectServicesSum,
    servicesSum => servicesSum
  );
  

  const errorSelector = createSelector(
    selectError,
    error => error
  );

  const loadingSelector = createSelector(
    selectLoading,
    loading => loading
  );

  const token = useSelector(tokenSelector);
  const role = useSelector(roleSelector);
  const clinicId = useSelector(clinicIdSelector);
  const doctors = useSelector(doctorSelector);
  const billings = useSelector(billingsSelector);
  const error = useSelector(errorSelector);
  const paymentsSum = useSelector(paymentsSumSelector);
  const servicesSum = useSelector(servicesSumSelector);
  const loading = useSelector(loadingSelector);
  const dispatch = useDispatch();

  var currentDate = new Date();


  useEffect(() => {
    if(role != "Doctor")
        {
            dispatch(getDoctors(token));
        }
    if(selectedDoctorIdFromRoute){
    dispatch(getBillingss(token,clinicId,selectedDoctorIdFromRoute,currentDate.getFullYear(),currentDate.getMonth()+1));
    handleDateChange(currentDate);
    }
  }, [selectedDoctorIdFromRoute]);

  const updateMonth = (e)=>{
    if(e.getMonth() != selectedDate.getMonth()){
      handleDateChange(e);

      dispatch(getBillingss(token,clinicId,selectedDoctorIdFromRoute,selectedDate.getFullYear(),e.getMonth()+1));
   
    }
  }


  return (
    <div >
      <div className={classes.flex}>
        <div className={classes.date}>
        <MuiPickersUtilsProvider required utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              openTo="year"
              views={["year", "month"]}
              label="Year and Month"
              value={selectedDate}
              format="yyyy/MM"
              onChange={(e) =>updateMonth(e)}
            />
          </MuiPickersUtilsProvider>
          <div className={classes.doctors} >
          {doctors?.map((doctor) => (
            <Link to={`/billings/${doctor.id}`}>
              <ListItem  selected = {selectedDoctorIdFromRoute == doctor.id} button key={1}>
                <ListItemText primary={doctor.name} />
              </ListItem>
            </Link>

          ))}
          </div>
          
        </div>

        {doctors != null ? <Divider className='patientDivider' orientation="vertical" flexItem />
          : null}
        <div>

        </div>

     {loading == null  || selectedDoctorIdFromRoute == null? <Typography className={classes.root}>Please select a doctor from the doctors list!</Typography> : loading === false?  <TableContainer   component={Paper}>

<Table stickyHeader  className={classes.tableBody} aria-label="simple table">

 <div className={classes.tableBody}>
 <TableHead  >
    <TableRow>
      <TableCell align="left" >Date</TableCell>
      <TableCell align="center">Services</TableCell>
      <TableCell align="center">Payments</TableCell>           
      <TableCell align="center">Debts</TableCell>
    </TableRow>
  </TableHead>
 <TableBody >

{billings?.map((row) => (

  <TableRow key={row.name}>
   <TableCell   style={{ minWidth: '200px' }} align="left"  scope="row">
     {row.date}
   </TableCell>
   <TableCell  style={{ minWidth: '200px' }}  align="center">{row.Services}</TableCell>             
   <TableCell  style={{ minWidth: '200px' }} align="center">{row.Payments}</TableCell>
   <TableCell  style={{ minWidth: '200px' }} align="center">{row.Debt}</TableCell>
 </TableRow>


))}

 
</TableBody>

 </div>
</Table>
<TableRow >
   <TableCell   style={{ minWidth: '200px' }}  align="left"  scope="row"></TableCell>
   <TableCell  style={{ minWidth: '200px' }}  align="center">Total Services: {servicesSum}</TableCell>             
   <TableCell  style={{ minWidth: '200px' }} align="center">Total Payments: {paymentsSum}</TableCell>
   <TableCell  style={{ minWidth: '200px' }} align="center">Total Debts: {servicesSum - paymentsSum} </TableCell>
 </TableRow>
</TableContainer>
:<div className={classes.progress}>
     <LinearProgress />
     </div>}
      
 
      
      </div>
      <div>
          <Snackbar className="snackbar"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
             open={error != null}
             autoHideDuration={5000}
             onClose={() => {dispatch(clearError())}}
             message={error}
             action={
              <React.Fragment>
                <IconButton  size="medium" aria-label="close" onClick={() => {dispatch(clearError())}} >
                  <CloseIcon  className={classes.closeIcon} />
                </IconButton>
              </React.Fragment>
            }
          />
        </div>
    </div>

  );
}