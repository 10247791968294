const initState = {
    doctors: null,
    clinics:null,
    error: null
}

const doctorsReducer = (state = initState, action) => {
    switch (action.type) {
        case "DOCTORS_RETRIVED":
            return { ...state, doctors: action.doctors.data.data}
        case "CLINICS_RETRIVED":
            return { ...state, clinics: action.doctors.data.data.Clinics}
        case "DOCTOR_ATTACHED":
            return { ...state }
        case "DOCTOR_DETACHED":
            return { ...state }
            case "DOCTOR_ERROR":
                return { ...state, error: action.message.message}
            case "CLEAR_DOCTOR_ERROR":
                return { ...state, error: null}
        default:
            return state
    }
};

export default doctorsReducer;