import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { attachPatient } from '../../store/actions/patientsAction';

class AttachPatient extends Component {
  state = {
    selectedPateintId: null,
  }

  handleAttachPatient = () => {
    this.props.attachPatient(this.props.token,
      this.props.clinicId,
      this.state.attachDoctorId,
      this.props.patientId, this.props.page, this.props.limit)
      this.props.handleAttachClose();
    
  }

  handleChangeAttachDoctor = (e) => {
    this.setState({
      attachDoctorId: e.target.value
    });
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.attachDialogOpened} onClose={this.props.handleAttachClose}
          aria-labelledby="form-dialog-title">
          <form>
            <DialogTitle id="form-dialog-title">Attach patient to another doctor</DialogTitle>
            <DialogContent>
              <FormControl required >
                <InputLabel >Doctor</InputLabel>
                <Select className="picker"
                  onChange={this.handleChangeAttachDoctor}
                  defaultValue=''
                >
                  {this.props.doctors ? this.props.doctors.filter(doctor =>
                    doctor.id != this.state.doctorId).map((doctor) =>
                      <MenuItem key={doctor.id} value={doctor.id}>{doctor.name}</MenuItem>
                    ) : null}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.handleAttachClose} color="primary">
                Cancel
          </Button>
              <Button disabled={this.state.attachDoctorId === null}
                onClick={this.handleAttachPatient} color="primary">
                Attach
          </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    clinicId: state.auth.clinicId,
    token: state.auth.token,
    role: state.auth.role,
    doctors: state.doctors.doctors,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    attachPatient: (token, clinicId, doctorId, patientId , page,limit) =>
      dispatch(attachPatient(token, clinicId, doctorId, patientId, page,limit))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttachPatient);