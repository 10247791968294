import axios from 'axios';
import { getUrl, checkError } from './actionsUtil';

export const signUp = (data) => {
  return (dispatch) => {
    const baseUrl = getUrl();
    const clinicPath = 'api/clinic';
    const doctorPath = 'api/doctor';
    const bothPath = 'api/clinic/doctor';

    const api = axios.create({ baseURL: baseUrl });

    switch (data.Role) {
      case 'Clinic':
        api.post(clinicPath, {
          name: data.Name,
          email: data.Email,
          password: data.Password,
          mobileNumber: data.MobileNumber,
          planId: 1,
          isActive: 0
        })
          .then(response => {
            dispatch({ type: 'AUTH_SUCCESS', response });
            localStorage.setItem('user', JSON.stringify({
              clinicId: response.data.ClinicId, token: response.data.Token,
              role: response.data.Role, name: response.data.Name,
              email: response.data.Email,
              mobileNumber: response.data.MobileNumber
            }));
          })
          .catch(error => {
            var message = checkError(error);
            dispatch({ type: 'AUTH_ERROR', message });
          });
        break;
      case 'Doctor':
        api.post(doctorPath, {
          name: data.Name,
          email: data.Email,
          password: data.Password,
          mobileNumber: data.MobileNumber
        })
          .then(response => {
            dispatch({ type: 'AUTH_SUCCESS', response });
            localStorage.setItem('user', JSON.stringify({
              doctorId: response.data.DoctorId, token: response.data.Token,
              role: response.data.Role, name: response.data.Name,
              email: response.data.Email,
              mobileNumber: response.data.MobileNumber
            }));
          })
          .catch(error => {
            var message = checkError(error);
            dispatch({ type: 'AUTH_ERROR', message });
          });
        break;
      case 'Both':
        api.post(bothPath, {
          name: data.Name,
          email: data.Email,
          password: data.Password,
          mobileNumber: data.MobileNumber,
          planId: 1
        })
          .then(response => {
            dispatch({ type: 'AUTH_SUCCESS', response });
            localStorage.setItem('user', JSON.stringify({
              clinicId: response.data.ClinicId, token: response.data.Token,
              role: response.data.Role, name: response.data.Name,
              email: response.data.Email,
              mobileNumber: response.data.MobileNumber
            }));
          })
          .catch(error => {
            var message = checkError(error);
            dispatch({ type: 'AUTH_ERROR', message });
          });
        break;
      default:
        console.log('SIGN_UP ERROR: Unexpected role => ' + data.Role);
    }
  }
}

export const signIn = (data) => {
  return (dispatch) => {
    const baseUrl = getUrl();
    var path;

    switch (data.Role) {
      case 'Clinic':
        path = 'api/clinic/login';
        break;
      case 'Doctor':
        path = 'api/doctor/login';
        break;
      case 'Secretary':
        path = 'api/secretary/login';
        break;
      default:
        console.log('SIGN_IN ERROR: Unexpected role => ' + data.Role);
    }

    const api = axios.create({ baseURL: baseUrl });
    api.post(path, {
      email: data.Email,
      password: data.Password
    })
      .then(response => {
        dispatch({ type: 'AUTH_SUCCESS', response });
        if (response.data.Role == 'Clinic' || response.data.Role == 'Secretary') {
          localStorage.setItem('user', JSON.stringify({
            clinicId: response.data.ClinicId,
             token: response.data.Token,
             role: response.data.Role,
             name: response.data.Name,
             email: response.data.Email,
             mobileNumber: response.data.MobileNumber

          }));
        }
        else {
          localStorage.setItem('user', JSON.stringify({
            doctorId: response.data.DoctorId, token: response.data.Token,
            role: response.data.Role, name: response.data.Name,
            email: response.data.Email,
            mobileNumber: response.data.MobileNumber
          }));

        }


      })
      .catch(error => {
        var message = checkError(error);
        dispatch({ type: 'AUTH_ERROR', message });
      })
  }
}

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: 'AUTH_LOGOUT' });
    localStorage.removeItem('user');
  }
}

export const clearError = () => {
  return (dispatch) => {
    dispatch({ type: 'AUTH_CLEAR_ERROR' });
  }
}

export const selectClinic = (id) => {
  return (dispatch) => {
    var user = JSON.parse(localStorage.getItem('user'))

    user['clinicId'] = id;

    // Save back to localStorage
    localStorage.setItem('user', JSON.stringify(user));

    dispatch({ type: 'AUTH_CLINIC_SELECTED', id });
  }
}