import axios from 'axios';
import { getUrl, checkError } from './actionsUtil';

export const getAppointments = (info) => {
    
    return (dispatch) => {
        dispatch({ type: 'APPOINTMENTS_ISLOADING'});
        const baseUrl = getUrl();
        const path = 'api/appointment';
        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${info.token}`
            },
        });

        api.get(path, {
            params: {
                patientId: parseInt(info.patientId),
                doctorId: parseInt(info.doctorId),
                clinicId: parseInt(info.clinicId)
            }
        })
            .then(response => {
                dispatch({ type: 'GET_APPOINTMENTS', response });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'PATIENT_ERROR', message });
                dispatch({ type: 'APPOINTMENTS_ISLOADING_STOP'});
            });
    }
}

export const getAppointmentsReposrt = (info) => {
    
    return (dispatch) => {
        dispatch({ type: 'APPOINTMENTS_ISLOADING'});
        const baseUrl = getUrl();
        const path = 'api/appointment/calender';
        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${info.token}`
            },
        });

        api.get(path, {
            params: {
                doctorId: parseInt(info.doctorId),
                clinicId: parseInt(info.clinicId),
                date: info.date
            }
        })
            .then(response => {
                dispatch({ type: 'GET_APPOINTMENTS_REPORT', response });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'APPOINTMENTS_ERROR', message });
                dispatch({ type: 'APPOINTMENTS_ISLOADING_STOP'});
            });
    }
}
export const addAppointment = (info) => {
    return (dispatch) => {
        dispatch({ type: 'APPOINTMENTS_ISLOADING'});
        const baseUrl = getUrl();
        const path = 'api/appointment';

        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${info.token}`
            }
        });

        var appointment = {
            period: info.body.period,
            comment: info.body.comment,
            date: info.body.date,
            clinicId: info.clinicId,
            doctorId: info.doctorId,
            patientId: info.patientId,
        };

        api.post(path, appointment)
            .then(response => {
                dispatch({ type: 'ADD_APPOINTMENT', response });

                api.get(path, {
                    params: {
                        patientId: parseInt(info.patientId),
                        doctorId: parseInt(info.doctorId),
                        clinicId: parseInt(info.clinicId)
                    }
                })
                    .then(response => {
                        dispatch({ type: 'GET_APPOINTMENTS', response });
                    }).catch(error => {
                        var message = checkError(error);
                        dispatch({ type: 'PATIENT_ERROR', message });
                        dispatch({ type: 'APPOINTMENTS_ISLOADING_STOP'});
                    });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'PATIENT_ERROR', message });
                dispatch({ type: 'APPOINTMENTS_ISLOADING_STOP'});
            });
    }
}

export const updateAppointment = (info) => {
    return (dispatch) => {
        dispatch({ type: 'APPOINTMENTS_ISLOADING'});
        const baseUrl = getUrl();
        const path = 'api/appointment';
        const updatePath = `api/appointment/${info.id}`;

        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${info.token}`
            }
        });

        var appointment = {
            period: info.body.period,
            comment: info.body.comment,
            date: info.body.date,
            clinicId: info.clinicId,
            doctorId: info.doctorId,
            patientId: info.patientId,
        };

        api.put(updatePath, appointment)
            .then(response => {
                dispatch({ type: 'UPDATE_APPOINTMENT', response });

                api.get(path, {
                    params: {
                        patientId: parseInt(info.patientId),
                        doctorId: parseInt(info.doctorId),
                        clinicId: parseInt(info.clinicId)
                    }
                })
                    .then(response => {
                        dispatch({ type: 'GET_APPOINTMENTS', response });
                    }) .catch(error => {
                        var message = checkError(error);
                        dispatch({ type: 'PATIENT_ERROR', message });
                        dispatch({ type: 'APPOINTMENTS_ISLOADING_STOP'});
                    });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'PATIENT_ERROR', message });
                dispatch({ type: 'APPOINTMENTS_ISLOADING_STOP'});
            });
    }
}

export const deleteAppointment = (info) => {
    return (dispatch) => {
        dispatch({ type: 'APPOINTMENTS_ISLOADING'});
        const baseUrl = getUrl();
        const path = 'api/appointment';
        const deletePath = `api/appointment/${info.id}`;

        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${info.token}`
            }
        });

        api.delete(deletePath, {
            params: {
                patientId: parseInt(info.patientId),
                doctorId: parseInt(info.doctorId),
                clinicId: parseInt(info.clinicId)
            }
        })
            .then(response => {
                dispatch({ type: 'DELETE_APPOINEMENT', response });

                api.get(path, {
                    params: {
                        patientId: parseInt(info.patientId),
                        doctorId: parseInt(info.doctorId),
                        clinicId: parseInt(info.clinicId)
                    }
                })
                    .then(response => {
                        dispatch({ type: 'GET_APPOINTMENTS', response });
                    }).catch(error => {
                        var message = checkError(error);
                        dispatch({ type: 'PATIENT_ERROR', message });
                        dispatch({ type: 'APPOINTMENTS_ISLOADING_STOP'});
                    });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'PATIENT_ERROR', message });
                dispatch({ type: 'APPOINTMENTS_ISLOADING_STOP'});
            });
    }
}

export const clearAppointmentsError = () => {
    return (dispatch) => {
      dispatch({ type: 'APPOINTMENTS_CLEAR_ERROR' });
    }
  }