import settings from '../../appSettings.json';

export const getUrl = () => {
    var url = settings.Environment.toLocaleLowerCase() == "development" ?
        settings.URLs.DEV_URL : settings.URLs.PROD_URL;

    if (url[url.length - 1] != "/")
        url += "/";

    return url;
}

export const checkError = (error) => {
    var message = {
        message: 'not respond!'
    };

    if (error.request) {
        if (error.request.response) {
            message = JSON.parse(error.request.response);
        }
    }
    return message;
}