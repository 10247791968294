import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import AddBox from '@material-ui/icons/AddBox';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getTreatments } from '../../store/actions/treatmentAction';
import { createSelector } from 'reselect';
import LinearProgress from '@material-ui/core/LinearProgress';


const columns = [
    { id: 'treatment', label: 'Treatment', minWidth: 100 },
    { id: 'date', label: 'Date', minWidth: 100 },
    { id: 'loggedBy', label: 'Logged by', minWidth: 80 },
    { id: 'createdAt', label: 'Logged At', minWidth: 100 },
    { id: 'updatedBy', label: 'Updated by', minWidth: 80 },
    { id: 'updatedAt', label: 'Updated at', minWidth: 100 }
];

const useStyles = makeStyles({
    root: {
        width: '100%',
        'max-width': '62vw'
    },
    container: {
        maxHeight: 600,
    },
    scroll: {
        maxHeight: '40vh !important',
        overflow: 'auto'
    },
    addButton: {
        float: 'left',
        height: '40px',
        wedth: '40px',
        padding: '5px'
    },
    addIcon: {
        'color': 'rgb(4, 67, 117) !important',
        height: '20px'
    }
});

export default function Treatment(props) {
    const classes = useStyles();
    const selectToken = state => state.auth.token;
    const selectTreatment = state => state.treatments.treatments;
    const selectTeeth = state => state.treatments.teethNumber;
    const selectTreatmentsIsLoading = state => state.treatments.isLoading;

    const tokenSelector = createSelector(
        selectToken,
        token => token
    );
    const treatmentsSelector = createSelector(
        selectTreatment,
        treatments => treatments
    );
    const teethSelector = createSelector(
        selectTeeth,
        teethNumber => teethNumber
    );
    const treatementsIsLoadingSelector = createSelector(
        selectTreatmentsIsLoading,
        isLoading => isLoading
    );
    const treatments = useSelector(treatmentsSelector, shallowEqual);
    const token = useSelector(tokenSelector);
    const teethId = useSelector(teethSelector);
    const isLoading = useSelector(treatementsIsLoadingSelector);
    const dispatch = useDispatch();

    const handleAdd = (event) => {
        props.toggleRightMenu(true, {
            "add": true
        })
    };

    const handleUpdate = (id, date, treatment) => {
        props.toggleRightMenu(true, {
            "id": id,
            "update": true,
            "treatment": treatment,
            "date": new Date(date)
        })
    };

    useEffect(() => {
        dispatch(getTreatments({
            token: token, patientId: props.patientId,
            doctorId: props.doctorId, clinicId: props.clinicId
        }))

    }, [props.clinicId, props.doctorId, props.patientId, token, dispatch]);

    return (
        <div>
                {isLoading == true? <LinearProgress />:<div>
            <IconButton aria-label="add" className={classes.addButton} onClick={() => { handleAdd() }}>
                <AddBox className={classes.addIcon} />
            </IconButton>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <div className={classes.scroll}> <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {treatments?.filter(obj => obj["treatment"].includes(teethId)).map((row) => {
                                return (
                                    <TableRow className="tableRow"
                                        onClick={() => { handleUpdate(row.id, row.date, row.treatment) }}
                                        hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns?.map((column) => {
                                            var value = row[column.id];
                                            if (column.id === 'createdAt' || column.id === 'updatedAt' || column.id === 'date') {
                                                value = value?.split('T')[0];
                                            }
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table></div>
                </TableContainer>
            </Paper>
            </div>}
        </div>
    );
}