import axios from 'axios';
import { getUrl, checkError } from './actionsUtil';

export const getExaminations = (info) => {
    return (dispatch) => {
        dispatch({ type: 'EXAMINATIONS_ISLOADING'});
        const baseUrl = getUrl();
        const path = 'api/examination';
        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${info.token}`
            },
        });

        api.get(path, {
            params: {
                patientId: parseInt(info.patientId),
                doctorId: parseInt(info.doctorId),
                clinicId: parseInt(info.clinicId)
            }
        })
            .then(response => {
                dispatch({ type: 'GET_EXAMINATIONS', response });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'PATIENT_ERROR', message });
                dispatch({ type: 'EXAMINATIONS_ISLOADING_STOP'});
            });
    }
}

export const addExamination = (info) => {
    return (dispatch) => {
        dispatch({ type: 'EXAMINATIONS_ISLOADING'});
        const baseUrl = getUrl();
        const path = 'api/examination';

        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${info.token}`
            }
        });

        const userTimezoneOffset = info.body.date.getTimezoneOffset() * 60000;
        const dateWithoutTimeZone = new Date(info.body.date.getTime() - userTimezoneOffset);

        var examination = {
            examination: info.body.examination,
            date: dateWithoutTimeZone,
            clinicId: info.clinicId,
            doctorId: info.doctorId,
            patientId: info.patientId,
        };

        api.post(path, examination)
            .then(response => {
                dispatch({ type: 'ADD_EXAMINATION', response });

                api.get(path, {
                    params: {
                        patientId: parseInt(info.patientId),
                        doctorId: parseInt(info.doctorId),
                        clinicId: parseInt(info.clinicId)
                    }
                })
                    .then(response => {
                        dispatch({ type: 'GET_EXAMINATIONS', response });
                    }).catch(error => {
                        var message = checkError(error);
                        dispatch({ type: 'PATIENT_ERROR', message });
                        dispatch({ type: 'EXAMINATIONS_ISLOADING_STOP'});
                    });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'PATIENT_ERROR', message });
                dispatch({ type: 'EXAMINATIONS_ISLOADING_STOP'});
            });
    }
}

export const updateExamination = (info) => {
    return (dispatch) => {
        dispatch({ type: 'EXAMINATIONS_ISLOADING'});
        const baseUrl = getUrl();
        const path = 'api/examination';
        const updatePath = `api/examination/${info.id}`;

        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${info.token}`
            }
        });

        const userTimezoneOffset = info.body.date.getTimezoneOffset() * 60000;
        const dateWithoutTimeZone = new Date(info.body.date.getTime() - userTimezoneOffset);

        var examination = {
            examination: info.body.examination,
            date: dateWithoutTimeZone,
            clinicId: info.clinicId,
            doctorId: info.doctorId,
            patientId: info.patientId,
        };

        api.put(updatePath, examination)
            .then(response => {
                dispatch({ type: 'UPDATE_EXAMINATION', response });

                api.get(path, {
                    params: {
                        patientId: parseInt(info.patientId),
                        doctorId: parseInt(info.doctorId),
                        clinicId: parseInt(info.clinicId)
                    }
                })
                    .then(response => {
                        dispatch({ type: 'GET_EXAMINATIONS', response });
                    }).catch(error => {
                        var message = checkError(error);
                        dispatch({ type: 'PATIENT_ERROR', message });
                        dispatch({ type: 'EXAMINATIONS_ISLOADING_STOP'});
                    });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'PATIENT_ERROR', message });
                dispatch({ type: 'EXAMINATIONS_ISLOADING_STOP'});
            });
    }
}

export const deleteExamination = (info) => {
    return (dispatch) => {
        dispatch({ type: 'EXAMINATIONS_ISLOADING'});
        const baseUrl = getUrl();
        const path = 'api/examination';
        const deletePath = `api/examination/${info.id}`;

        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${info.token}`
            }
        });

        api.delete(deletePath, {
            params: {
                patientId: parseInt(info.patientId),
                doctorId: parseInt(info.doctorId),
                clinicId: parseInt(info.clinicId)
            }
        })
            .then(response => {
                dispatch({ type: 'DELETE_EXAMINATION', response });

                api.get(path, {
                    params: {
                        patientId: parseInt(info.patientId),
                        doctorId: parseInt(info.doctorId),
                        clinicId: parseInt(info.clinicId)
                    }
                })
                    .then(response => {
                        dispatch({ type: 'GET_EXAMINATIONS', response });
                    }).catch(error => {
                        var message = checkError(error);
                        dispatch({ type: 'PATIENT_ERROR', message });
                        dispatch({ type: 'EXAMINATIONS_ISLOADING_STOP'});
                    });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'PATIENT_ERROR', message });
                dispatch({ type: 'EXAMINATIONS_ISLOADING_STOP'});
            });
    }
}