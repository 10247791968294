import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createSelector } from 'reselect';
import { useSelector} from 'react-redux';
import { Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  flex: {
    display: 'flex'
  },
  title:{
    textAlign:'left'
  },
  subTitle:{
    marginLeft:'5px'
  }
});


export default function Home() {
  const classes = useStyles();

  
  const location = useLocation();

  const selectName = state => state.auth.name;  

  const nameSelector = createSelector(
    selectName,
    name => name
  );

  const name = useSelector(nameSelector);
  

  var date = new Date();

  var greeting = date.getHours() >18 ? "Good evening" : date.getHours() >12 ? "Good afternoon" : "Good morning";

  return (
      <div>
       <Typography className={classes.title} variant="h5" >{greeting} {name},</Typography>
       <br></br>
       <Typography className={classes.subTitle}  variant="subtitle4" >Welcome to your Clinic Dashboard :)</Typography>
      
      </div>
  
  );
}