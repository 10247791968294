import axios from 'axios';
import { getUrl, checkError } from './actionsUtil';
import FormData from 'form-data'

export const getDiagnoses = (info) => {

    return (dispatch) => {
        dispatch({ type: 'DIAGNOSES_ISLOADING' });
        const baseUrl = getUrl();
        const path = 'api/diagnose';
        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${info.token}`
            },
        });

        api.get(path, {
            params: {
                patientId: parseInt(info.patientId),
                doctorId: parseInt(info.doctorId),
                clinicId: parseInt(info.clinicId)
            }
        })
            .then(response => {
                dispatch({ type: 'GET_DIAGNOSES', response });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'PATIENT_ERROR', message });
                dispatch({ type: 'DIAGNOSES_ISLOADING_STOP' });
            });
    }
}

export const addDiagnose = (info) => {
    return (dispatch) => {
        dispatch({ type: 'DIAGNOSES_ISLOADING' });
        const baseUrl = getUrl();
        const path = 'api/diagnose';

        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${info.token}`
            }
        });

        const userTimezoneOffset = info.body.date.getTimezoneOffset() * 60000;
        const dateWithoutTimeZone = new Date(info.body.date.getTime() - userTimezoneOffset);

        var diagnose = {
            diagnose: info.body.diagnose,
            date: dateWithoutTimeZone,
            imageUrl: info.body.imageUrl,
            clinicId: info.clinicId,
            doctorId: info.doctorId,
            patientId: info.patientId,
        };

        api.post(path, diagnose)
            .then(response => {
                dispatch({ type: 'ADD_DIAGNOSE', response });

                api.get(path, {
                    params: {
                        patientId: parseInt(info.patientId),
                        doctorId: parseInt(info.doctorId),
                        clinicId: parseInt(info.clinicId)
                    }
                })
                    .then(response => {
                        dispatch({ type: 'GET_DIAGNOSES', response });
                    }).catch(error => {
                        var message = checkError(error);
                        dispatch({ type: 'PATIENT_ERROR', message });
                        dispatch({ type: 'DIAGNOSES_ISLOADING_STOP' });
                    });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'PATIENT_ERROR', message });
                dispatch({ type: 'DIAGNOSES_ISLOADING_STOP' });
            });
    }
}

export const attachImage = (info) => {
    return (dispatch) => {
        dispatch({ type: 'DIAGNOSES_IMAGE_UPLOADING' });
        const path = 'api/diagnose/image';
        const baseUrl = getUrl();
        let formData = new FormData()
        formData.append('image', info.file)

        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${info.token}`
            }
        });

        api.post(path, formData,
            {
                params: {
                    doctorId: parseInt(info.doctorId),
                    clinicId: parseInt(info.clinicId)
                }
            })
            .then(response => {
                dispatch({ type: 'IMAGE_UPLOADED', response });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'PATIENT_ERROR', message });
                dispatch({ type: 'DIAGNOSES_IMAGE_UPLOADED' });
            });
    }
}
export const updateDiagnose = (info) => {
    return (dispatch) => {
        dispatch({ type: 'DIAGNOSES_ISLOADING' });
        const baseUrl = getUrl();
        const path = 'api/diagnose';
        const updatePath = `api/diagnose/${info.id}`;

        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${info.token}`
            }
        });

        const userTimezoneOffset = info.body.date.getTimezoneOffset() * 60000;
        const dateWithoutTimeZone = new Date(info.body.date.getTime() - userTimezoneOffset);

        var diagnose = {
            diagnose: info.body.diagnose,
            date: dateWithoutTimeZone,
            clinicId: info.clinicId,
            imageUrl: info.body.imageUrl,
            doctorId: info.doctorId,
            patientId: info.patientId,
        };

        api.put(updatePath, diagnose)
            .then(response => {
                dispatch({ type: 'ADD_DIAGNOSE', response });

                api.get(path, {
                    params: {
                        patientId: parseInt(info.patientId),
                        doctorId: parseInt(info.doctorId),
                        clinicId: parseInt(info.clinicId)
                    }
                })
                    .then(response => {
                        dispatch({ type: 'GET_DIAGNOSES', response });
                    }).catch(error => {
                        var message = checkError(error);
                        dispatch({ type: 'PATIENT_ERROR', message });
                        dispatch({ type: 'DIAGNOSES_ISLOADING_STOP' });
                    });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'PATIENT_ERROR', message });
                dispatch({ type: 'DIAGNOSES_ISLOADING_STOP' });
            });
    }
}

export const deleteDiagnose = (info) => {
    return (dispatch) => {
        dispatch({ type: 'DIAGNOSES_ISLOADING' });
        const baseUrl = getUrl();
        const path = 'api/diagnose';
        const deletePath = `api/diagnose/${info.id}`;

        const api = axios.create({
            baseURL: baseUrl, headers: {
                'Authorization': `Bearer ${info.token}`
            }
        });

        api.delete(deletePath, {
            params: {
                patientId: parseInt(info.patientId),
                doctorId: parseInt(info.doctorId),
                clinicId: parseInt(info.clinicId)
            }
        })
            .then(response => {
                dispatch({ type: 'DELETE_DIAGNOSE', response });

                api.get(path, {
                    params: {
                        patientId: parseInt(info.patientId),
                        doctorId: parseInt(info.doctorId),
                        clinicId: parseInt(info.clinicId)
                    }
                })
                    .then(response => {
                        dispatch({ type: 'GET_DIAGNOSES', response });
                    }).catch(error => {
                        var message = checkError(error);
                        dispatch({ type: 'PATIENT_ERROR', message });
                        dispatch({ type: 'DIAGNOSES_ISLOADING_STOP' });
                    });
            })
            .catch(error => {
                var message = checkError(error);
                dispatch({ type: 'PATIENT_ERROR', message });
                dispatch({ type: 'DIAGNOSES_ISLOADING_STOP' });
            });
    }
}
export const clearImage = () => {

    return (dispatch) => {
        dispatch({ type: 'DIAGNOSES_IMAGE_CLEAR' });
    }
}