import React from 'react';
import { Button } from '@material-ui/core';
import './tooth.css';
import NavigateNext from '@material-ui/icons/NavigateNext';

export default function ToothMap(props) {

	const selectTooth = (e) => {
		props.filterByTeeth(e.target.id)
	}

	return (
		<div >
			<div class="tooth-chart">
				<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
					viewBox="0 0 450 700" enable-background="new 0 0 450 700">

					<g class="dtfmLabels">
						<text id="txt85" transform="matrix(1 0 0 1 1.0001 447.0915)" font-family="'Avenir-Heavy'" font-size="24px">85</text>
						<text id="txt84" transform="matrix(1 0 0 1 23.3102 506.416)" font-family="'Avenir-Heavy'" font-size="24px">84</text>
						<text id="txt83" transform="matrix(1 0 0 1 57.4898 569.9049)" font-family="'Avenir-Heavy'" font-size="24px">83</text>
						<text id="txt82" transform="matrix(1 0 0 1 109.3098 618.5447)" font-family="'Avenir-Heavy'" font-size="24px">82</text>
						<text id="txt81" transform="matrix(1 0 0 1 173.8689 642.0117)" font-family="'Avenir-Heavy'" font-size="24px">81</text>
						<text id="txt71" transform="matrix(1 0 0 1 236.6671 642.012)" font-family="'Avenir-Heavy'" font-size="24px">71</text>
						<text id="txt72" transform="matrix(1 0 0 1 300.104 618.5447)" font-family="'Avenir-Heavy'" font-size="24px">72</text>
						<text id="txt73" transform="matrix(1 0 0 1 358.7073 569.905)" font-family="'Avenir-Heavy'" font-size="24px">73</text>
						<text id="txt74" transform="matrix(1 0 0 1 393.2542 506.4159)" font-family="'Avenir-Heavy'" font-size="24px">74</text>
						<text id="txt75" transform="matrix(1 0 0 1 405.3402 447.0916)" font-family="'Avenir-Heavy'" font-size="24px">75</text>
						<text id="txt65" transform="matrix(1 0 0 1 411.0546 240.9981)" font-family="'Avenir-Heavy'" font-size="24px">65</text>
						<text id="txt64" transform="matrix(1 0 0 1 390.5584 186.7916)" font-family="'Avenir-Heavy'" font-size="24px">64</text>
						<text id="txt63" transform="matrix(1 0 0 1 362.0746 133.1618)" font-family="'Avenir-Heavy'" font-size="24px">63</text>
						<text id="txt62" transform="matrix(1 0 0 1 314.9963 97.0455)" font-family="'Avenir-Heavy'" font-size="24px">62</text>
						<text id="txt61" transform="matrix(1 0 0 1 236.6669 69.3723)" font-family="'Avenir-Heavy'" font-size="24px">61</text>
						<text id="txt51" transform="matrix(1 0 0 1 173.869 69.3719)" font-family="'Avenir-Heavy'" font-size="24px">51</text>
						<text id="txt52" transform="matrix(1 0 0 1 111.8719 90.5134)" font-family="'Avenir-Heavy'" font-size="24px">52</text>
						<text id="txt53" transform="matrix(1 0 0 1 59.4649 141.1669)" font-family="'Avenir-Heavy'" font-size="24px">53</text>
						<text id="txt54" transform="matrix(1 0 0 1 25.25 184.2726)" font-family="'Avenir-Heavy'" font-size="24px">54</text>
						<text id="txt55" transform="matrix(1 0 0 1 7.6667 247.2726)" font-family="'Avenir-Heavy'" font-size="24px">55</text>
					</g>
					<g class="spots">
						<polygon onClick={selectTooth} id="85" fill={props.selectedTooth == "85" ? "#4fa1bc" : "#FFFFFF"} data-key="" points="65.7,373.5 74,375.2 82,380.8 86.3,384.8 90.3,390.2 92.7,395.8 93,404.8 93,416.8 
		93,424.5 94.3,432.2 95.7,438.5 94.7,444.2 92.3,450.2 89,453.5 77.7,456.5 68.7,460.5 64.3,461.2 54.7,456.5 48.3,450.2 42,442.2 
		36.3,425.2 33.3,407.8 34.3,399.5 36,392.8 39.7,383.5 47,377.2 57,373.2 	"/>
						<polygon onClick={selectTooth} id="84" fill={props.selectedTooth == "84" ? "#4fa1bc" : "#FFFFFF"} data-key="84" points="93.7,456.2 82.7,455.8 70.7,460.8 66.3,467.2 63.7,474.2 62,487.2 63.3,500.2 
		66.3,509.5 70.3,517.2 76.3,524.8 85.3,531.2 93.3,534.8 102.3,535.8 109.3,534.5 119,529.8 121.7,521.2 122,510.2 120.7,502.8 
		114.3,484.5 108.7,470.8 103,462.5 	"/>
						<polygon onClick={selectTooth} id="83" fill={props.selectedTooth == "83" ? "#4fa1bc" : "#FFFFFF"} data-key="83" points="129.3,532.2 137,535.2 142.7,541.2 146,548.8 147.7,557.8 144.7,565.2 136.7,570.5 
		123,573.8 113,574.2 105,572.8 101.3,564.8 103,551.2 107.3,541.2 111.3,535.5 124,531.5 	"/>
						<polygon onClick={selectTooth} id="82" fill={props.selectedTooth == "82" ? "#4fa1bc" : "#FFFFFF"} data-key="82" points="164.3,564.5 168.3,568.8 173,592.2 172,598.2 169.3,601.8 162,601.8 152,599.8 
		142.3,595.5 131,586.8 130.7,584.5 135,575.8 140.3,570.2 149.7,564.5 159,562.8 	"/>
						<polygon onClick={selectTooth} id="81" fill={props.selectedTooth == "81" ? "#4fa1bc" : "#FFFFFF"} data-key="81" points="208,576.5 218,607.2 214,611.5 206.7,613.2 191,612.8 179,606.8 172.3,601.5 
		174,597.8 190.3,578.2 199.7,571.8 205.7,573.8 	"/>
						<polygon onClick={selectTooth} id="71" fill={props.selectedTooth == "71" ? "#4fa1bc" : "#FFFFFF"} data-key="71" points="244.3,574.2 235.7,575.2 230.7,581.8 225.7,593.5 221.7,604.5 220.3,609.8 224,613.5 
		234.3,615.5 248.7,613.8 258.7,610.8 264,606.2 265.3,598.8 	"/>
						<polygon onClick={selectTooth} id="72" fill={props.selectedTooth == "72" ? "#4fa1bc" : "#FFFFFF"} data-key="72" points="306.3,575.8 298.7,569.2 289.7,566.2 280.7,564.2 275.3,566.8 272,572.2 268.7,583.8 
		267.3,593.2 266.3,599.2 266.7,603.8 270.7,607.8 276.3,608.8 286.7,605.2 300,597.5 307.7,590.5 309,583.5 	"/>
						<polygon onClick={selectTooth} id="73" fill={props.selectedTooth == "73" ? "#4fa1bc" : "#FFFFFF"} data-key="73" points="335,532.2 341.7,546.5 343.3,562.8 339.7,570.5 331.7,573.5 309,572.5 302,569.2 
		298,564.5 299.7,548.2 303,539.5 307.3,531.8 315.3,525.2 321.3,525.2 325,525.5 	"/>
						<polygon onClick={selectTooth} id="74" fill={props.selectedTooth == "74" ? "#4fa1bc" : "#FFFFFF"} data-key="74" points="354,450.2 365.7,453.8 377,459.5 384.3,469.2 385,481.5 382.7,491.8 369.7,517.8 
		357.3,527.8 340.7,532.2 331.7,528.5 324.3,523.5 321,515.2 320.7,504.2 325.3,489.5 330.3,470.2 335,460.5 343.7,453.2 	"/>
						<polygon onClick={selectTooth} id="75" fill={props.selectedTooth == "75" ? "#4fa1bc" : "#FFFFFF"} data-key="75" points="391.7,370.2 379,367.8 366.7,370.2 356.3,378.8 350.3,388.2 347.7,396.2 348,411.5 
		346.7,427.8 347.7,438.5 352,445.2 364.7,453.2 374.3,455.5 381.7,454.8 389.7,451.8 394.3,445.8 397.7,433.8 401.7,421.5 
		406.7,413.5 409.7,400.5 408.7,390.5 404.3,380.2 	"/>
						<polygon onClick={selectTooth} id="65" fill={props.selectedTooth == "65" ? "#4fa1bc" : "#FFFFFF"} data-key="65" points="384.3,231.2 391.3,232.5 402.3,241.5 409,253.8 414.3,267.2 416,281.8 413.7,300.2 
		409.3,308.8 400.3,314.5 386,317.2 375,314.8 367.3,310.5 358.7,303.5 353.7,291.5 353.7,277.8 354.7,267.8 350.3,259.8 
		351.7,248.2 357.7,239.5 367.7,237.5 	"/>
						<polygon onClick={selectTooth} id="64" fill={props.selectedTooth == "64" ? "#4fa1bc" : "#FFFFFF"} data-key="64" points="359.3,167.2 367.3,169.8 375.3,182.5 384,199.8 389.7,211.8 389.3,220.5 386,227.5 
		377.3,233.5 363.3,238.5 355.3,237.5 348.3,233.2 343.3,224.8 337,211.5 330.7,195.8 328,186.8 332.7,175.2 342.7,168.5 	"/>
						<polygon onClick={selectTooth} id="63" fill={props.selectedTooth == "63" ? "#4fa1bc" : "#FFFFFF"} data-key="63" points="305.7,136.5 303,143.8 301,150.5 304,161.5 313,170.2 323,172.8 331.7,172.5 
		339.3,168.8 346,162.5 350.3,152.2 351.3,141.2 347.3,134.5 343,132.2 334,131.5 322.3,130.2 316,129.8 	"/>
						<polygon onClick={selectTooth} id="62" fill={props.selectedTooth == "62" ? "#4fa1bc" : "#FFFFFF"} data-key="62" points="277.3,98.8 276.7,107.2 276,119.2 276,129.5 279.7,138.2 285.7,141.2 293.3,141.5 
		301.7,135.8 316.7,126.8 320,122.5 320,116.2 316.3,109.8 301.7,101.8 294.7,98.8 282,97.5 	"/>
						<polygon onClick={selectTooth} id="61" fill={props.selectedTooth == "61" ? "#4fa1bc" : "#FFFFFF"} data-key="61" points="222,87.5 222.7,93.8 226.7,98.2 231,107.8 236.7,121.5 242,125.8 248,126.8 257,120.2 
		275.3,101.5 276,95.2 273.7,92.2 266,85.8 248.7,80.5 236.3,80.2 229,81.8 	"/>
						<polygon onClick={selectTooth} id="51" fill={props.selectedTooth == "51" ? "#4fa1bc" : "#FFFFFF"} data-key="51" points="168.3,94.5 172,103.5 175.3,107.5 184,120.5 190.3,127.5 198.7,128.5 203.3,124.8 
		209.7,115.2 219.7,92.5 218.7,85.8 209,83.8 191.3,82.5 182,84.2 173.7,88.2 	"/>
						<polygon onClick={selectTooth} id="52" fill={props.selectedTooth == "52" ? "#4fa1bc" : "#FFFFFF"} data-key="52" points="123.7,124.2 131.3,131.2 143.3,137.5 151.3,141.8 159,142.5 165.7,137.5 169.3,125.8 
		169,108.8 169.7,100.2 165,95.5 158.3,95.2 145.7,97.8 138.7,102.5 130,108.2 124.3,114.2 	"/>
						<polygon onClick={selectTooth} id="53" fill={props.selectedTooth == "53" ? "#4fa1bc" : "#FFFFFF"} data-key="53" points="106.7,132.2 118,132.2 130.3,132.5 139.7,138.2 144.7,147.8 144.7,160.8 134.3,173.8 
		121.7,180.2 115,180.2 107.7,174.5 100,169.8 98.7,156.8 97.3,147.8 98.3,138.8 104.7,133.2 	"/>
						<polygon onClick={selectTooth} id="54" fill={props.selectedTooth == "54" ? "#4fa1bc" : "#FFFFFF"} data-key="54" points="90.2,242.2 97.3,236.1 106.3,224.2 114.9,200.1 117.3,189.4 113,179.1 95.7,169.8 
		86.2,169.8 71.9,179.1 63.5,191.3 58.6,202.5 54,215.2 55.8,227.2 65.8,238.9 79.1,242.2 	"/>
						<polygon onClick={selectTooth} id="55" fill={props.selectedTooth == "55" ? "#4fa1bc" : "#FFFFFF"} data-key="55" points="55.2,236.1 60,237.2 69.8,240.2 86.9,243.3 92,247 95.7,255.8 95.7,271.2 94.7,286.5 
		90.2,305.2 82.8,312.8 72,318.5 56.7,319.3 48.8,317.5 40.8,311.5 33,300.3 31.3,284.7 34.2,273.8 39.3,256.3 42.3,248 	"/>
					</g>
					<g id="child-outlines">
						<g id="XMLID_9_">
							<path id="XMLID_167_" fill="#010101" d="M67.9,257.2c0.1-0.5-0.5-1-0.9-0.8l0-0.1c-0.4,0.6-0.4,1.4,0,2
			C67.4,258,67.8,257.7,67.9,257.2z"/>
							<path id="XMLID_145_" fill="#010101" d="M391.2,293.4c0.2,0.6,1.1,1.1,1.6,0.6c1.1-0.9,0.1-3.1-1.3-2.9l0,0.4
			C391.3,292.1,391,292.8,391.2,293.4z"/>
							<path id="XMLID_144_" fill="#010101" d="M90.4,191.3c-0.6,0.7,0.3,1.9,1.3,2l0.2-0.2c0.9,0.9,2.7-0.8,1.8-1.8
			C92.6,191.3,91.5,191.3,90.4,191.3z"/>
							<path id="XMLID_143_" fill="#010101" d="M63.6,393.6c1,0.1,2-0.9,1.9-1.9c-0.1-1-1.3-1.8-2.3-1.4l0.1,0.5
			C63.4,391.7,63.5,392.6,63.6,393.6z"/>
							<path id="XMLID_142_" fill="#010101" d="M380.9,401c-1,1.3-1,3.2,0,4.5c1.8,2.5,0.9,6-0.5,8.8c-1.4,2.7-3.4,5.4-3.5,8.5
			c0,1.1,0.1,2.2-0.2,3.2c-0.7,2-3.3,2.7-5.4,2.6c-2.1-0.1-4.4-0.5-6.4,0.4c0.7,2,3.6,1.8,5.8,1.5c4.1-0.5,8.4,0.7,11.7,3.1
			c-3.5-6.9-2.7-15.8,2.1-21.9c1.3-1.6,1.2-4,0.3-5.9C383.9,403.9,382.4,402.4,380.9,401z"/>
							<path id="XMLID_141_" fill="#010101" d="M94.8,506.7c0.2,1,1.5,1.6,2.4,1c-0.3-0.8-0.5-1.7-0.8-2.5l0.2-0.4
			C95.6,504.7,94.6,505.7,94.8,506.7z"/>
							<path id="XMLID_140_" fill="#010101" d="M352.5,484c1-0.4,1.9-1.3,2.3-2.3c-0.6-0.7-1.8-0.7-2.5,0c-0.6,0.7-0.6,1.9,0.1,2.5
			L352.5,484z"/>
							<path id="XMLID_139_" fill="#010101" d="M75.9,434.2c-1-0.6-1.9-1.3-2.9-1.9c-2.2-1.5-4.4-2.9-6.6-4.4c-0.7-0.5-0.5-1.6-0.2-2.5
			c1.2-3.9,0.6-8.3-1.6-11.7c-0.6-1-2.2-1.8-2.7-0.7c4.3,5.2,3.7,13.8-1.2,18.5c-1,0.9-2,2.8-0.8,3.3c2.6-3,7.3-3.8,10.7-1.9
			C72.4,433.8,74.4,435.4,75.9,434.2z"/>
							<path id="XMLID_138_" fill="#010101" d="M155.2,105.8c-0.6-1.3-2.3-2-3.6-1.5l0,0.1c-1,0.3-1.7,1.4-1.4,2.4
			C151.9,107,153.7,106.7,155.2,105.8z"/>
							<path id="XMLID_137_" fill="#010101" d="M82.5,186.8c0.6,1.3,1.5,2.4,2.7,3.2l0,0.1c0.6-0.1,1.1-0.3,1.7-0.4
			C86.3,188,84.4,186.7,82.5,186.8z"/>
							<path id="XMLID_136_" fill="#010101" d="M361.4,216.8l0.5-0.8c2.1-0.5,2-4.2-0.1-4.6C360.5,212.9,360.4,215.2,361.4,216.8z" />
							<path id="XMLID_135_" fill="#010101" d="M355.9,500.4c1.7-2.7,3.5-5.7,6.5-6.7c1.4-0.4,3.3-0.8,3.3-2.2c0-1.6-2.5-1.7-3.9-1
			c-4.1,2-6.9,5.9-9.5,9.7c-1.3,1.8-2.6,3.6-3.8,5.5C351.6,505.8,354.2,503.1,355.9,500.4z"/>
							<path id="XMLID_134_" fill="#010101" d="M386.1,264.8c-0.5,1.9,0.5,4.2,2.3,5.1c0.7,0.1,1.3-0.9,0.8-1.4l0.1,0
			C388.2,267.3,387.2,266,386.1,264.8z"/>
							<path id="XMLID_133_" fill="#010101" d="M63.5,402.1c1,1,2.6,1.2,3.8,0.6c-0.8-2-2.1-4.4-4.2-4.6l0.1,0.2
			C62.4,399.4,62.6,401.1,63.5,402.1z"/>
							<path id="XMLID_116_" fill="#010101" d="M417.3,277.6c0-1.2-0.2-2.7-0.6-3.8c0.3-2.8-0.4-5.4-1.4-8c-0.3-2.5-1.1-4.9-2.4-7
			c-0.3-1-0.7-2-1.2-2.9c0-0.1,0-0.1,0-0.2c0-1.1-0.5-2.2-0.9-3.2c-1.9-4.4-3.9-8.9-7.1-12.6c-2.6-3-6-5.2-9.3-7.4
			c-0.6-0.4-1.3-0.8-2-1c-1.8-1.3-4.3-1.9-6.7-2c4.1-4.4,6.7-10.2,6.1-16.2c-0.5-4.5-2.6-8.6-4.8-12.6c-3.2-6-6.5-12-9.7-18.1
			c-3.1-5.7-6.3-11.6-11.6-15.2c-4.5-3-10.9-3.8-15.3-1.1c-2.1,0.4-4.3,0.6-6.5,0.7c5-6,8.2-13.4,9-21.2c0.3-3.7-0.3-8.1-2.4-11
			c0,0,0,0,0,0c-0.2-0.4-0.4-0.6-0.7-0.9c-0.3-0.3-0.7-0.6-1-0.9c-0.6-0.7-1.1-1.2-2-1.6c-2.4-1.1-5.4-1-8-0.8
			c-0.2,0-0.3,0.1-0.5,0.1c-6.5-1.4-13.2-2.3-19.9-2.6c3.8-2.9,4.7-8.5,3-13c-1.7-4.5-5.4-7.9-9.4-10.6c-9.6-6.5-21.5-9.8-33.1-9
			c-12.2-16.7-37.4-22.3-55.4-12.2c-2.3,1.3-5.2,0.7-7.8,0c-15.6-3.7-34-4.8-45.5,6.4c-1.9,1.9-4.1,4.3-6.8,4
			c-10.6-1.1-20.7,4.2-29.6,10.1c-4.8,3.2-9.7,6.9-11.7,12.2c-2,5.4,0.3,12.7,5.9,14c-7.3,3.3-16.2-2.3-23.6,0.8
			c-6.7,2.8-8.8,11.3-8.3,18.5c0.2,3.3,0.9,6.6,1.8,9.8c0.5,3.3,0.9,6.5,1.3,9.8c-5.2-1.3-10.7-1.2-15.8,0.6
			C68,175,60.5,191.7,54.8,206.8c-1.1,2.8-2.2,5.7-2.4,8.8c-0.5,6.9,3.4,13.3,7.2,19.1c-7.9,1.9-14.9,7.2-19.2,14.1c0,0,0,0-0.1,0.1
			c-0.5,0.8-0.9,1.6-1.2,2.4c-3.5,4.8-3.7,12.4-4.2,17.9c0,0.2,0,0.3,0.1,0.5c-8.1,13.7-6.4,33,5.3,43.9
			c13.3,12.3,37.4,10.5,47.8-4.3c5.8-8.1,7.2-18.5,8.5-28.3c1.3-10.4,2.6-21.4-1.6-31c-1.3-2.9-2.9-6.7-0.5-8.8
			c10.6-9.8,18-23,20.8-37.2c0.2-0.1,0.3-0.3,0.4-0.6c1.5-4.8,1.9-9.2,1.7-13.9c0.3-2.4,0-4.8-0.9-7c10,0.5,20.1-4.5,25.6-12.8
			c5.6-8.3,6.4-19.5,2.1-28.6c7.7,5.9,20.5,2.9,24.8-5.8c4.4-8.8,0.5-20.3,4.3-29.1c1.8,2.6,3.4,5.3,5,8.1c0,0.1,0.1,0.1,0.1,0.2
			c1.7,4,3.7,7.8,6.8,10.8c4.5,4.4,11.8,6.5,17.1,3.2c3.2-2,5.1-5.5,6.8-8.9c4.1-8.3,7.9-16.8,11.4-25.4c5,3.1,7.5,8.9,9.4,14.5
			c1.9,5.6,3.6,11.5,7.7,15.7c4.1,4.2,11.6,5.7,15.7,1.5c5.2-5.3,10.4-10.6,15.5-15.9c2.1-2.2,4.3-4.4,6.7-6.2c0.1,2,0,4-0.3,6
			c-0.2,1.5,0.1,2.8,0.1,4.2c-1,4.7-1.1,9.6-0.3,14.4c1,6.2,4.3,12.8,10.3,14.6c6.2,1.9,12.6-2.1,17.9-5.8
			c-2.7,7.5-4.1,15.9-1.7,23.5c2.5,7.6,9.8,14,17.8,13.3c4.4-0.4,10.9-1.4,11.4,2.9c0.1,1.3-0.5,2.6-1,3.8
			c-4.9,11.6,1.5,24.4,8.6,35.2c0,0.1,0,0.2,0,0.3c0.3,2.1,0.8,4.1,1.5,6c0,0-0.1,0.1-0.1,0.1c1.2,5.3,4.7,10.1,9.4,13
			c3,1.8,7.1,5,4.9,7.7c-5.4,6.9-5.8,17.2-1.2,24.6c0.3,1.4,0.4,2.8,0.3,4.2c-0.6,1.6-0.7,3.4-0.4,5.1c-0.1,0.1-0.2,0.3-0.2,0.4
			c-0.2,0.2-0.3,0.5-0.2,0.8c0.4,2.6,0.5,5.3,0.5,7.9c0,0.1,0,0.2,0,0.2c-0.8,4.8,0.2,9.9,2.6,14.2c3,5.4,7.8,9.6,13.1,12.7
			c6.7,3.9,14.4,6.1,22,5.6c7.7-0.5,15.3-4,20-10C417.2,300.2,417.4,288.5,417.3,277.6z M94.6,268.4c-0.7,5.7-1.3,11.4-2,17.2
			c-0.6,5-1.2,10-3,14.6c-5,12.8-19.9,20.9-33.4,18.2c-13.5-2.8-24-16.1-23.5-29.8c0.2-6.9,2.8-13.4,4.8-20c0.1-0.1,0.1-0.2,0.1-0.4
			c0.1-0.4,0.3-0.9,0.4-1.3c0.1-0.1,0.2-0.3,0.2-0.5c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.3c0.7-0.3,0.7-1.3,0.1-1.7
			c0.2-1.7,0.4-3.5,0.7-5.2c0.2-0.5,0.3-1.1,0.5-1.5c1-3.1,2.8-6.5,4.8-9.2c1.5-1.5,2.8-3.8,4.2-5.4c3.2-3.7,8.8-4.3,13.6-3.4
			s9.3,3,14.2,3.8c4,0.7,8.3,0.5,11.9,2.4C95.6,249.7,95.6,260.1,94.6,268.4z M113.3,202.6c-2,5.1-4,10.1-6,15.2
			c-2.1,5.3-4.3,10.6-8,14.9c-5.8,6.6-15.3,9.7-23.8,7.8c-8.6-1.9-15.9-8.6-18.4-17c-3.8-12.8,3-26.2,10.9-36.9
			c2.8-3.8,5.8-7.6,9.7-10.3c6.9-4.8,16.4-5.7,24.1-2.3c6,2.7,10.7,7.9,13.1,14C115.1,192.9,114.9,197.6,113.3,202.6z M143,160.1
			c-2.2,6.5-7.1,11.8-12.9,15.5c-3.9,2.5-8.6,4.3-13.1,3.2c-3.6-1-6.4-3.7-9.5-5.8c-1.8-1.2-3.8-2.2-5.8-2.9
			c-0.7-5.7-1.5-11.4-2.4-17.1c0.1-3.7,0.4-7.3,0.8-10.9c0.2-2.1,0.6-4.5,2.1-6c1.5-1.5,3.7-1.8,5.8-2c11.3-1.4,24.6-2,31.9,6.6
			C144.3,145.9,145.1,153.6,143,160.1z M168.6,106.4c-0.1,3.5-0.3,7-0.4,10.5c-0.2,5.8-0.4,11.7-2.5,17.1c-1.4,3.7-4.4,7.4-8.4,7.4
			c-1.1,0-2.1-0.3-3.2-0.7c-3-1-5.9-2.2-8.7-3.5c-5.1-2.4-10-5.2-14.6-8.4c-2.1-1.5-4.3-3.1-5.5-5.4c-1.2-2.3-1.4-5.4,0.3-7.3
			c6.6-7.5,15-13.4,24.2-17.2c4.1-1.7,8.6-3,13-2.1c1.5,0.3,2.9,0.8,3.9,1.9C168.7,100.6,168.7,103.7,168.6,106.4z M217.1,93.2
			c-3,7.1-6,14.1-9,21.2c-2.6,6.1-7,13.4-13.6,12.7c-7.5-0.8-9.7-10.8-15.2-15.9c-1.5-2.4-3-4.8-4.5-7.2c-0.2-0.4-0.6-0.5-0.9-0.6
			c-3.6-2.5-3.5-8.7-0.6-12.1c3.2-3.7,8.2-5,13-5.8c8.1-1.3,16.4-1.5,24.6-0.6c3,0.3,6.6,1.4,7.1,4.3
			C218.2,90.5,217.7,91.9,217.1,93.2z M269.6,105.4c-5.2,5.1-10.5,10.2-15.7,15.3c-2.3,2.2-4.9,4.6-8.1,4.5
			c-4.4-0.1-7.2-4.6-9.1-8.5c-2-4-4-8.1-5.5-12.3c-0.9-2.7-1.6-5.5-3.2-7.9c-1.5-2.2-3.7-4.2-3.8-6.8c-0.1-2.1,1.3-4.1,3-5.3
			c1.8-1.2,3.9-1.6,6-2c11.7-1.8,23.9,0.2,34.4,5.8c3.4,1.8,6.9,4.5,7.1,8.3C274.8,100.1,272.1,103,269.6,105.4z M296.2,137.6
			c-2.7,1.6-5.6,3.2-8.7,2.9c-6.1-0.5-9.4-7.7-9.6-13.8c-0.1-3.9,0.4-7.9,0.2-11.8c0.1-0.1,0.1-0.3,0.1-0.4c0.1-2-0.3-3.8,0-5.9
			c0.3-2.1,0.3-4.1,0.2-6.2c0-0.2-0.1-0.4-0.3-0.5c1.4-0.9,2.9-1.6,4.5-2.1c11-3.5,22.1,4.2,31.1,11.4c2.6,2.1,5.4,4.6,5.4,7.9
			c0,3.7-3.4,6.3-6.5,8.3C307.1,131,301.7,134.4,296.2,137.6z M319.4,170.8c-9.9-2.4-17.6-12.5-16.5-22.6
			c1.1-10.1,11.4-18.5,21.5-16.8c4.4,0.7,9.4,2.9,13.4,2.4c0.2,0.1,0.4,0.2,0.7,0.1c1.9-0.1,4.2-0.3,6.1,0.3
			c1.1,0.4,1.3,0.9,2.1,1.6c0.3,0.2,0.9,0.5,1,0.8c0.2,0.3,0.4,0.5,0.6,0.7c2.4,7.4,1.5,15.8-2.6,22.3
			C340.3,168.2,329.3,173.2,319.4,170.8z M345.4,226.6c-0.5-1.4-0.9-2.9-1.9-4c-0.1-0.1-0.3-0.3-0.4-0.4c0-0.1,0-0.1,0-0.2
			c-0.7-1.9-1-4-1.7-5.9c-0.2-0.5-0.4-1.1-0.7-1.6c0-0.1,0-0.1,0-0.2c0.1-0.5-0.2-0.8-0.5-0.9c-0.2-0.3-0.4-0.7-0.6-1
			c-0.4-1.3-0.9-2.6-1.5-3.8c-3.1-7-6.3-14.1-9.4-21.1c2.9-3.2,4.7-7.4,5-11.7c3-1.7,5.7-3.9,8.1-6.4c4.5,0,8.6-0.8,13-1.7
			c0,0,0.1,0,0.1,0c4.9,0.3,9.8,2.5,13.1,6.2c2.2,2.4,3.7,5.3,5.2,8.2c3.5,6.6,7,13.2,10.5,19.8c3.3,6.2,6.7,13.5,3.8,20
			c-1.2,2.7-3.4,4.9-5.7,6.8c-5.5,4.6-12.2,8.2-19.3,8.4C355.5,237.2,348,233.3,345.4,226.6z M414.2,283.8
			c-0.2,5.7-0.3,11.6-2.6,16.9c-3.5,8-11.8,13.5-20.5,14.6c-8.7,1.1-17.6-1.9-24.6-7.2c-6.3-4.7-11.3-11.8-11.5-19.7
			c0-1.1,0-2.4-0.2-3.5c0.1-0.1,0.2-0.3,0.3-0.5c0.5-2.2,0.7-4.3,0.7-6.5c0.7-3.8,1.6-7.9-0.2-11.2c-0.1-0.2-0.2-0.3-0.3-0.3
			c-0.1-0.4-0.3-0.8-0.5-1.1c-3.9-7.6-2.5-17.5,3.4-23.7c8-1.3,16.1-3.1,22.8-7.6c0.6-0.4,1.1-0.8,1.7-1.2c0.3,0.2,0.6,0.2,1,0
			c1.1-0.7,2.9,0,4.1,0.3c0.8,0.2,1.6,0.3,2.3,0.5c0,0,0,0,0,0.1c5.7,2.5,10.2,7.1,13.6,12.3c1.8,2.7,3.3,5.6,4.7,8.6
			c-0.3,0.4-0.2,1,0.4,1.3c0.1,0,0.2,0.1,0.3,0.2c1,2.2,1.9,4.4,2.8,6.6l1.6,3.9c0,0,0,0,0,0.1c0,3.1,0.4,6.2,1.1,9.3
			c0,0.2,0.1,0.3,0.2,0.4C414.3,278.7,414.3,281.3,414.2,283.8z"/>
							<path id="XMLID_80_" fill="#010101" d="M401.6,375c-9.8-10-27.2-12.3-38.5-3.9c-3.9,2.9-6.9,6.7-9.9,10.5c-2,2.6-4.1,5.2-5.2,8.4
			c-1,2.8-1.1,5.8-1.2,8.7c-0.3,7.7-0.7,15.4-1,23.1c-0.3,7.5-0.5,15.4,3,22.1c1.1,2.1-0.1,4.7-2,6.1c-1.8,1.4-4.1,2.1-6.2,3.2
			c-8.3,4.3-11.4,14.3-13.7,23.3c-1.9,7.5-3.9,15-5.8,22.5c-1.7,6.5-3.3,13.5-1,19.8c0.7,1.9-1.7,3.4-3.6,4.2
			c-0.6,0.3-1.1,0.7-1.6,1.1c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0-0.3,0.1-0.4,0.2c-0.3,0.2-0.7,0.4-1.1,0.7c-1.4,1-2.8,1.9-4.1,3
			c-0.5,0.4-0.8,0.9-1.2,1.4c-0.2,0-0.5,0.1-0.7,0.3c-0.6,0.7-1.1,1.4-1.7,2c-0.5,0.4-0.9,0.8-1.2,1.4c-0.6,0.4-0.9,1.1-1.3,1.7
			c-1.9,3.6-3.5,7.4-4.7,11.4c-0.6,1.8-0.9,3.9-0.7,5.8c-0.1,0.1-0.1,0.3-0.1,0.5c0,1.4-0.1,2.8-0.1,4.3c-0.2,0-0.4,0-0.5,0
			c-0.4,1.2-0.6,2.8,0.5,3.3c0,0.3,0,0.5,0,0.8c0,1.1-0.1,2.2,0.1,3.2c-1.6,1-4,1.1-5.9,0.6c-3-0.9-5.7-2.8-8.8-3
			c-5.7-0.4-10.2,5-12.4,10.3c-2.2,5.2-3.2,10.8-3.2,16.4c0,0,0,0,0,0.1c0,2.7-0.6,5.3-1,7.9c-1.2-2.2-2.7-4.3-4.3-6.1
			c-3.5-4.2-7-8.4-10.6-12.6c-1.6-1.9-3.3-3.9-5.5-4.9c-3.8-1.8-8.6-0.4-11.8,2.4c-3.2,2.8-5.2,6.7-6.9,10.5
			c-2.9,6.5-5.4,13.2-7.4,20c-2.4-8.1-4.8-16.2-7.2-24.3c-1-3.4-2.2-7.1-5-9.3c-5.4-4.1-13.4-0.7-17.9,4.3
			c-4.5,5-7.5,11.6-12.9,15.5c-2-7.2-3.9-14.4-5.9-21.7c-0.6-2.1-1.2-4.3-2.5-6c-4-5.4-12.4-4.8-18.4-1.6c0.3-7.5-0.8-15.3-4.7-21.8
			c-3.9-6.4-11.1-11.2-18.6-11c-2.7,0.1-3.7-3.6-3.1-6.2c0.4-2.1,1.2-4.5,0.6-6.4c0-0.1,0-0.1,0-0.2c-0.1-0.5-0.2-1-0.2-1.5
			c0-0.5,0-1,0.1-1.4l0.1,0.2c0-0.2,0-0.4-0.1-0.6c0-1,0-2,0-3.1c0-0.2,0-0.3-0.1-0.4c0-1-0.1-2.1-0.2-3.1c0-0.7-0.1-1.4-0.4-2.1
			c-0.2-0.7-0.4-1.4-0.7-2.1c-3.5-18.7-12.6-36.4-25.9-49.9c-2.8-2.8,0.5-7.2,1.6-11c1.4-5.1-1.5-10.2-2.5-15.4
			c-1.1-6.1,0.6-12.3,0.8-18.5c0.5-13.3-6.5-26.8-18.1-33.1s-27.5-4.5-36.7,5.1c-10.1,10.6-10.5,27-7.9,41.4c1.5,8.7,4,17.5,9,24.8
			c4.7,7,11.6,12.4,19.4,15.4c2.4,0.9,2.1,4.3,1.4,6.7c-4.1,15.8-4,33.3,3.8,47.6c7.8,14.3,24.3,24.4,40.4,21.6
			c-5.5,5.7-6.7,14.2-7.7,22.1c-0.5,4-0.9,8.3,1.2,11.7c3.1,5,10,5.7,15.9,5.2c5.9-0.5,12.3-1.6,17.2,1.5c-4.1,0.5-6,6-4.3,9.9
			c1.7,3.8,5.5,6.2,9.2,8.1c4,2.1,8.1,4,12.3,5.7c6.6,2.7,14.5,4.8,20.7,1.1c0.1-0.1,0.3-0.2,0.4-0.3c0.6,1.2,1.4,2.3,2.2,3.4
			c1,1.3,2.3,2,3.7,2.6c3.4,3.7,8.4,6.1,13.4,6.9c7.4,1.2,14.9-0.5,22.2-2.2c2-0.5,4.2-1,6.1-0.3c2.1,0.7,3.6,2.7,5.5,3.9
			c3.1,2,7,1.9,10.7,1.4c7.7-0.9,15.3-2.9,22.4-5.9c3.3-1.4,6.7-3.3,8.3-6.5c0.8,0.1,1.4,1.2,1.9,1.8c0.4,0.5,0.9,0.8,1.4,1.1
			c1.9,1.2,4.3,1.8,6.5,1.6c3.5-0.3,6.8-1.9,9.9-3.5c3.1-1.6,6.3-3.2,9.4-4.8c3.3-1.7,6.7-3.4,9.4-6c5.6-5.4,7.2-14.4,3.8-21.4
			c7.2-0.2,14.5-0.4,21.7-0.6c2.9-0.1,5.9-0.2,8.5-1.6c6.5-3.5,6.7-12.6,5.2-19.8c-1.3-6.2-3.1-12.2-5.6-18
			c9.7,1.2,19.4-3.6,26.3-10.6c6.9-6.9,11.5-15.8,15.7-24.6c3-6.2,6-12.7,6.3-19.7c0.2-3.9-0.7-8-2.6-11.4c0-0.2,0-0.4-0.2-0.6
			c-1.9-2.6-3.9-5.2-5.8-7.8c1.1-3.3,6-4.3,9.5-5.9c9.1-4.4,9.5-16.8,13.2-26.2c1.8-4.8,4.7-9,6.7-13.7
			C413.6,401.4,411.4,385.1,401.6,375z M57.4,456.4c-7.9-4.7-13.5-12.6-16.9-21.2c-3.4-8.6-4.7-17.8-5.5-27
			c-0.3-3.2-0.4-6.7,1.3-9.3c1.4-2.2,1.5-5,2.1-7.6c2-8.9,10.4-15.5,19.3-16.8c9-1.3,18.2,2.3,25.1,8.2c2.8,2.4,5.2,5.2,6.8,8.5
			c4.9,10.1,0.7,22.1,2,33.2c0.6,5.5,2.6,10.9,2.5,16.5s-3.6,11.7-9.1,12.3c-4.5,0.5-9,2-12.8,4.5C67.9,460.5,62,459,57.4,456.4z
			 M76.8,523.7c-8.4-8.9-12.5-21.2-13.6-33.3c-0.4-5-0.4-10.2,1-15c2.2-7.8,8.2-14.5,15.9-17.1s16.8-0.7,22.5,5.1
			c4.1,4.2,6.2,9.8,8.1,15.3c3.5,9.7,7,19.4,10.5,29.1c0,1.3-0.2,2.6-0.1,3.9c0,1.3,0.1,2.5,0.3,3.8c0,0.4,0,0.8,0,1.1
			c0,0.3,0.1,0.4,0.2,0.6c-1.4,2.1-1.2,5.4-1.9,8c-1.2,4.2-5.3,7-9.5,8.4C98.6,537.3,85.3,532.6,76.8,523.7z M128.5,571.9
			c-3.9,0.2-7.7,1.1-11.6,1.3c-3.9,0.2-8-0.2-11.2-2.5c-1.1-0.8-2-1.8-2.5-3c-0.5-1.2-0.5-2.6-0.4-4c0.1-2.6,0.4-5.2,0.8-7.8
			c0.7-4.5,1.7-9,3.9-13c2.1-4,5.5-7.5,9.8-9c3.7-1.3,7.8-1,11.7-0.2c2.1,0.4,4.3,0.9,6.2,1.9c5.3,2.7,8.2,8.5,10.1,14.2
			c1.4,4.2,2.3,9.1-0.2,12.7c-0.9,1.4-2.3,2.4-3.6,3.4C137.6,568.7,133.4,571.7,128.5,571.9z M169.3,600.1c-2.6,2-6.3,1.4-9.4,0.3
			c-3.1-1.1-6.2-2.7-9.4-2.5c-1.1,0-2.1-0.6-3.1-1.2c-4.1-2.6-8.2-5.2-12.3-7.8c-1.1-0.7-2.2-1.5-2.6-2.7c-0.2-0.8,0-1.7,0.2-2.5
			c1.9-6.4,6.5-11.8,12.3-15.1c3.4-2,7.3-3.2,11.1-4.4c3.6-1.2,7.7,1,9.9,4.1c2.2,3.1,3,7,3.8,10.8c0.6,2.9,1.2,5.8,1.8,8.6
			C172.3,592,172.8,597.5,169.3,600.1z M213.6,609.9c-1.2,1-2.7,1.4-4.3,1.7c-12.1,2-25.1-1.3-34.7-9.1c0.1,0.2,0.2,0.4,0.2,0.6
			c-0.4-0.6-0.7-1.2-1-1.9c1.7-1.5,3.1-3.3,4.5-5.1c3.9-5.1,7.9-10.3,11.8-15.4c3.3-4.3,8.3-9.1,13.3-6.9c3.2,1.4,4.5,5.1,5.5,8.5
			l5.4,17.8C215.4,603.4,216.2,607.6,213.6,609.9z M263.3,604.2c-0.8,4.3-5.6,6.5-9.8,7.5c-8.2,2-16.8,2.5-25.2,1.6
			c-2.2-0.3-4.8-0.8-5.8-2.8c-0.9-1.8-0.3-3.9,0.4-5.8c2.6-7,5.5-13.9,8.8-20.6c2-4.2,5.2-8.9,9.9-8.6c3.1,0.2,5.5,2.7,7.6,5.1
			c4.6,5.2,9.1,10.6,12.5,16.6C262.7,599.3,263.8,601.7,263.3,604.2z M307.3,584.9c-0.6,6.3-6.5,10.4-11.9,13.8
			c-7.6,4.7-16.4,9.5-24.9,8.1c-0.5-0.4-0.9-1-1.3-1.4c-0.5-0.6-1.1-1.1-1.9-1.3c0.1-0.5,0.2-1,0.2-1.5c0.1-0.2,0.2-0.5,0.2-0.7
			c-0.7-4.5,1-8.8,1.1-13.3c0-0.2,0-0.3-0.1-0.5c1.1-3.5,1.5-7.3,2.5-10.9c1.4-5,4.7-10.2,9.8-11c2.5-0.4,5,0.4,7.4,1.2
			c4.4,1.4,9,2.9,12.7,5.7C305,575.8,307.8,580.3,307.3,584.9z M341.2,552.6c1.2,6.3,1.5,14.2-3.9,17.7c-3.6,2.3-8.1,1.6-12.4,1.4
			c-5.1-0.1-10.3,0.7-15.3-0.3c-3.8-0.7-7.6-3-9.4-6.2c-0.1-3.1,0-6.3,0.3-9.4c0-0.1,0-0.2,0-0.3c0.1-0.1,0.1-0.3,0.1-0.5
			c-0.1-1.1-0.2-2.2-0.3-3.3c0-0.1,0-0.2-0.1-0.3c0.6-4.5,2.1-9,4.6-12.8c0.7-1.1,1.5-2.3,1.8-3.6c0.6-0.4,1.2-0.9,1.7-1.5
			c0.4-0.4,0.5-0.9,0.9-1.4c0.6-0.8,1.3-1,2.1-1.5c0.7-0.5,1.1-1.1,1.5-1.7c1.1-0.9,1.8-1.6,3.3-2c0.2-0.1,0.4-0.2,0.5-0.3
			c6-1.7,12.9,1.3,17,6.2C338,538.3,339.8,545.5,341.2,552.6z M382.6,468.6c1.4,3.5,1.9,7.4,1.7,11.2c-0.4,6.3-2.8,12.4-5.4,18.2
			c-3.7,8.3-8.1,16.5-14.6,22.8c-6.5,6.3-15.6,10.5-24.6,9.4c-9-1.1-17.4-8.4-18-17.5c-0.4-6.1,2.6-11.7,4.5-17.5
			c2.8-8.4,3.4-17.4,6.4-25.7s9.3-16.4,18-17.6c6.4-0.9,12.7,2,18.6,4.8c2.4,1.2,4.8,2.3,7.2,3.5c0,0.1,0.1,0.1,0.1,0.2
			C378.5,463.1,380.6,465.9,382.6,468.6z M404.8,414.5c-1.5,2.8-3.3,5.4-4.7,8.2c-4.7,9.8-4,23-13,29c-5.7,3.8-13.4,2.9-19.9,0.8
			c-7.7-2.5-15.5-7.4-17.9-15.2c-3.1-10.3,4.3-22,0.1-31.9c-2.3-5.2-0.2-11.2,2.4-16.3c3.6-6.8,8.6-13.2,15.4-16.7
			c11.1-5.8,25.9-2.5,34.1,6.9S410.6,403.4,404.8,414.5z"/>
							<path id="XMLID_79_" fill="#010101" d="M76.3,413.6l0.3-0.2c-0.9-2.2-2.4-4.9-4.8-4.9c0.9,2.4,2.2,4.6,3.8,6.5
			C76.2,415.1,76.7,414.1,76.3,413.6z"/>
							<path id="XMLID_78_" fill="#010101" d="M366.2,207.5c1.4,0.5,3,0.2,4.2-0.8c-0.6-1.7-2.6-2.7-4.4-2.3c0.1-1.1-0.5-2.2-1.5-2.8
			c-0.6,2.1-0.9,4.3-0.9,6.5c0.8,0.2,1.8,0,2.4-0.7L366.2,207.5z"/>
							<path id="XMLID_77_" fill="#010101" d="M134,117.5c0.3,0,0.5-0.1,0.7-0.3c4.2-2.6,8.5-5.2,12.7-7.8c1.1-0.7,2.4-2.1,1.6-3.1
			c-5.6,1.8-10.7,4.9-14.9,8.9C133.4,115.9,133,117.4,134,117.5z"/>
							<path id="XMLID_76_" fill="#010101" d="M66.4,278.5c0.5-0.1,0.9-0.1,1.4-0.2c0.1-5.3,9.1-6.1,10.1-11.3c-1-0.4-2.2,0.3-3.1,0.9
			c-1.6,1.1-3.2,2.1-4.8,3.2c-1.3,0.9-2.6,1.8-3.5,3.1C65.7,275.4,65.5,277.3,66.4,278.5z"/>
							<path id="XMLID_75_" fill="#010101" d="M101.2,492c-1.5,0.6-3,1.2-4.5,1.8c-1.2,0.5-1.8,2-1.7,3.3s0.8,2.5,1.5,3.7
			c-0.2-1.3,1-2.7,2.3-2.7C99.6,496.1,100.4,494,101.2,492z"/>
							<path id="XMLID_74_" fill="#010101" d="M64.2,264.8c-4.4,3.1-8.4,6.7-11.7,10.9c3.8-1.2,7.4-3.2,10.3-6
			C64.2,268.5,65.5,266.2,64.2,264.8z"/>
							<path id="XMLID_72_" fill="#010101" d="M378.4,399.1c0.9-0.9,1.3-2.2,1.7-3.4c1.1-3.5,2.3-7,3.4-10.5c-2-0.6-3.7,1.6-4.6,3.5
			c-1.7,3.5-3,7.3-3.8,11.1C376.1,400.5,377.5,400,378.4,399.1z"/>
							<path id="XMLID_71_" fill="#010101" d="M372.2,251.4c1.3,2.2,2.7,4.5,3.1,7.1c0.4,2.6-0.3,5.5-2.4,7c0,1.3,2.2,1.1,2.9,0
			c0.7-1.1,0.8-2.7,1.9-3.4c0.5-0.3,1.1-0.4,1.6-0.8c0.4-0.4,0.5-1.1,0.6-1.7C380,255.4,376.3,251.5,372.2,251.4z"/>
							<path id="XMLID_69_" fill="#010101" d="M194.1,93.7c0.6-0.1,1.3-0.3,1.7-0.8c0.4-0.5,0.3-1.4-0.3-1.6c-5.5-1.9-12-0.5-16.3,3.4
			C184.2,95.1,189.2,94.7,194.1,93.7z"/>
							<path id="XMLID_68_" fill="#010101" d="M358.2,479.7c0.2-0.2,0.3-0.5,0.4-0.8c1.6-4,3.1-8.1,4.7-12.1c-1.2-0.9-3,0.3-3.5,1.8
			c-0.5,1.4-0.4,3.1-1.1,4.4c-0.5,1-1.4,1.8-2.1,2.6c-0.7,0.9-1.3,2-1,3.1S357.4,480.6,358.2,479.7z"/>
							<path id="XMLID_66_" fill="#010101" d="M58.2,407.8c-1.5-0.2-2.8,0.9-3.9,1.9c-0.9,0.8-1.8,1.7-2.6,2.5c-1,1-1.8,3.1-0.4,3.4
			c2.4-2,5-3.6,7.9-4.7C60.5,410.3,59.7,407.9,58.2,407.8z"/>
							<path id="XMLID_65_" fill="#010101" d="M381.6,281.6c-0.7,0.4-1.3,0.8-1.9,1.3c1.3-0.1,2.7,0.2,3.8,0.9c2.3,1.4,4.4,4.3,7,3.5
			C390.8,283.1,385.4,279.7,381.6,281.6z"/>
							<path id="XMLID_64_" fill="#010101" d="M262,97.4c-0.7-1.9-2.9-2.6-4.9-3.2c-2.7-0.7-5.5-1.5-8.2-2.2c-0.9-0.2-1.8-0.5-2.6-0.2
			c-0.8,0.3-1.5,1.3-1,2.1C250.7,91.9,256.3,98.7,262,97.4z"/>
							<path id="XMLID_63_" fill="#010101" d="M75.5,208.2c-1.7-0.1-4,0.9-3.4,2.4c5,0.3,9.9,1.9,14.1,4.5
			C85.2,210.7,80.1,208.5,75.5,208.2z"/>
							<path id="XMLID_62_" fill="#010101" d="M358.7,193c-1.7,1-2.3,3-2.7,4.9c-0.4,1.5-0.7,3-1.1,4.5c0.9,0.4,1.8-0.4,2.5-1.2
			c1.4-1.6,2.9-3.2,4.3-4.8c0.3-0.4,0.6-0.7,0.8-1.2C363.1,193.4,360.3,192,358.7,193z"/>
							<path id="XMLID_61_" fill="#010101" d="M86.9,492.7c2.8-4.6,3.3-10.5,1.3-15.5c-0.5-1.3-2.1-2.8-3.1-1.7
			c1.8,5.8,1.7,12.2-0.5,17.9C84.8,494.4,86.4,493.6,86.9,492.7z"/>
						</g>
					</g>
				</svg>
				<Button style={{ width: "98%" }} id="" onClick={selectTooth} size="small"  variant="outlined">Reset</Button>
				<Button   endIcon={<NavigateNext></NavigateNext>} style={{ width: "98%", "margin-top": "20%" }} id="" variant="contained"  onClick={() => { props.toggleBrowseTeeth(false) }} >Back to Treatment</Button>

			</div>
		</div>
	);
}